export const menuItems = [
    {
        text: 'consumersMenu',
        destination: 'consumersLink',
        pagesIncluded: [
            {
                url: 'consumerLink',
                name: 'consumerHeader',
            },
            {
                url: 'consumersLink',
                name: 'consumersHeader',
            },
        ],
    },
    {
        text: 'complainsMenu',
        destination: 'complainsLink',
        pagesIncluded: [
            {
                url: 'complainsLink',
                name: 'complainsHeader',
            },
            {
                url: 'complainLink',
                name: 'complainHeader',
            }
        ],
    },
    {
        text: 'companiesMenu',
        destination: 'companiesLink',
        pagesIncluded: [
            {
                url: 'companyCreateLink',
                name: 'companyCreateHeader',
            },
            {
                url: 'companyLink',
                name: 'companyHeader',
            },
            {
                url: 'companiesLink',
                name: 'companiesHeader',
            },
        ],
    },
    {
        text: 'usersMenu',
        destination: 'usersLink',
        pagesIncluded: [
            {
                url: 'userCreateLink',
                name: 'userCreateHeader',
            },
            {
                url: 'userLink',
                name: 'userHeader',
            },
            {
                url: 'usersLink',
                name: 'usersHeader',
            },
        ],
    },
    {
        text: 'logsMenu',
        destination: 'logsLink',
        pagesIncluded: [
            {
                url: 'logsLink',
                name: 'logsHeader',
            },
        ],
    },
    {
        text: 'reportsMenu',
        destination: 'reportsLink',
        pagesIncluded: [
            {
                url: 'reportsLink',
                name: 'reportsHeader',
            },
        ],
    },
];