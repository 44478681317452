import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks";
import { useForm } from 'react-hook-form';
import { setError } from "../../app/slices/authSlice";
import { showModal } from "../../app/slices/modalSlice";
import {
    Container,
    Grid,
    TextField
} from '@mui/material';
import { Logo } from "../../assets/images";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { APP_URLS } from "../../app/config";
import { withRouter } from "../../wrappers/withRouter";

function SetPassword({error, setError, params}) {
    const navigate = useNavigate();
    const location = useLocation();
    const { login } = useAuth();

    const { t } = useTranslation('LoginScreen');
    const { register, handleSubmit } = useForm();

    const nextPath = location?.state?.nextPath ?? '/';

    // useEffect(() => {
    //     if (authenticated) {
    //         navigate(nextPath);
    //     }
    // }, [navigate, authenticated, nextPath])

    const handleLogin = (data) => {
        if (data.password !== data.repeatPassword) {
            setError("Podane hasła nie są identyczne");
            return;
        }

        axios.post(
            APP_URLS.USER_SET_NEW_PASSWORD,
            {
                id: params.id,
                code: params.code,
                password: data.password,
            }
        ).then((result) => {
            if (result.status === 200) {
                showModal({ message: t('account:changePasswordConfirmationDialogMessage')});
            }
            navigate('/');
        }).catch((error) => {
            if (error.response?.status === 400) {
                setError({
                    responseErrors: error.response?.data?.map((error) => error.description) ?? [],
                }, () => console.log(this.state.responseErrors));

                return;
            }
        });
    };

    return (
        <Container component="main" maxWidth="sm" className="fk-login">
            <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid>
                    <form onSubmit={handleSubmit(handleLogin)}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} style={{textAlign: 'center'}}>
                                <img className="logo" src={Logo} alt="Logo" />
                                <h1>{t('setPasswordHeader')}</h1>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label={t('passwordLabel')} required type="password" name="password" fullWidth {...register("password", { required: true })} /><br />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label={t('repeatPasswordLabel')} required type="password" name="repeatPassword" fullWidth {...register("repeatPassword", { required: true })} /><br />
                            </Grid>
                            <Grid item xs={12}>
                                <button type="submit" className="fk-button fk-button--primary fk-button--full-width fk-button--medium">{t('setButton')}</button>
                                <div className="validation-error">
                                    {!error?.responseErrors ? error : error.responseErrors.map((error) => <p>{error}</p>)}
                                </div>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </Container>
    )
}

const mapStateToProps = (state) => ({
    authenticated: state.auth.authenticated,
    error: state.auth.error,
});

export default withRouter(connect(mapStateToProps, { setError, showModal })(SetPassword));