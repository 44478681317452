import { Grid } from '@mui/material';
import React, { Component } from 'react';
// import { CompanyAdditionalInfoForm, CompanyInfoForm, CompanyInfoSection, CompanySubmitForm } from '../components/forms/company';
import { clearConsumer } from '../app/slices/consumersSlice';
import { ScreenHeader } from '../components/screenHeader';
import { connect } from 'react-redux';
import {
    UserInfoForm,
    UserSubmitForm,
} from '../components/forms/user';

class UserCreateScreen extends Component {
    constructor(props) {
        super(props);
        props.clearConsumer();
    }

    render() {
        return (
            <div className="fk-screen">
                <ScreenHeader withBackButton withSeparator />
                <Grid container spacing={4}>
                    <Grid item md={6}>
                        <UserInfoForm isCreate />
                        <UserSubmitForm />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    model: state.companies.company,
});

const actions = {
    clearConsumer,
};

export default connect(mapStateToProps, actions)(UserCreateScreen);