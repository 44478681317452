import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks";
import { useForm } from 'react-hook-form';
import {
    Container,
    Grid,
    TextField
} from '@mui/material';
import { Logo } from "../../assets/images";
import { useTranslation } from "react-i18next";

function Login({ authenticated, error }) {
    const navigate = useNavigate();
    const location = useLocation();
    const { login } = useAuth();

    const { t } = useTranslation('LoginScreen');
    const { t: link } = useTranslation('Pages');
    const { register, handleSubmit } = useForm();

    const nextPath = location?.state?.nextPath ?? '/';

    useEffect(() => {
        if (authenticated) {
            navigate(nextPath);
        }
    }, [navigate, authenticated, nextPath])

    const handleLogin = (credentials) => {
        login(credentials).then((data) => {
            navigate(nextPath);
        });
    };

    return (
        <Container component="main" maxWidth="sm" className="fk-login">
            <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid>
                    <form onSubmit={handleSubmit(handleLogin)}>
                        <div className="validation-error">{error}</div>
                        <Grid container spacing={3}>
                            <Grid item xs={12} style={{textAlign: 'center'}}>
                                <img className="logo" src={Logo} alt="Logo" height={200} />
                                <h1>{t('header')}</h1>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label={t('loginLabel')} required autoFocus fullWidth {...register("username", { required: true })} /><br />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label={t('passwordLabel')} required type="password" name="password" fullWidth {...register("password", { required: true })} /><br />
                            </Grid>
                            <Grid item xs={12}>
                                <button type="submit" className="fk-button fk-button--primary fk-button--full-width fk-button--medium">{t('submitButton')}</button>
                                <a className="remember-password-link" href={link('rememberPasswordLink')}>Zapomniałem hasła</a>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </Container>
    )
}

const mapStateToProps = (state) => ({
    authenticated: state.auth.authenticated,
    error: state.auth.error,
});

export default connect(mapStateToProps, {})(Login);