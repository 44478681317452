import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import classNames from 'classnames';
import { Grid, MenuItem, TextField } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import {
  setCompany
} from '../../../app/slices/companiesSlice';
import _ from "lodash";
import { additionalInfoFieldsArray } from "./config";
import React from "react";

function CompanyAdditionalInfoForm({ isCreate, canEdit, onUpdate, model, errors, setCompany }) {
    const { t } = useTranslation('CompanyCreateScreen', { keyPrefix: 'AdditionalInfoForm' });

    const [currentEdit, setCurrentEdit] = React.useState('');

    const checkIfDisabled = (field) => !isCreate && (!canEdit || field !== currentEdit);
    const getErrorMessage = (field) => _.find(errors, (error) => error.field === field);
    const handleFieldChange = (field, value) => setCompany({ field, value });

    const handleUpdate = async (name, value) => {
        await onUpdate(name, value);

        setCurrentEdit(null);
    }

    return (
        <div className={
            classNames(
                'fk-form-section',
                {
                    'edit-mode': canEdit
                }
            )
        }>
            <h3 className="title">
                {t('title')}
            </h3>
            <Grid container spacing={2}>
                {additionalInfoFieldsArray.map(({name, modelName, required, items, textarea}, index) => (
                    <React.Fragment key={index}>
                        <Grid item md={3} className="fk-label">
                            <label htmlFor={name}>{`${t(name)}${(required ? '*' : '')}`}</label>
                        </Grid>
                        <Grid item md={8} className={
                            classNames(
                                'fk-input-container',
                                {
                                    'edit-mode': canEdit,
                                    'has-error': !!getErrorMessage(name),
                                }
                            )
                        }>
                            <TextField
                                fullWidth
                                select={!!items}
                                multiline={!!textarea}
                                rows={4}
                                disabled={checkIfDisabled(name)}
                                className="fk-input"
                                placeholder={t(`${name}Placeholder`)}
                                helperText={getErrorMessage(name)?.error}
                                value={model ? model[name] ?? '' : !!items ? '' : ''}
                                onChange={(event) => handleFieldChange(name, event.target.value)}
                            >
                                {items && items.map(({value, text}) => (
                                    <MenuItem key={value} value={value}>
                                        {text}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        {canEdit ?
                            <Grid item md={1}>
                                {name !== currentEdit
                                    ? <button
                                        className="fk-edit-button"
                                        onClick={() => setCurrentEdit(name)}
                                    >
                                        <EditIcon />
                                    </button>
                                    : <button
                                        className="fk-edit-button"
                                        onClick={() => handleUpdate(modelName, model[name])}
                                    >
                                        <SaveIcon />
                                    </button>
                                }
                            </Grid>
                            : <Grid item md={1} />
                        }
                    </React.Fragment>
                ))}
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    model: state.companies.company,
    errors: state.companies.errors,
});

const actions = {
    setCompany
};

export default connect(mapStateToProps, actions)(CompanyAdditionalInfoForm);