import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { userStatuses } from "../../../app/config/userStatuses";
import { fetchConsumers, setFilter } from "../../../app/slices/consumersSlice";
import { FilterMenu } from "../../filterMenu";
import { ScreenHeader } from "../../screenHeader";

function ConsumersTableFilter({ filter, csvLink }) {
  const dispatch = useDispatch();
  const { t } = useTranslation('ConsumersScreen', { keyPrefix: 'TableFilter' });
  const { t: t_common } = useTranslation('Common');
  let timer = null;

  let handleFilterChange = (field, value) => {
    clearTimeout(timer);

    dispatch(setFilter({ field, value, }));

    timer = setTimeout(() => {
      dispatch(fetchConsumers(true));
    }, 500);
  };

  return (
    <>
      <ScreenHeader withSearch search={filter.search} onChange={(event) => handleFilterChange('search', event.target.value)} />
      <div className="fk-filter-container --only-filter">
        <div className="fk-filter">
          <FilterMenu
            datepicker
            label={t('joinDate.text')}
            options={[]}
            selected={filter.dateFrom}
            buttonText={`${t('joinDate.from')} ${filter.dateFrom ? format(new Date(filter.dateFrom), "yyyy-MM-dd") : ''}`}
            onChange={(option) => handleFilterChange('dateFrom', option)}
          />
          <FilterMenu
            datepicker
            options={[]}
            selected={filter.dateTo}
            buttonText={`${t('joinDate.to')} ${filter.dateTo ? format(new Date(filter.dateTo), "yyyy-MM-dd") : ''}`}
            onChange={(option) => handleFilterChange('dateTo', option)}
          />
          <FilterMenu
            label={t('status.text')}
            options={userStatuses}
            selected={filter.isBlocked}
            onChange={(option) => handleFilterChange('isBlocked', option)}
            tFunc={t}
            tPrefix="status."
          />
          <FilterMenu
              actionButton
              link={csvLink}
              buttonText={t_common('downloadCsv')}
          />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  filter: state.consumers.filter,
  csvLink: state.consumers.csvLink,
});

export default connect(mapStateToProps, {})(ConsumersTableFilter);