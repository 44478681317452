import { Button, Grid } from '@mui/material';
import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ScreenHeader } from '../components/screenHeader';
import {
    getConsumer,
    updateConsumer,
    changeStatusConsumer,
    deleteConsumer,
} from '../app/slices/consumersSlice';
import {
    fetchComplains,
    previousPage,
    nextPage,
    resetFilter,
    setFilter,
    checkItem,
    checkAllItems,
    uncheckItem,
    uncheckAllItems,
} from "../app/slices/complainsSlice";
import { withRouter } from '../wrappers/withRouter';
import { ConsumerComplainsList, ConsumerInfoForm, ConsumerInfoSection } from '../components/forms/consumer';
import { DeleteOutline } from '@mui/icons-material';
import { LAYOUT_CONFIG } from "../layoutConfig";
import { showModal } from "../app/slices/modalSlice";
import { useNavigate } from 'react-router-dom';


function ConsumerEditScreen({ params }) {
    const dispatch = useDispatch();
    const model = useSelector(state => state.consumers.consumer);
    const complains = useSelector(state => state.complains.data);
    const filter = useSelector(state => state.complains.filter);
    const selectedItems = useSelector(state => state.complains.selectedItems);
    const navigate = useNavigate();
    

    useEffect(() => {
        dispatch(getConsumer(params.id));
        dispatch(resetFilter());
        refreshComplains();
    }, [dispatch, params.id]);

    const refreshComplains = useCallback(async () => {
        await dispatch(fetchComplains({ reporterId: params.id }));
    }, [dispatch, params.id]);

    const handleFieldSave = useCallback(async (field, value) => {
        try {
            await dispatch(updateConsumer({
                id: params.id,
                fieldName: field,
                value: value,
            }));
            await dispatch(getConsumer(params.id));
        } catch (e) {
            console.log('Error :>> ', e);
        }
    }, [dispatch, params.id]);

    const handlePreviousPage = useCallback(() => {
        dispatch(previousPage());
        refreshComplains();
    }, [dispatch, refreshComplains]);

    const handleNextPage = useCallback(() => {
        dispatch(nextPage());
        refreshComplains();
    }, [dispatch, refreshComplains]);

    const handleRequestSort = useCallback((event, property) => {
        const direction =
            filter.orderBy === property && filter.orderDirection === "asc"
                ? "desc"
                : "asc";
        dispatch(setFilter({ field: "orderBy", value: property }));
        dispatch(setFilter({ field: "orderDirection", value: direction }));
        refreshComplains();
    }, [dispatch, filter.orderBy, filter.orderDirection, refreshComplains]);

    const handleRowCheck = useCallback((item, isChecked) => {
        if (!isChecked) {
            dispatch(checkItem(item));
        } else {
            dispatch(uncheckItem(item));
        }
    }, [dispatch]);

    const handleSelectAll = useCallback(() => {
        if (selectedItems.length === complains.length) {
            dispatch(uncheckAllItems());
        } else {
            dispatch(checkAllItems());
        }
    }, [dispatch, selectedItems.length, complains.length]);

    const handleStatusChange = useCallback(() => {
        const { id, isBlocked, fullName } = model;

        const content = {
            title: isBlocked ? 'Odblokowanie konta' : 'Zablokowanie konta',
            message: isBlocked ? 'Czy na pewno chcesz odblokować konto konsumenta' : 'Czy na pewno chcesz zablokować konto konsumenta',
            button: isBlocked ? 'Odblokuj' : 'Blokuj'
        };

        dispatch(showModal({
            title: content.title,
            message: `${content.message} ${fullName}?`,
            button: content.button,
            mode: 'normal',
            cancelButton: true,
            fallback: () => {
                dispatch(changeStatusConsumer(id)).then(() => dispatch(getConsumer(params.id)));
            }
        }));
    }, [dispatch, model]);

    const handleDelete = useCallback(() => {
        dispatch(showModal({
            title: 'Usunięcie konsumenta',
            message: `Czy na pewno chcesz usunąć konsumenta ${model.fullName}?`,
            button: 'Usuń',
            mode: 'normal',
            cancelButton: true,
            fallback: () => {
                dispatch(deleteConsumer(model.id)).then(() => navigate("/"));
            }
        }));
    }, [dispatch, model]);

    if (!model?.id) {
        return null;
    }

    return (
        <div className="fk-screen">
            <ScreenHeader
                withBackButton
                withSeparator
                title="consumerHeader"
                titleReplacement={['#CONSUMER_NAME#', model.fullName]}
                subtitle="consumerSubheader"
                subtitleReplacement={['#CONSUMER_ID#', model.id]}
                additionalButtons={
                    <div className="screen-header-buttons --flex-end">
                        {model.isBlocked
                            ? <Button className="green" onClick={handleStatusChange}>Odblokuj</Button>
                            : <Button className="green" onClick={handleStatusChange}>Zablokuj</Button>
                        }
                        <Button className="red" onClick={handleDelete}><DeleteOutline /> Usuń</Button>
                    </div>
                }
            />
            <Grid container spacing={4}>
                <Grid item md={6}>
                    <ConsumerInfoForm canEdit onUpdate={handleFieldSave} />
                </Grid>
                <Grid item md={6}>
                    {LAYOUT_CONFIG.USER_DETAILS.SHOW_POINTS &&
                        <ConsumerInfoSection />
                    }
                </Grid>
            </Grid>
            <ConsumerComplainsList
                consumerId={model.id}
                selectedItems={selectedItems}
                onRequestSort={handleRequestSort}
                onRowCheck={handleRowCheck}
                onSelectAll={handleSelectAll}
                onPreviousPage={handlePreviousPage}
                onNextPage={handleNextPage}
            />
        </div>
    );
}

export default withRouter(ConsumerEditScreen);
