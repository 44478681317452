import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import _ from 'lodash';
import { getFormData } from '../../utils';
import { STORAGE, APP_URLS } from '../config';

const defaultState = {
    filter: {
        search: '',
        sector: null,
        openComplainsAmountFrom: null,
        openComplainsAmountTo: null,
        closeComplainsAmountFrom: null,
        closeComplainsAmountTo: null,
        orderBy: 'id',
        orderDirection: 'desc',
        pageNumber: 1,
        pageSize: 6,
        hasPrevious: false,
        hasNext: false,
    },
    data: [],
    selectedItems: [],
    company: {
        name: '',
        nip: '',
        sectorId: '',
        allSectors: [],
        email: '',
        status: '',
        ownName: '',
        contactPerson: '',
        phone: '',
        additionalEmail: '',
        comment: '',
        scoreVisible: true
    },
    isPending: false,
    requestId: null,
    errors: [],
    error: null,
};

const validate = (state) => {
    let errors = [];

    if (!state.company.name) {
        errors.push({
            field: 'name',
            error: 'fieldRequiredError',
        });
    }

    if (!state.company.email) {
        errors.push({
            field: 'email',
            error: 'fieldRequiredError',
        });
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(state.company.email)) {
        errors.push({
            field: 'email',
            error: 'emailAddressIncorrectFormatError',
        });
    }

    if (!state.company.sectorId) {
        errors.push({
            field: 'sectorId',
            error: 'fieldRequiredError',
        });
    }

    return errors;
};

const fetchCompanies = createAsyncThunk(
    'companies/fetch',
    async (data, thunkApi) => {
        try {
            const { companies } = thunkApi.getState();

            if (companies.isPending && companies.requestId !== thunkApi.requestId) {
                return;
            }

            let params = {
                pageSize: companies.filter.pageSize ? companies.filter.pageSize : 6,
                pageNumber: companies.filter.pageNumber ? companies.filter.pageNumber : 1,
            };

            params = (companies.filter.search) ? {...params, search: companies.filter.search} : {...params};
            params = (companies.filter.sector) ? {...params, sector: companies.filter.sector.value} : {...params};
            params = (companies.filter.openComplainsAmountFrom) ? {...params, openComplainsAmountFrom: companies.filter.openComplainsAmountFrom} : {...params};
            params = (companies.filter.openComplainsAmountTo) ? {...params, openComplainsAmountTo: companies.filter.openComplainsAmountTo} : {...params};
            params = (companies.filter.closeComplainsAmountFrom) ? {...params, closeComplainsAmountFrom: companies.filter.closeComplainsAmountFrom} : {...params};
            params = (companies.filter.closeComplainsAmountTo) ? {...params, closeComplainsAmountTo: companies.filter.closeComplainsAmountTo} : {...params};
            params = (companies.filter.orderBy) ? {...params, orderBy: companies.filter.orderBy} : {...params};
            params = (companies.filter.orderDirection) ? {...params, orderDirection: companies.filter.orderDirection} : {...params};

            const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY))?.token;
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }

            const response = await axios.get(
                APP_URLS.COMPANIES_LIST,
                { params, headers});

            return response;
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

const createCompany = createAsyncThunk(
    'companies/create',
    async (data, thunkApi) => {
        try {
            const { companies } = thunkApi.getState();

            if (companies.isPending && companies.requestId !== thunkApi.requestId)
                return;

            const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY))?.token;
            const form = getFormData(companies.company)

            const response = await axios.post(
                APP_URLS.COMPANY_CREATE,
                form,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    }
            });

            return response;
        } catch (error) {
            return thunkApi.rejectWithValue(error.response.data);
        }
    }
);

const updateCompany = createAsyncThunk(
    'companies/update',
    async (data, thunkApi) => {
        try {
            const { companies } = thunkApi.getState();

            if ((companies.isPending && companies.requestId !== thunkApi.requestId) || validate(companies).length)
                return thunkApi.rejectWithValue(companies.errors);

            const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY))?.token;
            const form = getFormData(data)

            const response = await axios.put(
                APP_URLS.COMPANY_UPDATE.replace('{id}', companies.company.id),
                form,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    }
            });

            return response;
        } catch (error) {
            console.log('error :>> ', error);
            return thunkApi.rejectWithValue(error.response.data);
        }
    }
);

const getCompany = createAsyncThunk(
    "companies/get",
    async (id, thunkApi) => {
        try {
            const { companies } = thunkApi.getState();

            if (companies.isPending && companies.requestId !== thunkApi.requestId) {
                return;
            }

            const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY))?.token;
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }

            const response = await axios.get(
                APP_URLS.COMPANY_GET.replace('{id}', id),
                { headers }
            );

            return response;
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

const sendLogo = createAsyncThunk(
    'companies/sendLogo',
    async (data, thunkApi) => {
        try {
            const { complains } = thunkApi.getState();

            if (complains.isPending && complains.requestId !== thunkApi.requestId)
                return;

            let form = new FormData();

            if (data.logo) {
                form.append('Logo', data.logo);
            }

            if (data.acceptanceEmail) {
                form.append('AcceptanceEmail', data.acceptanceEmail);
            }

            const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY))?.token;
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token,
            };

            return await axios.post(
                APP_URLS.COMPANY_LOGO_UPLOAD.replace('{id}', data.id),
                form,
                { headers }
            );
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

const mergeCompanies = createAsyncThunk(
    "companies/merge",
    async (data, thunkApi) => {
        try {
            const { companies } = thunkApi.getState();

            if (companies.isPending && companies.requestId !== thunkApi.requestId) {
                return;
            }
            const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY))?.token;
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }

            const response = await axios.post(
                APP_URLS.COMPANY_MERGE,
                data,
                {
                    headers
                }
            );

            return response;
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

const slice = createSlice({
    name: 'companies',
    initialState: {...defaultState},
    reducers: {
        setFilter: (state, { payload }) => {
            const { field, value } = payload;

            state.filter = {
                ...state.filter,
                [field]: value
            };
        },
        setAllFilters: (state, { payload }) => {
            state.filter = {
                ...payload
            };
        },
        resetFilter: (state) => {
            state.filter = {
                ...defaultState.filter,
            };
        },
        previousPage: (state) => {
            state.filter.pageNumber--;
        },
        nextPage: (state) => {
            state.filter.pageNumber++;
        },
        checkItem: (state, { payload }) => {
            state.selectedItems.push(payload);
        },
        checkAllItems: (state) => {
            state.selectedItems = [...state.data];
        },
        uncheckItem: (state, { payload }) => {
            state.selectedItems = _.filter(state.selectedItems, (item) => item.id !== payload.id)
        },
        uncheckAllItems: (state) => {
            state.selectedItems = [];
        },
        setCompany: (state, { payload }) => {
            const { field, value } = payload;

            state.company = {
                ...state.company,
                [field]: value
            };

            if (state.errors.length)
                state.errors = [...validate(state)];
        },
        clearCompany: (state) => {
            state.company = {...defaultState.company}
        },
    },
    extraReducers: builder  => {
        builder
            .addCase(fetchCompanies.pending, (state, action) => {
                if (state.isPending)
                    return;

                state.error = null;
                state.isPending = true;
                state.requestId = action.meta.requestId;
            })
            .addCase(fetchCompanies.fulfilled, (state, { payload }) => {
                state.isPending = false;
                state.requestId = null;

                if (!payload)
                    return;

                state.filter = {
                    ...state.filter,
                    hasPrevious: payload.data.hasPrevious,
                    hasNext: payload.data.hasNext,
                }
                state.data = payload.data.items;
                state.csvLink = payload.request?.responseURL?.replace("list", "csv");
            })
            .addCase(fetchCompanies.rejected, (state, { error }) => {
                state.error = error.message;
                state.isPending = false;
                state.requestId = null;
            })
            .addCase(createCompany.pending, (state, action) => {
                if (state.isPending)
                    return;

                state.errors = [...validate(state)];

                state.isPending = true;
                state.requestId = action.meta.requestId;
            })
            .addCase(createCompany.fulfilled, (state, { payload }) => {
                if (!payload)
                    return;

                state.isPending = false;
                state.requestId = null;
            })
            .addCase(createCompany.rejected, (state, error) => {
                state.error = error.payload;
                state.isPending = false;
                state.requestId = null;
            })
            .addCase(updateCompany.pending, (state, action) => {
                if (state.isPending)
                    return;

                state.errors = [...validate(state)];

                state.isPending = true;
                state.requestId = action.meta.requestId;
            })
            .addCase(updateCompany.fulfilled, (state, { payload }) => {
                if (!payload)
                    return;

                state.isPending = false;
                state.requestId = null;
            })
            .addCase(updateCompany.rejected, (state, error) => {
                state.error = error.payload;
                state.isPending = false;
                state.requestId = null;
            })
            .addCase(getCompany.pending, (state, action) => {
                if (state.isPending)
                    return;

                state.error = null;
                state.isPending = true;
                state.requestId = action.meta.requestId;
            })
            .addCase(getCompany.fulfilled, (state, { payload }) => {
                if (!payload)
                    return;

                state.company = {...payload.data}
                state.isPending = false;
                state.requestId = null;
            })
            .addCase(getCompany.rejected, (state, { error }) => {
                state.error = error.message;
                state.isPending = false;
                state.requestId = null;
            })
            .addCase(sendLogo.pending, (state, action) => {
                if (state.isPending)
                    return;

                state.error = null;
                state.isPending = true;
                state.requestId = action.meta.requestId;
            })
            .addCase(sendLogo.fulfilled, (state, { payload }) => {
                if (!payload || !payload.data)
                    return;

                state.company.logo = payload.data.logo;
                state.company.logoAcceptanceEmail = payload.data.logoAcceptanceEmail;

                state.isPending = false;
                state.requestId = null;
            })
            .addCase(sendLogo.rejected, (state, { error }) => {
                state.error = error.message;
                state.isPending = false;
                state.requestId = null;
            })
            .addCase(mergeCompanies.pending, (state, action) => {
                if (state.isPending)
                    return;

                state.error = null;
                state.isPending = true;
                state.requestId = action.meta.requestId;
            })
            .addCase(mergeCompanies.fulfilled, (state, { payload }) => {
                state.isPending = false;
                state.requestId = null;

                if (!payload)
                    return;
            })
            .addCase(mergeCompanies.rejected, (state, { error }) => {
                state.error = error.message;
                state.isPending = false;
                state.requestId = null;
            });
    }
});


export const {
    setFilter,
    setAllFilters,
    resetFilter,
    previousPage,
    nextPage,
    checkItem,
    checkAllItems,
    uncheckItem,
    uncheckAllItems,
    setCompany,
    clearCompany,
} = slice.actions;

export {
    fetchCompanies,
    createCompany,
    updateCompany,
    getCompany,
    sendLogo,
    mergeCompanies,
};

export default slice.reducer;