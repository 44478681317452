import { Table, TableBody, TableContainer } from "@mui/material";
import React, { Component } from "react";
import {
  fetchComplains,
  previousPage,
  nextPage,
  resetFilter,
  setFilter,
  checkItem,
  checkAllItems,
  uncheckItem,
  uncheckAllItems,
} from "../app/slices/complainsSlice";
import {
  ComplainsTableFilter,
  ComplainsTableRow,
  TableHeader,
  TablePagination,
} from "../components/table";
import { complainsTableHead } from "../app/config";
import "./screens.styles.scss";
import "../components/table/table.styles.scss";
import { connect } from "react-redux";
import _ from "lodash";

class ComplainsScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: null,
        };

        this.props.resetFilter();
        this.refresh();

        this.handlePreviousPage = this.handlePreviousPage.bind(this);
        this.handleNextPage = this.handleNextPage.bind(this);
        this.handleRequestSort = this.handleRequestSort.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handleSelectAll = this.handleSelectAll.bind(this);
    }

    componentWillUnmount() {
        this.props.resetFilter();
        this.props.uncheckAllItems();
    }

    refresh() {
        this.props.fetchComplains();
    }

    handlePreviousPage() {
        this.props.previousPage();
        this.refresh();
    }

    handleNextPage() {
        this.props.nextPage();
        this.refresh();
    }

    handleRequestSort(event, property) {
        const direction =
            this.props.filter.orderBy === property &&
            this.props.filter.orderDirection === "asc"
                ? "desc"
                : "asc";
        this.props.setFilter({ field: "orderBy", value: property });
        this.props.setFilter({ field: "orderDirection", value: direction });

        this.refresh();
    }

    handleRowCheck(item, isChecked) {
        if (!isChecked) {
            this.props.checkItem(item);
        } else {
            this.props.uncheckItem(item);
        }
    }

    handleSelectAll() {
        if (this.props.selectedItems.length === this.props.complains.length) {
            this.props.uncheckAllItems();
        } else {
            this.props.checkAllItems();
        }
    }

    handleRowClick(id) {
        this.setState({
            open: this.state.open === id ? null : id,
        });
    }

  render() {
    return (
      <div className="fk-screen">
            <ComplainsTableFilter />
            <TableContainer>
                <Table size="medium" className="fk-table">
                    <TableHeader
                        crud
                        withCheckbox
                        items={complainsTableHead}
                        orderBy={this.props.filter.orderBy}
                        order={this.props.filter.orderDirection}
                        numSelected={this.props.selectedItems.length}
                        rowCount={this.props.complains.length}
                        onRequestSort={this.handleRequestSort}
                        onSelectAllClick={this.handleSelectAll}
                        screen="ComplainsScreen"
                    />
                    <TableBody>
                    {this.props.complains.map((complain) => {
                        const isChecked = _.some(this.props.selectedItems, (item) => item.id === complain.id);

                        return (
                            <ComplainsTableRow
                                key={complain.id}
                                checked={isChecked}
                                open={this.state.open === complain.id}
                                complain={complain}
                                columns={complainsTableHead}
                                onCheck={() => this.handleRowCheck(complain, isChecked)}
                                onRowClick={this.handleRowClick}
                            />
                        )
                    })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                filter={this.props.filter}
                handlePreviousPage={this.handlePreviousPage}
                handleNextPage={this.handleNextPage}
            />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
    complains: state.complains.data,
    filter: state.complains.filter,
    selectedItems: state.complains.selectedItems,
});

const actions = {
    fetchComplains,
    previousPage,
    nextPage,
    resetFilter,
    setFilter,
    checkItem,
    checkAllItems,
    uncheckItem,
    uncheckAllItems,
};

export default connect(mapStateToProps, actions)(ComplainsScreen);
