import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

function TablePagination(props) {
    const { t } = useTranslation('Common');

    return (
        <div
            className={`fk-pagination${
                !props.filter.hasPrevious ? " --flex-end" : ""
            }`}
        >
            {props.filter.hasPrevious && (
                <Button
                    className="fk-button fk-button--outline"
                    onClick={props.handlePreviousPage}
                >
                    {t('previous')}
                </Button>
            )}
            {props.filter.hasNext && (
                <Button
                    className="fk-button fk-button--primary"
                    onClick={props.handleNextPage}
                >
                    {t('next')}
                </Button>
            )}
        </div>
    );
}

export default TablePagination;