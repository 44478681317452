import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APP_URLS } from "../config";

const defaultState = {
    data: [],
    isPending: false,
    error: null,
    requestId: null,
};

const fetchComplainTypes = createAsyncThunk(
    "complainTypes/fetch",
    async (sectorId, thunkApi) => {
        try {
            const { complainTypes } = thunkApi.getState();

            if (complainTypes.isPending && complainTypes.requestId !== thunkApi.requestId) {
                return;
            }

            const response = await fetch(
                APP_URLS.COMPLAIN_TYPES.replace('{id}', sectorId),
            );

            return response.json();
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

const slice = createSlice({
    name: 'complainTypes',
    initialState: {...defaultState},
    extraReducers: builder => {
        builder
            .addCase(fetchComplainTypes.pending, (state, action) => {
                if (state.isPending)
                    return;

                state.error = null;
                state.isPending = true;
                state.requestId = action.meta.requestId;
            })
            .addCase(fetchComplainTypes.fulfilled, (state, { payload }) => {
                if (!payload)
                    return;

                state.data = payload.map((type) => ({ value: type.id, text: type.name }));
                state.isPending = false;
                state.requestId = null;
            })
            .addCase(fetchComplainTypes.rejected, (state, { error }) => {
                state.error = error.message;
                state.isPending = false;
                state.requestId = null;
            });
    }
});

export {
    fetchComplainTypes
};

export default slice.reducer;