import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { TrashIcon } from "../../../assets/icons";
import { removePhoto } from "../../../app/slices/complainsSlice";
import { APP_URLS } from '../../../app/config';

function ComplainAttachments({id, attachments, onUpdate}) {
    const dispatch = useDispatch();
    const { t } = useTranslation('ComplainDetailsScreen', { keyPrefix: 'Attachments' });

    const removeFile = async (file) => {
        await dispatch(removePhoto({id, file}));
        await onUpdate();
    }

    if (!attachments?.length)
        return;

    return (
        <div className="fk-form-section">
            <h3 className="title">
                {t('formTitle')}
            </h3>
            <Grid container spacing={2}>
                <Grid item md={2} />
                <Grid item md={8}>
                    <ul className="fk-files-list">
                        <li>
                            {t('attachments')}
                            <a href={`${APP_URLS.BASE}/api/Report/${id}/downloadAllAttachments`} target="_blank" rel="noreferrer">[{t('downloadAll')}]</a>
                        </li>
                        {attachments.map((file) => (
                            <li key={file.fileName}>
                                <div className="file-name-container">
                                    {file.fileName}
                                </div>
                                <div className="file-icons-container">
                                    <a href={`${APP_URLS.BASE}${file.path}`} target="_blank" rel="noreferrer">[{t('download')}]</a>
                                    <button onClick={() => removeFile(file.path)}>
                                        <img src={TrashIcon} alt="" />
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                </Grid>
                <Grid item md={2} />
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    id: state.complains.complain?.id,
    attachments: state.complains.complain?.photos,
});

const actions = {
    removePhoto,
};

export default connect(mapStateToProps, actions)(ComplainAttachments);