import { Button, Grid } from '@mui/material';
import React, { Component } from 'react';
// import { CompanyAdditionalInfoForm, CompanyInfoForm, CompanyInfoSection, CompanySubmitForm } from '../components/forms/company';
import {
    updateConsumer,
    getConsumer,
    changeStatusConsumer,
    removeAdminRoles,
    deleteConsumer,
} from '../app/slices/consumersSlice';
import { ScreenHeader } from '../components/screenHeader';
import { connect } from 'react-redux';
import {
    UserInfoForm,
} from '../components/forms/user';
import { withRouter } from '../wrappers/withRouter';
import { DeleteOutline } from '@mui/icons-material';
import axios from 'axios';
import { APP_URLS } from '../app/config';
import { showModal } from '../app/slices/modalSlice';

class UserEditScreen extends Component {
    constructor(props) {
        super(props);

        this.refresh();

        this.refresh = this.refresh.bind(this);
        this.handleFieldSave = this.handleFieldSave.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleSendSetPassword = this.handleSendSetPassword.bind(this);
        this.handleRemoveRole = this.handleRemoveRole.bind(this);
    }

    async refresh() {
        await this.props.getConsumer(this.props.params.id);
    }

    async handleFieldSave(field, value) {
        await this.props.updateConsumer({
            id: this.props.params.id,
            fieldName: field,
            value: value,
        });

        await this.refresh();
    }

    handleSendSetPassword() {
        axios.get(APP_URLS.USER_SEND_SET_NEW_PASSWORD.replace('{id}', this.props.params.id))
            .then((result) => {
                if (result.status === 200) {
                    this.props.showModal({ message: "Link został pomyślnie wysłany"});
                } else {
                    this.props.showModal({ message: "Wystąpił błąd podczas wysyłania linku"});
                }
            })
    }

    handleStatusChange() {
        this.props.changeStatusConsumer(this.props.model.id).then(() => this.refresh());
    }

    handleDelete() {
        this.props.deleteConsumer(this.props.model.id).then(() => this.props.navigate(-1));
    }

    handleRemoveRole() {
        this.props.removeAdminRoles(this.props.model.id).then(() => this.props.navigate(-1));
    }

    render() {
        return (
            <div className="fk-screen">
                <ScreenHeader
                    withBackButton
                    withSeparator
                    title="userHeader"
                    titleReplacement={['#USER_NAME#', this.props.model.fullName]}
                    subtitle="userSubheader"
                    subtitleReplacement={['#USER_ID#', this.props.model.id]}
                    additionalButtons={
                        <div className="screen-header-buttons --flex-end">
                            <Button className="green" onClick={this.handleSendSetPassword}>Wyślij ponownie link</Button>
                            {this.props.isAdmin && 
                            <Button className="red" onClick={this.handleRemoveRole}><DeleteOutline /> Usuń</Button>
                            }
                        </div>
                    }
                />
                <Grid container spacing={4}>
                    <Grid item md={6}>
                        <UserInfoForm canEdit onUpdate={this.handleFieldSave} isAdmin={this.props.isAdmin} />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    model: state.consumers.consumer,
});

const actions = {
    updateConsumer,
    getConsumer,
    changeStatusConsumer,
    deleteConsumer,
    removeAdminRoles,
    showModal,
};

export default withRouter(connect(mapStateToProps, actions)(UserEditScreen));