import { connect, useDispatch } from "react-redux";
import { companySectors } from "../../../app/config";
import { fetchComplains, sendAgainToCompany, setFilter, uncheckAllItems, sendBulkAgainToCompany } from "../../../app/slices/complainsSlice";
import { FilterMenu } from "../../filterMenu";
import { ScreenHeader } from "../../screenHeader";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { fetchComplainTypes } from "../../../app/slices/complainTypesSlice";
import classNames from "classnames";
import { showModal } from "../../../app/slices/modalSlice";
import _ from "lodash";
import MergeComplainsModal from "../../modal/mergeComplainsModal";

function CompanyComplainsTableFilter({ companyId, filter, complainTypes, hideSearch, hideTitle, listTitle, selectedItems, csvLink }) {
  const dispatch = useDispatch();
  const { t } = useTranslation('ComplainsScreen', { keyPrefix: 'TableFilter' });
  const { t: t_common } = useTranslation('Common');
  let timer = null;

  const [showMergeModal, setShowMergeModal] = useState(false);

  useEffect(() => {
      dispatch(setFilter({ field: 'type', value: null }))
      dispatch(fetchComplainTypes(filter.sector?.value ?? -1));
  }, [filter.sector, dispatch]);

  let handleFilterChange = (field, value) => {
    clearTimeout(timer);

    dispatch(setFilter({ field, value, }));

    timer = setTimeout(() => {
      dispatch(fetchComplains({ companyId }));
    }, 500);
  };

  const handleMergeSelectedComplains = () => {
    if (!selectedItems || selectedItems.length <= 1) {
      dispatch(showModal({
        title: 'Nie zaznaczono skarg do scalenia',
        message: 'Aby scalić skargi, należy wybrać z listy min. dwie pozycje.',
        mode: 'danger',
      }));

      return;
    }

    if (!_.every(selectedItems, {mergeInfo: selectedItems[0].mergeInfo})) {
      dispatch(showModal({
        title: 'Wykryto niezgodność w danych skarg',
        message: 'Aby scalić skargi, powinny dotyczyć tego samego zgłaszającego, firmy i jej rodzaju działalności oraz rodzaju zgłoszenia.',
        mode: 'danger',
      }));

      return;
    }

    setShowMergeModal(true);
  }

  const onMergeModalComplete = async (isSuccess) => {
    setShowMergeModal(false);

    if (isSuccess) {
      dispatch(showModal({
        title: 'Wybrane skargi zostały połączone.',
        mode: 'normal',
      }));

      dispatch(uncheckAllItems());
      dispatch(fetchComplains({ companyId }));
    } else {
      dispatch(showModal({
        title: 'Wystąpił błąd podczas scalania skarg.',
        mode: 'danger',
      }));
    }
  }

  const handleSendAgainBulkToCompany = async () => {
    if (!selectedItems || selectedItems.length < 1) {
      dispatch(showModal({
        message: 'Proszę wybrać co najmniej jedną skargę.',
        mode: 'danger',
      }));
      return;
    }
    
    let ids = selectedItems.map(item => item.id);
    let result = await dispatch(sendBulkAgainToCompany(ids));

    if (!result.hasOwnProperty('error')) {
      dispatch(showModal({
        title: 'Wybrane skargi zostały wysłane ponownie.',
        mode: 'normal',
      }));
    } else {
      dispatch(showModal({
        title: 'Wystąpił błąd podczas wysyłania skarg ponownie.',
        mode: 'danger',
      }));
    }
  }
  
  
  const handleSendAgainToCompany = async () => {
    if (!selectedItems || selectedItems.length !== 1) {
      dispatch(showModal({
        message: 'Proszę wybrać dokładnie jedną skargę.',
        mode: 'danger',
      }));

      return;
    }
    
    let result = await dispatch(sendAgainToCompany(selectedItems[0].id));

    if (!result.hasOwnProperty('error')) {
      dispatch(showModal({
        title: 'Wybrana skarga została wysłana ponownie.',
        mode: 'normal',
      }));
    } else {
      dispatch(showModal({
        title: 'Wystąpił błąd podczas wysyłania skargi ponownie.',
        mode: 'danger',
      }));
    }
  }

  return (
    <>
        {!hideTitle &&
          <ScreenHeader withSearch={!hideSearch} search={filter.search} onChange={(event) => handleFilterChange('search', event.target.value)} />
        }
        <div className={
          classNames(
            'fk-filter-container',
            {
              '--only-filter': !listTitle,
            }
          )
        }>
          <h3>{listTitle}</h3>
          <div className="fk-filter">
              <FilterMenu
                  label={t('sector.text')}
                  options={companySectors}
                  selected={filter.sector}
                  onChange={(option) => handleFilterChange('sector', option)}
              />
              <FilterMenu
                  label={t('type.text')}
                  options={complainTypes}
                  selected={filter.type}
                  onChange={(option) => handleFilterChange('type', option)}
              />
              <FilterMenu
                  actionButton
                  buttonText={t('mergeSelectedComplains')}
                  onClick={handleMergeSelectedComplains}
              />
              <FilterMenu
                  actionButton
                  buttonText={t('sendAgain')}
                  onClick={handleSendAgainBulkToCompany}
              />
              <FilterMenu
                  actionButton
                  link={csvLink}
                  buttonText={t_common('downloadCsv')}
              />
          </div>
        </div>
        <MergeComplainsModal
          show={showMergeModal}
          complains={selectedItems}
          fallback={onMergeModalComplete}
          onCancel={() => setShowMergeModal(false)}
        />
    </>
  );
}

const mapStateToProps = state => ({
  filter: state.complains.filter,
  complainTypes: state.complainTypes.data,
  selectedItems: state.complains.selectedItems,
  csvLink: state.complains.csvLink,
});

export default connect(mapStateToProps, {})(CompanyComplainsTableFilter);