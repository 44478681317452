import { format } from "date-fns";
import { connect, useDispatch } from "react-redux";
import { logTypes } from "../../../app/config";
import { fetchLogs, setFilter } from "../../../app/slices/logsSlice";
import { FilterMenu } from "../../filterMenu";
import { ScreenHeader } from "../../screenHeader";
import { useTranslation } from "react-i18next";
import _ from "lodash";

function ComplainsTableFilter({ filter, hideSearch, hideTitle, selectedItems, csvLink }) {
  const dispatch = useDispatch();
  const { t } = useTranslation('LogsScreen', { keyPrefix: 'TableFilter' });
  const { t: t_type } = useTranslation('LogsScreen', { keyPrefix: 'LogTypes'});
  const { t: t_common } = useTranslation('Common');
  let timer = null;

  const handleFilterChange = (field, value) => {
    clearTimeout(timer);

    dispatch(setFilter({ field, value, }));

    timer = setTimeout(() => {
      dispatch(fetchLogs());
    }, 500);
  };

  return (
    <>
        {!hideTitle &&
          <ScreenHeader withSearch={!hideSearch} search={filter.search} onChange={(event) => handleFilterChange('search', event.target.value)} />
        }
        <div className="fk-filter-container --only-filter">
          <div className="fk-filter">
              <FilterMenu
                  label={t('type.text')}
                  options={logTypes}
                  selected={filter.type}
                  onChange={(option) => handleFilterChange('type', option)}
                  tFunc={t_type}
                  tPrefix=""
              />
              <FilterMenu
                  datepicker
                  label={t('createdAt.text')}
                  options={[]}
                  selected={filter.dateFrom}
                  buttonText={`${t('createdAt.from')} ${filter.dateFrom ? format(new Date(filter.dateFrom), "yyyy-MM-dd") : ''}`}
                  onChange={(option) => handleFilterChange('dateFrom', option)}
              />
              <FilterMenu
                  datepicker
                  options={[]}
                  selected={filter.dateTo}
                  buttonText={`${t('createdAt.to')} ${filter.dateTo ? format(new Date(filter.dateTo), "yyyy-MM-dd") : ''}`}
                  onChange={(option) => handleFilterChange('dateTo', option)}
              />
              <FilterMenu
                  actionButton
                  link={csvLink}
                  buttonText={t_common('downloadCsv')}
              />
          </div>
        </div>
    </>
  );
}

const mapStateToProps = state => ({
  filter: state.logs.filter,
  csvLink: state.logs.csvLink,
});

export default connect(mapStateToProps, {})(ComplainsTableFilter);