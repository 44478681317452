export const consumerComplainsTableHead = [
    {
      id: 'createdAt',
    },
    {
      id: 'companyName'
    },
    {
      id: 'title',
    },
    {
      id: 'isPublic',
    },
    {
      id: 'type'
    },
    {
      id: 'number',
    },
    {
      id: 'haveComments',
    },
    {
      id: 'status',
    },
  ];