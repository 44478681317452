import {TailSpin} from "react-loader-spinner";
import React, {useEffect, useState} from "react";
import Stat from "./Stat";
import ReportCard from "./ReportCard";
import {CompaniesTableFilter, CompaniesTableRow, TableHeader, TablePagination} from "../table";
import {Table, TableBody, TableContainer} from "@mui/material";
import {companiesTableHead} from "../../app/config";
import _ from "lodash";
import {
    checkAllItems,
    checkItem,
    fetchCompanies,
    nextPage,
    previousPage,
    resetFilter,
    setFilter, uncheckAllItems, uncheckItem
} from "../../app/slices/companiesSlice";
import {connect} from "react-redux";
import {companiesSummaryTableHead} from "../../app/config/tables/companiesTableHead";

const CompaniesTab = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [activeFilter, setActiveFilter] = useState(0);
    const [open, setOpen] = useState(null);

    const clearFilters = () => {
        
    }

    const onMostClick = (period) => {
        switch (period) {
            case 'month':
                setActiveFilter(1);
                break;
            case 'year':
                setActiveFilter(2);
                break;
        }
    }

    useEffect(() => {
        props.fetchCompanies();

        return () => {
            props.resetFilter();
            props.uncheckAllItems();
        }
    }, []);

    const refresh = () => {
        props.fetchCompanies();
    }

    const handleRequestSort = (event, property) => {
        const direction =
            props.filter.orderBy === property &&
            props.filter.orderDirection === "asc"
                ? "desc"
                : "asc";
        props.setFilter({ field: "orderBy", value: property });
        props.setFilter({ field: "orderDirection", value: direction });

        refresh();
    }

    const handleRowCheck = (item, isChecked) => {
        if (!isChecked) {
            props.checkItem(item);
        } else {
            props.uncheckItem(item);
        }

    }
    const handleSelectAll = () => {
        if (props.selectedItems.length === props.companies.length) {
            props.uncheckAllItems();
        } else {
            props.checkAllItems();
        }
    }

    const handleRowClick = (id) => {
        setOpen(prev => prev === id ? null : id);
    }

    const handlePreviousPage = () => {
        props.previousPage();
        refresh();
    }

    const handleNextPage = () => {
        props.nextPage();
        refresh();
    }

    if(isLoading) {
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '80vh'}}>
                <TailSpin
                    visible={true}
                    height="80"
                    width="80"
                    color="#4fa94d"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
            </div>
        )
    }
    
    
    return (
        <div className="fk-screen">
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <span className="rowHeaderText">Quick filters </span>
                <span style={{color: '#B2B2B2', marginLeft: 12, cursor: 'pointer'}}
                      onClick={clearFilters}>Clear filters</span>
            </div>

            <div className="rowContainer">
                <ReportCard label={'Cases created'} isLoading={isLoading}>
                    <Stat value={'Most'}
                          description={"this month"}
                          onClick={() => onMostClick('month')}
                          period={'month'}
                          filterNumber={1}
                          activeFilter={activeFilter}
                    />

                    <Stat value={'Most'}
                          description={"this year"}
                          onClick={() => onMostClick('year')}
                          period={'year'}
                          filterNumber={2}
                          activeFilter={activeFilter}
                    />
                </ReportCard>
            </div>


            <CompaniesTableFilter />
            <TableContainer>
                <Table size="medium" className="fk-table">
                    <TableHeader
                        crud
                        withCheckbox
                        items={companiesSummaryTableHead}
                        orderBy={props.filter.orderBy}
                        order={props.filter.orderDirection}
                        numSelected={props.selectedItems.length}
                        rowCount={props.companies.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAll}
                        screen="CompaniesScreen"
                    />

                    <TableBody>
                        {props.companies.map((company) => {
                            const isChecked = _.some(props.selectedItems, (item) => item.id === company.id);

                            return (
                                <CompaniesTableRow
                                    key={company.id}
                                    checked={isChecked}
                                    company={company}
                                    open={open === company.id}
                                    onRowClick={handleRowClick}
                                    onCheck={() => handleRowCheck(company, isChecked)}
                                />
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                filter={props.filter}
                handlePreviousPage={handlePreviousPage}
                handleNextPage={handleNextPage}
            />
            
            
        </div>
    )
}

const mapStateToProps = (state) => ({
    companies: state.companies.data,
    filter: state.companies.filter,
    selectedItems: state.companies.selectedItems,
});

const actions = {
    fetchCompanies,
    previousPage,
    nextPage,
    resetFilter,
    setFilter,
    checkItem,
    checkAllItems,
    uncheckItem,
    uncheckAllItems,
};

export default connect(mapStateToProps, actions)(CompaniesTab);
