import { useRef } from "react";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
  ];
  
const AutoLogout = ({ children }) => {
    const navigate = useNavigate();
    let timer = useRef(null);
  
    const resetTimer = useCallback(() => {
        if (timer) clearTimeout(timer);
    }, [timer]);

    const logoutAction = useCallback(() => {
        localStorage.clear();
        navigate("/login");
    }, [navigate]);

    const handleLogoutTimer = useCallback(() => {
        timer.current = setTimeout(() => {

            resetTimer();

            Object.values(events).forEach((item) => {
                window.removeEventListener(item, resetTimer);
            });

            logoutAction();
        }, 30 * 60 * 1000);
    }, [timer, resetTimer, logoutAction]);
  
    useEffect(() => {
        Object.values(events).forEach((item) => {
            window.addEventListener(item, () => {
                resetTimer();
                handleLogoutTimer();
            });
        });
    }, [resetTimer, handleLogoutTimer]);
  
    return children;
  };
  
  export default AutoLogout;