import { Grid } from '@mui/material';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    CompanyAdditionalInfoForm,
    CompanyComplainsList,
    CompanyInfoForm,
    CompanyInfoSection,
    CompanyScoreSection,
    CompanySectorsSection,
} from '../components/forms/company';
import { ScreenHeader } from '../components/screenHeader';
import { getCompany, updateCompany } from '../app/slices/companiesSlice';
import {
    fetchComplains,
    previousPage,
    nextPage,
    resetFilter,
    setFilter,
    checkItem,
    checkAllItems,
    uncheckItem,
    uncheckAllItems,
} from "../app/slices/complainsSlice";
import { withRouter } from '../wrappers/withRouter';
import {LAYOUT_CONFIG} from "../layoutConfig";

class CompanyEditScreen extends Component {
    constructor(props) {
        super(props);

        this.refresh();

        this.props.resetFilter();
        this.refreshComplains();

        this.refresh = this.refresh.bind(this);
        this.refreshComplains = this.refreshComplains.bind(this);
        this.handleFieldSave = this.handleFieldSave.bind(this);
        this.handlePreviousPage = this.handlePreviousPage.bind(this);
        this.handleNextPage = this.handleNextPage.bind(this);
        this.handleRequestSort = this.handleRequestSort.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handleRowCheck = this.handleRowCheck.bind(this);
        this.handleSelectAll = this.handleSelectAll.bind(this);
    }

    async refresh() {
        await this.props.getCompany(this.props.params.id);
    }

    async refreshComplains() {
        await this.props.fetchComplains({ companyId: this.props.params.id });
    }

    async handleFieldSave(field, value) {
        await this.props.updateCompany({
            id: this.props.params.id,
            fieldName: field,
            value: value,
        });

        await this.refresh();
    }

    handlePreviousPage() {
        this.props.previousPage();
        this.refreshComplains();
    }

    handleNextPage() {
        this.props.nextPage();
        this.refreshComplains();
    }

    handleRequestSort(event, property) {
        console.log("🚀 ~ file: companyEdit.screen.jsx:76 ~ CompanyEditScreen ~ handleRequestSort ~ property:", property)

        const direction =
            this.props.filter.orderBy === property &&
            this.props.filter.orderDirection === "asc"
                ? "desc"
                : "asc";
        this.props.setFilter({ field: "orderBy", value: property });
        this.props.setFilter({ field: "orderDirection", value: direction });

        this.refreshComplains();
    }

    handleRowCheck(item, isChecked) {
        if (!isChecked) {
            this.props.checkItem(item);
        } else {
            this.props.uncheckItem(item);
        }
    }

    handleSelectAll() {
        if (this.props.selectedItems.length === this.props.complains.length) {
            this.props.uncheckAllItems();
        } else {
            this.props.checkAllItems();
        }
    }

    handleRowClick(id) {
        this.setState({
            open: this.state.open === id ? null : id,
        });
    }

    render() {
        if (!this.props.model.id)
            return;

        return (
            <div className="fk-screen">
                <ScreenHeader
                    withBackButton
                    withSeparator
                    title="companyHeader"
                    titleReplacement={['#COMPANY_NAME#', this.props.model.name]}
                    subtitle="companySubheader"
                    subtitleReplacement={['#COMPANY_ID#', this.props.model.id]}
                />
                <Grid container spacing={4}>
                    <Grid item md={6}>
                        <CompanyInfoForm canEdit onUpdate={this.handleFieldSave} />
                        {this.props.model.sectorsData.length <= 1 &&
                            <CompanyAdditionalInfoForm canEdit onUpdate={this.handleFieldSave} />
                        }
                    </Grid>
                    <Grid item md={6}>
                        <CompanyInfoSection onRefresh={this.refresh}/>
                        { LAYOUT_CONFIG.COMPANY_DETAILS.SHOW_POINTS && <CompanyScoreSection onUpdate={this.handleFieldSave} /> }
                    </Grid>
                    {this.props.model.sectorsData.length > 0 &&
                        <Grid item md={12}>
                            <CompanySectorsSection onUpdate={this.refresh}/>
                        </Grid>
                    }
                </Grid>
                <CompanyComplainsList
                    companyId={this.props.model.id}
                    selectedItems={this.props.selectedItems}
                    onRequestSort={this.handleRequestSort}
                    onRowCheck={this.handleRowCheck}
                    onSelectAll={this.handleSelectAll}
                    onPreviousPage={this.handlePreviousPage}
                    onNextPage={this.handleNextPage}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    model: state.companies.company,
    complains: state.complains.data,
    filter: state.complains.filter,
    selectedItems: state.complains.selectedItems,
});

const actions = {
    fetchComplains,
    previousPage,
    nextPage,
    resetFilter,
    setFilter,
    checkItem,
    checkAllItems,
    uncheckItem,
    uncheckAllItems,
    getCompany,
    updateCompany,
};

export default withRouter(connect(mapStateToProps, actions)(CompanyEditScreen));