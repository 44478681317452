import _ from "lodash";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Logo } from '../../assets/images';
import { menuItems } from '../../app/config';
import { useAuth } from "../../hooks";
import { useTranslation } from "react-i18next";
import { Button, MenuItem, Menu as MenuMui } from "@mui/material";
import { useDispatch } from "react-redux";
import { logout } from "../../app/slices/authSlice";

function Menu() {
    const navigate = useNavigate();
    const location = useLocation();
    const auth = useAuth();
    const dispatch = useDispatch();
    const { t } = useTranslation('Pages');
    const { t: t_button } = useTranslation('Buttons');
    const [currentPage, setCurrentPage] = React.useState(location.pathname);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    }
    const handleLogout = () => {
        setAnchorEl(null);
        dispatch(logout());
        navigate('/');
    };

    React.useEffect(() => {
        setCurrentPage(location.pathname);
    }, [location]);

    const checkIfActive = (item) =>
        _.find(item.pagesIncluded, (o) =>
            currentPage.length > 1 && t(o.url).length > 1
                ? t(o.url).startsWith(currentPage) || currentPage.startsWith(t(o.url))
                : currentPage === t(o.url)
        );

    return (
        <header className="fk-header">
            <div className="menu-container">
                <img className="logo" src={Logo} alt="Logo" />
                <ul className="menu">
                    {menuItems.map((item, index) => (
                        <li key={index} className={`menu-link ${checkIfActive(item) ? 'active' : ''}`}>
                            <Link to={t(item.destination)}>{t(item.text)}</Link>
                        </li>
                    ))}
                </ul>
            </div>
            <Button className="account-menu" onClick={handleClick}>
                <span className="user-name">{auth.userData.firstName} {auth.userData.lastName}</span>
                <span className="user-email">{auth.userData.email}</span>
            </Button>
            <MenuMui
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleLogout}>{t_button('logout')}</MenuItem>
            </MenuMui>
        </header>
    );
}

export default Menu;