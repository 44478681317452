import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { STORAGE, APP_URLS } from '../config';

const defaultState = {
    filter: {
        search: '',
        dateFrom: null,
        dateTo: null,
        type: null,
        orderBy: 'createdAt',
        orderDirection: 'desc',
        pageNumber: 1,
        pageSize: 25,
        hasPrevious: false,
        hasNext: false,
    },
    data: [],
    isPending: false,
    requestId: null,
    error: null,
}

const fetchLogs = createAsyncThunk(
    'logs/fetch',
    async (data, thunkApi) => {
        try {
            const { logs } = thunkApi.getState();

            if (logs.isPending && logs.requestId !== thunkApi.requestId) {
                return;
            }

            let params = {
                pageSize: logs.filter.pageSize ? logs.filter.pageSize : 25,
                pageNumber: logs.filter.pageNumber ? logs.filter.pageNumber : 1,
                ...data
            };

            params = (logs.filter.search) ? {...params, search: logs.filter.search} : {...params};
            params = (logs.filter.dateFrom) ? {...params, dateFrom: logs.filter.dateFrom} : {...params};
            params = (logs.filter.dateTo) ? {...params, dateTo: logs.filter.dateTo} : {...params};
            params = (logs.filter.type) ? {...params, type: logs.filter.type.value} : {...params};
            params = (logs.filter.orderBy) ? {...params, orderBy: logs.filter.orderBy} : {...params};
            params = (logs.filter.orderDirection) ? {...params, orderDirection: logs.filter.orderDirection} : {...params};

            const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY));
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token?.token,
            };

            const response = await axios.get(
                APP_URLS.LOGS_LIST,
                { params, headers});

            return response;
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

const slice = createSlice({
    name: 'logs',
    initialState: {...defaultState},
    reducers: {
        setFilter: (state, { payload }) => {
            const { field, value } = payload;

            state.filter = {
                ...state.filter,
                [field]: value
            };
        },
        setAllFilters: (state, { payload }) => {
            state.filter = {
                ...payload
            };
        },
        resetFilter: (state) => {
            state.filter = {
                ...defaultState.filter,
            };

            state.selectedItems = [];
        },
        previousPage: (state) => {
            state.filter.pageNumber--;
        },
        nextPage: (state) => {
            state.filter.pageNumber++;
        }
    },
    extraReducers: builder  => {
        builder
            .addCase(fetchLogs.pending, (state, action) => {
                if (state.isPending)
                    return;

                state.data = [];
                state.error = null;
                state.isPending = true;
                state.requestId = action.meta.requestId;
            })
            .addCase(fetchLogs.fulfilled, (state, { payload }) => {
                state.isPending = false;
                state.requestId = null;

                if (!payload)
                    return;

                state.filter = {
                    ...state.filter,
                    hasPrevious: payload.data.hasPrevious,
                    hasNext: payload.data.hasNext,
                }
                state.data = payload.data.items;
                state.csvLink = payload.request?.responseURL?.replace("list", "csv");
            })
            .addCase(fetchLogs.rejected, (state, { error }) => {
                state.error = error.message;
                state.isPending = false;
                state.requestId = null;
            });
    }
});

export const {
    setFilter,
    setAllFilters,
    resetFilter,
    previousPage,
    nextPage,
} = slice.actions;

export {
    fetchLogs,
};

export default slice.reducer;