import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

const ConfirmModal = ({title, message, onClose, open, onConfirm}) => {
    const handleClose = () => {
        onClose();
    };

    const handleConfirm = () => {
        onConfirm();
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>{title}</DialogTitle>
            {message && 
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            }
            <DialogActions>
                <Button sx={{color: '#41A046'}} onClick={handleConfirm}>Agree</Button>
                <Button sx={{color: 'red'}} onClick={handleClose} autoFocus>Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmModal;