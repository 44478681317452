import {Layout, Modal} from './components';
import {Routes, Route} from 'react-router-dom';
import {Provider} from 'react-redux';
import store from './app/store';
import {
    CompaniesScreen,
    CompanyCreateScreen,
    CompanyEditScreen,
    ComplainEditScreen,
    ComplainsScreen,
    ConsumerEditScreen,
    ConsumersScreen,
    LoginScreen,
    UsersScreen,
    UserCreateScreen,
    UserEditScreen,
    SetPasswordScreen,
    RememberPasswordScreen,
    LogsScreen,
} from './screens';
import './App.scss';
import {RequireAuth} from './components/auth';
import {useTranslation} from 'react-i18next';
import AutoLogout from './wrappers/autoLogout';
import ReportsScreen from "./screens/reports.screen";

function App() {
    const {t} = useTranslation('Pages')

    return (
        <Provider store={store}>
            <Layout>
                {/* <AutoLogout> */}
                <Modal/>
                <Routes>
                    <Route exact path={t('consumersLink')} element={
                        <RequireAuth>
                            <ConsumersScreen/>
                        </RequireAuth>
                    }
                    />
                    <Route exact path={`${t('consumerLink')}:id`} element={
                        <RequireAuth>
                            <ConsumerEditScreen/>
                        </RequireAuth>
                    }
                    />
                    <Route exact path={t('usersLink')} element={
                        <RequireAuth>
                            <UsersScreen/>
                        </RequireAuth>
                    }
                    />
                    <Route exact path={t('userCreateLink')} element={
                        <RequireAuth>
                            <UserCreateScreen/>
                        </RequireAuth>
                    }
                    />
                    <Route exact path={t('userLink')} element={
                        <RequireAuth>
                            <UserEditScreen/>
                        </RequireAuth>
                    }
                    />
                    <Route exact path={t('complainsLink')} element={
                        <RequireAuth>
                            <ComplainsScreen/>
                        </RequireAuth>
                    }
                    />
                    <Route exact path={`${t('complainLink')}:id`} element={
                        <RequireAuth>
                            <ComplainEditScreen/>
                        </RequireAuth>
                    }
                    />
                    <Route exact path={t('companiesLink')} element={
                        <RequireAuth>
                            <CompaniesScreen/>
                        </RequireAuth>
                    }
                    />
                    <Route exact path={t('companyCreateLink')} element={
                        <RequireAuth>
                            <CompanyCreateScreen/>
                        </RequireAuth>
                    }
                    />
                    <Route exact path={`${t('companyLink')}:id`} element={
                        <RequireAuth>
                            <CompanyEditScreen/>
                        </RequireAuth>
                    }
                    />
                    <Route exact path={t('loginLink')} element={
                        <LoginScreen/>
                    }
                    />
                    <Route exact path={t('setPasswordLink')} element={
                        <SetPasswordScreen/>
                    }
                    />
                    <Route exact path={t('rememberPasswordLink')} element={
                        <RememberPasswordScreen/>
                    }
                    />
                    <Route exact path={t('logsLink')} element={
                        <RequireAuth>
                            <LogsScreen />
                        </RequireAuth>
                    }
                    />
                    <Route exact path={t('reportsLink')} element={
                        <RequireAuth>
                            <ReportsScreen />
                        </RequireAuth>
                    }
                    />
                </Routes>
                {/* </AutoLogout> */}
            </Layout>
        </Provider>
    );
}

export default App;
