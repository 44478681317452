import { Grid } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { setComplain } from "../../../app/slices/complainsSlice";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

function ComplainAdminsComment({ onUpdate, adminsComment, setComplain }) {
    const { t } = useTranslation('ComplainDetailsScreen', { keyPrefix: 'AdminComment' });

    const [editEnabled, setEditEnabled] = useState(false);

    const handleFieldChange = (field, value) => setComplain({ field, value });

    const handleUpdate = async () => {
        await onUpdate('AdminsComment', adminsComment);

        setEditEnabled(false);
    }

    return (
        <div className="fk-form-section" style={{width: '60%'}}>
            <h3 className="title">
                {t('formTitle')}
            </h3>
            <Grid container spacing={2} className="fk-form">
                <Grid item xs={3} className="fk-label">
                    <label htmlFor="adminComment">{t('commentLabel')}</label>
                </Grid>
                <Grid item xs={8}>
                    <div  className="fk-input--with-counter">
                        <textarea className="fk-input fk-input--full-width"
                            disabled={!editEnabled}
                            placeholder={t('commentPlaceholder')}
                            value={adminsComment}
                            onChange={(event) => handleFieldChange('adminsComment', event.target.value)}
                            rows={5}
                            maxLength={8000}
                        />
                        <div className="fk-word-counter">
                            {t('charsLimit')} {adminsComment.length} / 8000
                        </div>
                    </div>
                </Grid>
                <Grid item xs={1}>
                    {!editEnabled
                        ? <button
                            className="fk-edit-button"
                            onClick={() => setEditEnabled(true)}
                        >
                            <EditIcon />
                        </button>
                        : <button
                            className="fk-edit-button"
                            onClick={() => handleUpdate()}
                        >
                            <SaveIcon />
                        </button>
                    }
                </Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    adminsComment: state.complains.complain?.adminsComment ?? '',
});

const actions = {
    setComplain
};

export default connect(mapStateToProps, actions)(ComplainAdminsComment);