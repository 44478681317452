import "./reports.scss"
const ReportCard = ({children, label, isLoading}) => {
    
    return (
        <div className="card">
            <span className="label">{label}</span>
            <div className="childrenContainer">
                {!isLoading && children}
            </div>
        </div>
    )
}

export default ReportCard;