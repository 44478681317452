import { AttachFile } from "@mui/icons-material";
import { Button, Grid, IconButton } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import ComplainMessage from "../../common/complainMessage";
import { showModal } from "../../../app/slices/modalSlice";
import { sendMessage } from "../../../app/slices/complainsSlice";
import { TrashIcon } from "../../../assets/icons";
import ComplaintMessageEditForm from "./ComplaintMessageEditForm/complaintMessageEditForm";

function ComplainMessages({ id, messages: fetchedMessages, showModal, sendMessage, onUpdate }) {
    const { t } = useTranslation('ComplainDetailsScreen', { keyPrefix: 'Messages' });
    const [newMessage, setNewMessage] = useState("");
    const [attachments, setAttachments] = useState([]);
    const [messages, setMessages] = useState(fetchedMessages);

    const handleMessageAttachmentsChange = ({ target: { files }}) => {
        const maxFileSize = 10000000;
        const maxfiles = 10;
        let acceptedFiles = [...attachments];
        let rejectedFiles = [];

        for (let i = 0; i < files.length; i++) {
            let file = files[i];

            if (acceptedFiles.length === maxfiles) {
                file.reason = this.t('common:maxFilesAmountReached');
                rejectedFiles.push(file);
                continue;
            }

            if (file.size > maxFileSize) {
                file.reason = this.t('common:fileTooLarge');
                rejectedFiles.push(file);
                continue;
            }

            acceptedFiles.push(file);
        }

        setAttachments(acceptedFiles);

        if (rejectedFiles.length) {
            showModal({
                message: <div className="rejected-files-dialog">
                    <h4>{this.t('complaint:followingFilesWereRejected')}</h4>
                    {rejectedFiles.map((file, index) => (
                        <p className="file-line">
                            {file.name}
                            <span>{file.reason}</span>
                        </p>
                    ))}
                </div>,
            });
        }
    }

    const handleDeleteMessage = (id) => {
        setMessages(prev => {
            return prev.filter(i => i.id !== id);
        })
    }
    
    const handleRemoveAttachment = (forRemove) => {
        setAttachments([...attachments.filter((attachment) => attachment !== forRemove)]);
    }

    const sendNewMessage = () => {
        sendMessage({
            id: id,
            reporter: false,
            company: false,
            admin: true,
            message: newMessage,
            attachments: attachments,
        }).then(async (data) => {
            const success = data.payload?.status === 200;
            showModal({
                message: (success) ? t('messageSent') : t('messageSentError'),
                mode: (success) ? 'normal' : 'danger',
                fallback: async () => {
                    if (success) {
                        await onUpdate();
                    }
                }
            });

            setNewMessage('');
            setAttachments([]);
        });
    };

    return (
        <div className="fk-complain-messages">
            <hr style={{marginTop: '30px'}}/>
            <h3 className="complain-messages-title">{t('formTitle')} [{messages.length}]</h3>

            <Grid container spacing={2} className="fk-form">
                <Grid item md={1} className="fk-label">
                    <label>
                        {t('answerLabel')}
                    </label>
                </Grid>
                <Grid item md={8}>
                    <div className="fk-input--with-counter">
                        <textarea className="fk-input fk-input--full-width"
                            placeholder={t('answerPlaceholder')}
                            value={newMessage}
                            onChange={(event) => setNewMessage(event.target.value)}
                            rows={5}
                            maxLength={8000}
                        />
                        <div className="fk-word-counter">
                            {t('charsLimit')} {newMessage.length} / 8000
                        </div>
                    </div>
                </Grid>
                <Grid item md={3}>
                    <div className="fk-complain-send-message-buttons">
                        <IconButton
                            className="message-attachments-upload"
                            aria-label="upload"
                            component="label"
                        >
                            <input hidden accept="*" type="file" multiple onChange={handleMessageAttachmentsChange}/>
                            <AttachFile />
                        </IconButton>
                        <Button
                            fullWidth
                            className="fk-button fk-button--medium fk-button--full-width fk-button--primary"
                            onClick={sendNewMessage}
                        >
                            {t('sendMessageButton')}
                        </Button>
                    </div>
                </Grid>
                <Grid item md={1} />
                <Grid item md={11} className="helper-text">
                    {attachments.length > 0 &&
                        <ul className="fk-message-files-list --flex-start">
                            <li>
                                {t('choosenFiles')}
                            </li>
                            {attachments.map((attachment, index) => (
                                <li key={index}>
                                    {attachment.name}
                                    <button onClick={() => handleRemoveAttachment(attachment)}>
                                        <img src={TrashIcon} alt="" />
                                    </button>
                                </li>
                            ))}
                        </ul>
                    }
                    {t('beforeMessageSendWarning')}
                </Grid>
            </Grid>
            {messages?.map((message, index) => (
                <ComplainMessage key={index} data={message} onDelete={handleDeleteMessage} />
            ))}
        </div>
    );
}

const mapStateToProps = (state) => ({
    id: state.complains.complain?.id,
    messages: state.complains.complain?.reportMessages ?? [],
});

const actions = {
    showModal,
    sendMessage,
};

export default connect(mapStateToProps, actions)(ComplainMessages);