import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .init({
    resources: {
      pl: require('./locales/pl/default.json'),
      en: require('./locales/en/default.json'),
    },
    lng: 'pl',
    fallbackLng: 'pl',
    debug: false,
    interpolation: {
      escapeValue: false,
    }
  });

export default i18n;