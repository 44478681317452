// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fk-screen {
  padding: 20px;
}
.fk-screen .fk-filter-container {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #696969;
  margin: 20px 0 10px 0;
  padding: 0 0 10px 0;
}
.fk-screen .fk-filter-container.--only-filter {
  justify-content: flex-end;
}
.fk-screen .fk-filter-container button {
  display: flex;
  align-items: center;
  padding: 0;
  font: normal normal bold 16px/21px Noto Sans;
  color: #41A046;
}
.fk-screen .fk-filter-container button:hover {
  background-color: transparent;
  opacity: 0.8;
}
.fk-screen .fk-filter-container button img {
  margin-right: 5px;
}
.fk-screen .fk-filter-container .fk-filter {
  display: flex;
  justify-content: flex-end;
  gap: 25px;
}
.fk-screen .fk-filter-container h3 {
  margin: 0 0 0 10px;
  font: normal normal bold 20px/24px Noto Sans;
}`, "",{"version":3,"sources":["webpack://./src/screens/screens.styles.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;AADJ;AAGI;EACI,aAAA;EACA,8BAAA;EACA,gCAAA;EACA,qBAAA;EACA,mBAAA;AADR;AAGQ;EACI,yBAAA;AADZ;AAIQ;EACI,aAAA;EACA,mBAAA;EACA,UAAA;EACA,4CAAA;EACA,cAAA;AAFZ;AAIY;EACI,6BAAA;EACA,YAAA;AAFhB;AAKY;EACI,iBAAA;AAHhB;AAOQ;EACI,aAAA;EACA,yBAAA;EACA,SAAA;AALZ;AAQQ;EACI,kBAAA;EACA,4CAAA;AANZ","sourcesContent":["@import '../styles/variables';\n\n.fk-screen {\n    padding: 20px;\n\n    .fk-filter-container {\n        display: flex;\n        justify-content: space-between;\n        border-bottom: 1px solid #696969;\n        margin: 20px 0 10px 0;\n        padding: 0 0 10px 0;\n\n        &.--only-filter {\n            justify-content: flex-end;\n        }\n\n        button {\n            display: flex;\n            align-items: center;\n            padding: 0;\n            font: normal normal bold 16px/21px Noto Sans;\n            color: #41A046;\n\n            &:hover {\n                background-color: transparent;\n                opacity: .8;\n            }\n\n            img {\n                margin-right: 5px;\n            }\n        }\n\n        .fk-filter {\n            display: flex;\n            justify-content: flex-end;\n            gap: 25px;\n        }\n\n        h3 {\n            margin: 0 0 0 10px;\n            font: normal normal bold 20px/24px Noto Sans;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
