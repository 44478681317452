import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getFormData } from '../../utils';
import { STORAGE, APP_URLS } from '../config';

const defaultState = {
    filter: {
        search: '',
        dateFrom: null,
        dateTo: null,
        isBlocked: null,
        orderBy: 'createdAt',
        orderDirection: 'desc',
        pageNumber: 1,
        pageSize: 6,
        hasPrevious: false,
        hasNext: false,
    },
    data: [],
    consumer: {
        isBlocked: false,
        role: 3,
    },
    isPending: false,
    requestId: null,
    error: null,
    errors: [],
}

const validate = (state) => {
    return [];
}

const fetchConsumers = createAsyncThunk(
    'consumers/fetch',
    async (isConsumer, thunkApi) => {
        try {
            const { consumers } = thunkApi.getState();

            if (consumers.isPending && consumers.requestId !== thunkApi.requestId) {
                return;
            }

            let params = {
                pageSize: consumers.filter.pageSize ? consumers.filter.pageSize : 6,
                pageNumber: consumers.filter.pageNumber ? consumers.filter.pageNumber : 1,
                isConsumer,
            };

            params = (consumers.filter.search) ? {...params, search: consumers.filter.search} : {...params};
            params = (consumers.filter.dateFrom) ? {...params, dateFrom: consumers.filter.dateFrom} : {...params};
            params = (consumers.filter.dateTo) ? {...params, dateTo: consumers.filter.dateTo} : {...params};
            params = (consumers.filter.isBlocked) ? {...params, isBlocked: consumers.filter.isBlocked.value} : {...params};
            params = (consumers.filter.orderBy) ? {...params, orderBy: consumers.filter.orderBy} : {...params};
            params = (consumers.filter.orderDirection) ? {...params, orderDirection: consumers.filter.orderDirection} : {...params};

            const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY));
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token?.token,
            }

            const response = await axios.get(
                APP_URLS.CONSUMERS_LIST,
                { params, headers});

            return response;
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

const changeStatusConsumer = createAsyncThunk(
    'consumers/changeStatus',
    async (id, thunkApi) => {
        try {
            const { consumers } = thunkApi.getState();

            if (consumers.isPending && consumers.requestId !== thunkApi.requestId)
                return;

            const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY))?.token;
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }

            const response = await axios.put(
                APP_URLS.CONSUMER_CHANGE_STATUS.replace('{id}', id),
                null,
                { headers }
            );

            if (!response.data.succeeded)
                return thunkApi.rejectWithValue('Błąd api');

            return response;
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

const deleteConsumer = createAsyncThunk(
    'consumers/delete',
    async (id, thunkApi) => {
        try {
            const { consumers } = thunkApi.getState();

            if (consumers.isPending && consumers.requestId !== thunkApi.requestId)
                return;

            const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY))?.token;
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }

            console.log('headers :>> ', headers);

            const response = await axios.delete(
                APP_URLS.CONSUMER_REMOVE.replace('{id}', id),
                { headers }
            );

            if (!response.data.succeeded)
                return thunkApi.rejectWithValue('Błąd api');

            return response;
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

const getConsumer = createAsyncThunk(
    "consumers/get",
    async (id, thunkApi) => {
        try {
            const { consumers } = thunkApi.getState();

            if (consumers.isPending && consumers.requestId !== thunkApi.requestId) {
                return;
            }

            const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY))?.token;
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }

            const response = await axios.get(
                APP_URLS.CONSUMER_GET.replace('{id}', id),
                { headers }
            );

            return response;
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);


const removeAdminRoles = createAsyncThunk(
    'consumers/removeAdminRoles',
    async (id, thunkApi) => {
        try {
            const { consumers } = thunkApi.getState();

            if (consumers.isPending && consumers.requestId !== thunkApi.requestId)
                return;

            const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY))?.token;
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }

            const response = await axios.put(
                APP_URLS.CONSUMER_REMOVE_ADMIN.replace('{id}', id),
                null,
                { headers }
            );

            if (!response.data.succeeded)
                return thunkApi.rejectWithValue('Błąd api');

            return response;
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);


const updateConsumer = createAsyncThunk(
    'consumers/update',
    async (data, thunkApi) => {
        try {
            const { consumers } = thunkApi.getState();

            if ((consumers.isPending && consumers.requestId !== thunkApi.requestId) || validate(consumers).length)
                return thunkApi.rejectWithValue(consumers.errors);

            const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY))?.token;
            const form = getFormData(data)

            const response = await axios.put(
                APP_URLS.CONSUMER_UPDATE.replace('{id}', consumers.consumer.id),
                form,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    }
            });

            return response;
        } catch (error) {
            return thunkApi.rejectWithValue(error.response.data);
        }
    }
);

const createAccount = createAsyncThunk(
    'consumers/create',
    async (data, thunkApi) => {
        try {
            const { consumers: { consumer, isPending, requestId } } = thunkApi.getState();

            if (isPending && requestId !== thunkApi.requestId)
                return;

            const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY))?.token;
            const form = getFormData({
                Email: consumer.email,
                FirstName: consumer.firstname,
                LastName: consumer.lastname,
                Role: consumer.role,
            });

            const response = await axios.post(
                APP_URLS.USER_CREATE,
                form,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    }

                }
            );

            return response;
        } catch (error) {
            return thunkApi.rejectWithValue(error.response.data);
        }
    }
);

const slice = createSlice({
    name: 'consumers',
    initialState: {...defaultState},
    reducers: {
        setFilter: (state, { payload }) => {
            const { field, value } = payload;

            state.filter = {
                ...state.filter,
                [field]: value
            };
        },
        setAllFilters: (state, { payload }) => {
            state.filter = {
                ...payload
            };
        },
        resetFilter: (state) => {
        console.log("🚀 ~ file: consumersSlice.jsx:280 ~ state:", state)
        state.filter = {
                ...defaultState.filter,
            };
        },
        previousPage: (state) => {
            state.filter.pageNumber--;
        },
        nextPage: (state) => {
            state.filter.pageNumber++;
        },
        setConsumer: (state, { payload }) => {
            const { field, value } = payload;

            state.consumer = {
                ...state.consumer,
                [field]: value
            };

            if (state.errors.length)
                state.errors = [...validate(state)];
        },
        clearConsumer: (state) => {
            state.consumer = {...defaultState.consumer}
            console.log("🚀 ~ file: consumersSlice.jsx:271 ~ consumer", state.consumer)
        },
    },
    extraReducers: builder  => {
        builder
            .addCase(fetchConsumers.pending, (state, action) => {
                if (state.isPending)
                    return;

                state.data = [];
                state.error = null;
                state.isPending = true;
                state.requestId = action.meta.requestId;
            })
            .addCase(fetchConsumers.fulfilled, (state, { payload }) => {
                if (!payload)
                    return;

                state.filter = {
                    ...state.filter,
                    hasPrevious: payload.data.hasPrevious,
                    hasNext: payload.data.hasNext,
                }
                state.data = payload.data.items;
                state.csvLink = payload.request?.responseURL?.replace("list", "csv");
                state.isPending = false;
                state.requestId = null;
            })
            .addCase(fetchConsumers.rejected, (state, { error }) => {
                state.error = error.message;
                state.isPending = false;
                state.requestId = null;
            })
            .addCase(deleteConsumer.pending, (state, action) => {
                if (state.isPending)
                    return;

                state.error = null;
                state.isPending = true;
                state.requestId = action.meta.requestId;
            })
            .addCase(deleteConsumer.fulfilled, (state, { payload }) => {
                if (!payload)
                    return;

                state.isPending = false;
                state.requestId = null;
            })
            .addCase(deleteConsumer.rejected, (state, { error }) => {
                state.error = error.message;
                state.isPending = false;
                state.requestId = null;
            })
            .addCase(changeStatusConsumer.pending, (state, action) => {
                if (state.isPending)
                    return;

                state.error = null;
                state.isPending = true;
                state.requestId = action.meta.requestId;
            })
            .addCase(changeStatusConsumer.fulfilled, (state, { payload }) => {
                if (!payload)
                    return;

                state.isPending = false;
                state.requestId = null;
            })
            .addCase(changeStatusConsumer.rejected, (state, { error }) => {
                state.error = error.message;
                state.isPending = false;
                state.requestId = null;
            })
            .addCase(getConsumer.pending, (state, action) => {
                if (state.isPending)
                    return;

                state.error = null;
                state.isPending = true;
                state.requestId = action.meta.requestId;
            })
            .addCase(getConsumer.fulfilled, (state, { payload }) => {
                if (!payload)
                    return;

                state.consumer = {...payload.data}
                state.isPending = false;
                state.requestId = null;
            })
            .addCase(getConsumer.rejected, (state, { error }) => {
                state.error = error.message;
                state.isPending = false;
                state.requestId = null;
            })
            .addCase(updateConsumer.pending, (state, action) => {
                console.log('action :>> ', action);
                if (state.isPending)
                    return;

                state.errors = [...validate(state)];

                state.isPending = true;
                state.requestId = action.meta.requestId;
            })
            .addCase(updateConsumer.fulfilled, (state, { payload }) => {
                if (!payload)
                    return;

                state.isPending = false;
                state.requestId = null;
            })
            .addCase(updateConsumer.rejected, (state, error) => {
                console.log('error :>> ', error);
                state.error = error.payload;
                state.isPending = false;
                state.requestId = null;
            })
            .addCase(createAccount.pending, (state, action) => {
                if (state.isPending)
                    return;

                state.errors = [...validate(state)];

                state.isPending = true;
                state.requestId = action.meta.requestId;
            })
            .addCase(createAccount.fulfilled, (state, { payload }) => {
                if (!payload)
                    return;

                state.isPending = false;
                state.requestId = null;
            })
            .addCase(createAccount.rejected, (state, error) => {
                state.error = error.payload;
                state.isPending = false;
                state.requestId = null;
            });
    }
});

export const {
    setFilter,
    setAllFilters,
    resetFilter,
    previousPage,
    nextPage,
    setConsumer,
    clearConsumer,
} = slice.actions;

export {
    fetchConsumers,
    changeStatusConsumer,
    removeAdminRoles,
    deleteConsumer,
    getConsumer,
    updateConsumer,
    createAccount,
};

export default slice.reducer;