import React from 'react';
import './modal.styles.scss';
import { connect, useDispatch } from 'react-redux';
import { hideModal } from '../../app/slices/modalSlice';
import { Button } from '@mui/material';
import { isFunction } from 'lodash';

function Modal({ modal }) {
    const dispatch = useDispatch();

    if (!modal.show) {
        return null;
    }

    const onCloseButtonClick = () => {
        if (isFunction(modal.fallback))
            modal.fallback();

        dispatch(hideModal());
    }

    const onYesButtonClick = () => {
        if (isFunction(modal.fallbackYes))
            modal.fallbackYes();

        dispatch(hideModal());
    }

    const onNoButtonClick = () => {
        if (isFunction(modal.fallbackNo))
            modal.fallbackNo();

        dispatch(hideModal());
    }

    const onCancelButtonClick = () => {
        dispatch(hideModal());
    }

    return (
        <div className="fk-modal-overlay">
            <div className="fk-modal">
                <span className="fk-modal-close" onClick={onCancelButtonClick}>
                    &#10005;
                </span>
                {modal.title &&
                    <div className="fk-modal-title">
                        {modal.title}
                    </div>
                }
                <div className={`fk-modal-body${!modal.title ? ' bold' : ''}`}>
                    {modal.message}
                </div>
                <div className="fk-modal-buttons">
                    {
                        modal.yesNoButtons
                        ? <>
                            <Button
                                fullWidth
                                className={`fk-modal-button-normal`}
                                onClick={onYesButtonClick}
                            >
                                Tak
                            </Button>
                            <Button
                                fullWidth
                                className={`fk-modal-button-danger`}
                                onClick={onNoButtonClick}
                            >
                                Nie
                            </Button>
                        </>
                        : <>
                            <Button
                                fullWidth
                                className={`fk-modal-button-${modal.mode}`}
                                onClick={onCloseButtonClick}
                            >
                                {modal.button}
                            </Button>
                            {modal.cancelButton &&
                                <Button
                                    fullWidth
                                    className={`fk-modal-button-danger`}
                                    onClick={onCancelButtonClick}
                                >
                                    Anuluj
                                </Button>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    modal: state.modal
});

export default connect(mapStateToProps, {})(Modal);