export const logTypes = [
    {
        value: 0,
        text: 'UndeliveredEmail',
    },
    {
        value: 1,
        text: 'FirstReminderSent',
    },
    {
        value: 2,
        text: 'LastReminderSent',
    },
    {
        value: 3,
        text: 'AutomaticallyClosed',
    },
    {
        value: 4,
        text: 'ConsumerRegister',
    },
    {
        value: 5,
        text: 'ConsumerConfirmed',
    },
    {
        value: 6,
        text: 'ComplaintDraftCreated',
    },
    {
        value: 7,
        text: 'ComplaintSentToCompany',
    },
    {
        value: 8,
        text: 'CompanyAnswer',
    },
    {
        value: 9,
        text: 'ConsumerAnswer',
    },
    {
        value: 10,
        text: 'AdminsCommentOnComplaint',
    },
    {
        value: 11,
        text: 'ComplaintsMerge',
    },
    {
        value: 12,
        text: 'ComplaintChanged',
    },
    {
        value: 13,
        text: 'ComplaintGoesPublic',
    },
    {
        value: 14,
        text: 'ComplaintGoesPrivate',
    },
    {
        value: 15,
        text: 'CompanyPointsVisibilityChanged',
    },
    {
        value: 16,
        text: 'ComplaintClosedUnresolved',
    },
    {
        value: 17,
        text: 'ComplaintClosedResolved',
    },
    {
        value: 18,
        text: 'ComplaintRemoved',
    },
    {
        value: 19,
        text: 'ConsumerAccountRemoved',
    },
    {
        value: 20,
        text: 'ConsumerAccountBlocked',
    },
    {
        value: 21,
        text: 'CompanyLogoUploaded',
    },
    {
        value: 22,
        text: 'ConsumerRankChanged',
    },
    {
        value: 23,
        text: 'ConsumerAccountUnblocked',
    },
];