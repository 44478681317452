import { APP_URLS } from './urls';
import { STORAGE } from './storage';

import { menuItems } from './menu';
import { companySectors } from './companySectors';

import { complainStatuses } from './complainStatuses';
import { visibilityStates } from './visibilityStates';
import { yesNoStatuses } from './yesNoStatuses';

import { consumersTableHead } from './tables/consumersTableHead';
import { complainsTableHead } from './tables/complainsTableHead';
import { companiesTableHead } from './tables/companiesTableHead';
import { companyComplainsTableHead } from './tables/companyComplainsTableHead';
import { consumerComplainsTableHead } from './tables/consumerComplainsTableHead';
import { complainDuplicatesTableHead } from './tables/complainDuplicatesTableHead';
import { complainLogsTableHead } from './tables/complainLogsTableHead';
import { usersTableHead } from './tables/usersTableHead';
import { userRoles } from './userRoles';
import { logTypes } from './logTypes';

export {
    APP_URLS,
    STORAGE,

    menuItems,
    companySectors,

    complainStatuses,
    visibilityStates,
    yesNoStatuses,

    consumersTableHead,
    complainsTableHead,
    companiesTableHead,
    companyComplainsTableHead,
    consumerComplainsTableHead,
    complainDuplicatesTableHead,
    complainLogsTableHead,
    usersTableHead,
    userRoles,
    logTypes,
}