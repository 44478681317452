import CompanyInfoForm from './companyInfoForm';
import CompanyAdditionalInfoForm from './companyAdditionalInfoForm';
import CompanyInfoSection from './companyInfoSection';
import CompanySubmitForm from './companySubmitForm';
import CompanyScoreSection from './companyScoreSection';
import CompanyComplainsList from './companyComplainsList';
import CompanySectorsSection from './companySectorsSection'

export {
    CompanyInfoForm,
    CompanyAdditionalInfoForm,
    CompanyInfoSection,
    CompanySubmitForm,
    CompanyScoreSection,
    CompanyComplainsList,
    CompanySectorsSection,
}