const getFormData = (data) => {
    var formData = new FormData();

    for (var key in data) {
        formData.append(key, data[key] ?? '');
    }

    return formData;
}

export {
    getFormData,
};