import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {APP_URLS, companySectors} from "../../app/config";
import {FilterMenu} from "../filterMenu";
import {format} from "date-fns";
import {Autocomplete, MenuItem, Select, TextField} from "@mui/material";
import SectorItem from "./components/SectorItem";
import SaveAltRoundedIcon from "@mui/icons-material/SaveAltRounded";

const CompaniesCard = () => {
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [complainTypes, setComplainTypes] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [companiesResult, setCompaniesResult] = useState([])
    const [filter, setFilter] = useState({from: null, to: null})
    const {t} = useTranslation('ComplainsScreen', {keyPrefix: 'TableFilter'});


    useEffect(() => {
        if (!searchTerm) {
            setCompaniesResult([]);
        }

        if (searchTerm.length > 2) {
            axios.get(`${APP_URLS.COMPANY_SEARCH}/${searchTerm}`).then(response => {
                setCompaniesResult(response.data);
            })
        }
    }, [searchTerm]);


    const groupBySectorName = (list) => {
        return list.reduce((acc, item) => {
            // Jeśli grupa dla sectorName już istnieje, dodaj do niej, w przeciwnym razie utwórz nową grupę
            acc[item.sectorName] = acc[item.sectorName] || [];
            acc[item.sectorName].push(item);
            return acc;
        }, {});
    };

    const handleChange = (event) => {
        setSelectedCompany(event.target.value);
    };

    useEffect(() => {
        if (selectedCompany === null) {
            return;
        }

        const company = companiesResult[selectedCompany];

        if (!company) {
            return;
        }

        axios.get(`${APP_URLS.COMPANY_STATS}/${company.id}`, {params: {...filter}}).then(response => {
            setComplainTypes(groupBySectorName(response.data));
            // console.log(JSON.stringify(response.data, null, 2))
        })
    }, [selectedCompany, filter.from, filter.to]);

    const handleFilterChange = (field, value) => {
        setFilter(prev => {
            return {
                ...prev,
                [field]: value,
            }
        })
    }

    const exportToCsv = () => {
        if (selectedCompany === null) {
            return;
        }

        const company = companiesResult[selectedCompany];

        if (!company) {
            return;
        }
        const dates = {
            from: filter.from ? filter.from?.toISOString() : '',
            to: filter.to ? filter.to?.toISOString() : '',
        }

        let params = new URLSearchParams(dates);
        let keysForDel = [];
        
        params.forEach((value, key) => {
            if (value === '') {
                keysForDel.push(key);
            }
        });

        keysForDel.forEach(key => {
            params.delete(key);
        });

        
        const url = `${APP_URLS.COMPANY_STATS}/${company.id}/csv?${params.toString()}`
        const link = document.createElement('a');
        link.href = url;
        link.target = "_blank";

        document.body.appendChild(link);
        link.click();

        link.remove();
        window.URL.revokeObjectURL(url);
    }

    return (
        <div className="rowContainer">
            <div className="bigCard">
                <div style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <span className="header" style={{color: '#434343'}}>The main type of problem with division into company</span> 
                    <SaveAltRoundedIcon style={{color: '#41A046'}} onClick={exportToCsv}/>
                </div>

                <div style={{display: 'flex'}}>
                    <span className="label">Dates:</span>
                    <FilterMenu
                        datepicker
                        options={[]}
                        selected={filter.from}
                        buttonText={`${t('createdAt.from')} ${filter.from ? format(new Date(filter.from), "yyyy-MM-dd") : ''}`}
                        onChange={(option) => handleFilterChange('from', option)}
                    />
                    <FilterMenu
                        datepicker
                        options={[]}
                        selected={filter.to}
                        buttonText={`${t('createdAt.to')} ${filter.to ? format(new Date(filter.to), "yyyy-MM-dd") : ''}`}
                        onChange={(option) => handleFilterChange('to', option)}
                    />
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <span className="label" style={{textWrap: 'nowrap', paddingRight: 12}}>Company:</span>
                    <Autocomplete
                        fullWidth
                        size="small"
                        disablePortal
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onInput={event => setSearchTerm(event.target.value)}
                        onChange={handleChange}
                        id="combo-box-demo"
                        options={companiesResult}
                        renderInput={(params) => <TextField {...params} label="Type name of the company"/>}
                    />
                </div>
                <div className="itemsContainer">
                    <SectorItem label={'Type of problem'} value={'Cases'} transparent={true}/>
                    {Object.keys(complainTypes).map(sectorName => {
                        return (
                            <>
                                <span>{sectorName}</span>
                                {complainTypes[sectorName].map(({name, count}) => {
                                    return <SectorItem label={name} value={count}/>
                                })}
                            </>
                        )
                    })}

                </div>
            </div>
        </div>
    )
}

export default CompaniesCard;