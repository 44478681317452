import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faUser,
    faSearch,
    faCheck,
    faArrowLeft,
    faTrashCan,
    faList,
    faArrowRightFromBracket,
} from '@fortawesome/free-solid-svg-icons'

library.add(
    faUser,
    faSearch,
    faCheck,
    faArrowLeft,
    faTrashCan,
    faList,
    faArrowRightFromBracket
);

export default function FaIconsProvider(props) {
    return (
        <>
            {props?.children}
        </>
    );
}