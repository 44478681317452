export const complainsTableHead = [
    {
      id: 'createdAt',
      label: 'Data stworzenia skargi',
    },
    {
      id: 'companyName',
      label: 'Nazwa firmy',
    },
    {
      id: 'title',
      label: 'Tytuł skargi',
    },
    {
      id: 'fullName',
      label: 'Imię i Nazwisko',
    },
    {
      id: 'isPublic',
      label: 'Widoczność',
    },
    {
      id: 'number',
      label: 'Numer skargi',
    },
    {
      id: 'haveComments',
      label: 'Komentarz konsumenta',
    },
    {
        id: 'haveCompanyComment',
        label: 'Firma odpowiedziała?',
    },
    {
      id: 'status',
      label: 'Status',
    },
  ];