export const logsTableHead = [
    {
      id: 'createdAt',
      label: 'Data stworzenia skargi',
    },
    {
      id: 'type',
      label: 'Rodzaj zdarzenia',
    },
    {
      id: 'description',
      label: 'Opis',
    },
  ];