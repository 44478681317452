import { connect } from "react-redux";
import React from "react";
import { complainDuplicatesTableHead } from "../../../app/config";
import { ComplainDuplicatesTableFilter, ComplainsTableRow, TableHeader, TablePagination } from "../../table";
import { Table, TableBody, TableContainer } from "@mui/material";
import _ from "lodash";
import { useTranslation } from "react-i18next";

function ComplainDuplicateList({ parentId, complains, filter, selectedItems, onRequestSort, onRowCheck, onSelectAll, onPreviousPage, onNextPage }) {
    const [open, setOpen] = React.useState(null);
    const { t } = useTranslation('ComplainDuplicatesList');

    const handleRowClick = (id) => setOpen(open ? null : id);

    return (
        <div className="fk-company-complains-list">
            <ComplainDuplicatesTableFilter hideTitle hideSearch parentId={parentId} listTitle={t('title')} />
            <TableContainer>
                <Table size="medium" className="fk-table">
                    <TableHeader
                        crud
                        withCheckbox
                        items={complainDuplicatesTableHead}
                        orderBy={filter.orderBy}
                        order={filter.orderDirection}
                        numSelected={selectedItems.length}
                        rowCount={complains.length}
                        onRequestSort={onRequestSort}
                        onSelectAllClick={onSelectAll}
                        screen="ComplainsScreen"
                    />
                    <TableBody>
                    {complains.map((complain) => {
                        const isChecked = _.some(selectedItems, (item) => item.id === complain.id);

                        return (
                            <ComplainsTableRow
                                key={complain.id}
                                checked={isChecked}
                                open={open === complain.id}
                                complain={complain}
                                columns={complainDuplicatesTableHead}
                                onCheck={() => onRowCheck(complain, isChecked)}
                                onRowClick={handleRowClick}
                            />
                        )
                    })}
                    </TableBody>
                </Table>
                {complains.length === 0 &&
                    <p className="fk-no-items">
                        {t('noRecords')}
                    </p>
                }
            </TableContainer>
            <TablePagination
                filter={filter}
                handlePreviousPage={onPreviousPage}
                handleNextPage={onNextPage}
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    complains: state.complains.data,
    filter: state.complains.filter,
});

const actions = {
};

export default connect(mapStateToProps, actions)(ComplainDuplicateList);