import React, {useCallback, useState} from "react";
import CasesTab from "../components/reports/casesTab";
import "../components/reports/reports.scss"
import {TailSpin} from "react-loader-spinner";
import SectorsTab from "../components/reports/sectorsTab";
import SummaryTab from "../components/reports/components/summaryTab";
import CompaniesTab from "../components/reports/companiesTab";


const ReportsScreen = (props) => {
    const [activeTab, setActiveTab] = useState(1);

    const renderTab = useCallback(() => {
        switch (activeTab) {
            case 1:
                return <SummaryTab />
            case 2:
                return <CasesTab />
            case 3:
                return <CompaniesTab />
            case 4:
                return <SectorsTab />
        }
    }, [activeTab]);


    return (
        <div className="fk-screen">
            <div className="tabContainer">
                <div
                    className={`tabButton ${activeTab === 1 && '--active'}`}
                    onClick={() => setActiveTab(1)}>
                    Summary
                </div>
                <div
                    className={`tabButton ${activeTab === 2 && '--active'}`}
                    onClick={() => setActiveTab(2)}>
                    Cases
                </div>
                <div
                    className={`tabButton ${activeTab === 3 && '--active'}`}
                    onClick={() => setActiveTab(3)}>
                Companies
                </div>
                <div
                    className={`tabButton ${activeTab === 4 && '--active'}`}
                    onClick={() => setActiveTab(4)}>
                    Sectors & NOPs
                </div>
            </div>
            {renderTab()}
        </div>
    )
}

export default ReportsScreen;