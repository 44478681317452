import { IconButton, InputAdornment, TextField } from '@mui/material';
import {
    Search
} from '@mui/icons-material';
import _ from 'lodash';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { menuItems } from '../../app/config';
import './screenHeader.style.scss';
import { useTranslation } from 'react-i18next';
import { BackArrow } from '../../assets/icons';
import classNames from 'classnames';

function ScreenHeader({ withSearch, withBackButton, withSeparator, title, subtitle, titleReplacement, subtitleReplacement, search, additionalButtons, onChange}) {
    let location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation('Pages');
    const currentPage = location.pathname;

    const getCurrentPageName = () => {
        let pageName = '';

        _.each(menuItems, (item) => {
            if (pageName)
                return;

            let current = _.find(item.pagesIncluded, (o) =>
                    currentPage.length > 1 && t(o.url).length > 1
                        ? currentPage.startsWith(t(o.url))
                        : currentPage === t(o.url)
                );

            if (current)
                pageName = t(current.name);
        });
        console.log("🚀 ~ file: screenHeader.jsx:39 ~ getCurrentPageName ~ pageName", pageName)

        return pageName;
    }

    const getTitle = () => {
        console.log("🚀 ~ file: screenHeader.jsx:52 ~ getTitle ~ title", title)
        if (!title)
            return getCurrentPageName();

        title = t(title);

        if (titleReplacement?.length === 2) {
            title = title.replace(titleReplacement[0], titleReplacement[1])
        }

        return title;
    }

    const getSubtitle = () => {
        if (!subtitle)
            return getCurrentPageName();

        subtitle = t(subtitle);

        if (subtitleReplacement?.length === 2) {
            subtitle = subtitle.replace(subtitleReplacement[0], subtitleReplacement[1])
        }

        return subtitle;
    }

    return (
        <div className={
            classNames(
                'fk-screen-header',
                {
                    '--separator': withSeparator,
                    '--with-down-section': !!additionalButtons
                }
            )
        }>
            <div className="top-section">
                <div className="title-container">
                    {withBackButton &&
                        <IconButton className="fk-back-button" component="button" onClick={() => navigate(-1)}>
                            <img src={BackArrow} alt="button" />
                        </IconButton>
                    }
                    <h1 className="title">
                        {getTitle()}
                        {subtitle &&
                            <>
                                <br />
                                { getSubtitle() }
                            </>
                        }
                    </h1>
                </div>
                {withSearch &&
                    <TextField
                        className="fk-input"
                        variant="outlined"
                        placeholder={t('searchFieldLabel')}
                        InputProps={searchIcon}
                        value={search}
                        onChange={onChange}
                    />
                }
            </div>
            {additionalButtons}
        </div>
    );
}

const searchIcon = {
    endAdornment:
        <InputAdornment position="end">
            <Search />
        </InputAdornment>
};

export default ScreenHeader;