import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { APP_URLS, STORAGE } from '../config';
import { Buffer } from 'buffer';

const defaultState = {
    data: {
        access_token: '',
        token_type: '',
        expires_in: '',
        id_token: '',
        refresh_token: ''
    },
    authenticated: false,
    userData: null,
    isPending: false,
    requestId: null,
    error: null,
}

const login = createAsyncThunk(
    "auth/login",
    async (data = {}, thunkApi) => {
        try {
            const { auth } = thunkApi.getState();
            const details = {
                grant_type: 'password',
                scope: 'openid offline_access',
                username: data.username,
                password: data.password,
                check_email_verification: false,
            };

            const body = Object.keys(details).map(key =>
                encodeURIComponent(key) + '=' + encodeURIComponent(details[key])
            ).join('&');

            if (auth.isPending && auth.requestId !== thunkApi.requestId)
                return;
            const response = await fetch(APP_URLS.ADMIN_LOGIN, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: body
            });

            return response.json();
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

const getUserData = createAsyncThunk(
    'auth/getData',
    async (token, thunkApi) => {
        try {
            const { auth } = thunkApi.getState();

            if (auth.isPending && auth.requestId !== thunkApi.requestId)
                return;

            const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY));
            const response = await fetch(APP_URLS.USER_DATA, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Authorization': `Bearer ${token?.token}`,
                },
            });

            return response.json();
        } catch (error) {
            console.warn(error);
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

const setToken = async (state, json) => {
    try {
        let idToken = JSON.parse(Buffer.from(json.id_token.split('.')[1], 'base64').toString())
        localStorage.setItem(STORAGE.ID_TOKEN_KEY, JSON.stringify(idToken));
        localStorage.setItem(STORAGE.ACCESS_TOKEN_KEY, JSON.stringify({ token: json.access_token, userId: idToken.sub}));

        if (json && json.hasOwnProperty('refresh_token')) {
            localStorage.setItem(STORAGE.REFRESH_TOKEN_KEY, json.refresh_token);
        }
    }
    catch (e) {
        console.warn(e);
        state.error = e;
    }
};

const removeToken = () => {
    localStorage.removeItem(STORAGE.ID_TOKEN_KEY);
    localStorage.removeItem(STORAGE.ACCESS_TOKEN_KEY);
    localStorage.removeItem(STORAGE.REFRESH_TOKEN_KEY);
}

const slice = createSlice({
    name: 'auth',
    initialState: {...defaultState},
    reducers: {
        logout: (state, { payload }) => {
            state.authenticated = false;
            removeToken();
        },
        setError: (state, {payload}) => {
            state.error = payload;
        }
    },
    extraReducers: builder  => {
        builder
            .addCase(login.pending, (state, action) => {
                if (state.isPending)
                    return;

                state.login_error = null;
                state.isPending = true;
                state.requestId = action.meta.requestId;
            })
            .addCase(login.fulfilled, (state, { payload }) => {
                state.isPending = false;
                state.requestId = null;

                if (!payload) {
                    state.login_error = 'Wystąpił błąd API.';
                    return;
                }

                if (payload.hasOwnProperty('error') && payload.error === 'invalid_grant') {
                    state.login_error = 'Podany email lub hasło jest nieprawidłowe.';
                    return;
                }

                if (!payload.hasOwnProperty('access_token')) {
                    state.login_error = 'Wystąpił błąd API';
                    return;
                }

                state.data = {...payload};

                setToken(state, payload);
            })
            .addCase(login.rejected, (state, { error }) => {
                state.login_error = error.message;
                state.isPending = false;
                state.requestId = null;
            })
            .addCase(getUserData.pending, (state, action) => {
                if (state.isPending)
                    return;

                state.isPending = true;
                state.requestId = action.meta.requestId;
            })
            .addCase(getUserData.fulfilled, (state, { payload }) => {
                if (!payload)
                    return;

                state.userData = {...payload};
                state.authenticated = true;
                state.isPending = false;
                state.requestId = null;
            })
            .addCase(getUserData.rejected, (state, { error }) => {
                state.authenticated = false;
                state.isLoginIn = false;
                state.isPending = false;
                state.requestId = null;
            });
    }
});

export const {
    logout,
    setError,
} = slice.actions;

export {
    login,
    getUserData,
};

export default slice.reducer;