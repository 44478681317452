import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import classNames from 'classnames';
import { Grid, MenuItem, TextField } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import {
  setCompany,
} from '../../../app/slices/companiesSlice';
import {
    showModal,
} from '../../../app/slices/modalSlice';
import _ from "lodash";
import { infoFieldsArray } from "./config";

function CompanyInfoForm({ isCreate, canEdit, onUpdate, model, errors, setCompany }) {
    const { t } = useTranslation('CompanyCreateScreen', { keyPrefix: 'InfoForm' });

    const [currentEdit, setCurrentEdit] = React.useState('');

    const checkIfDisabled = (field) => !isCreate && (!canEdit || field !== currentEdit);
    const getErrorMessage = (field) => _.find(errors, (error) => error.field === field);
    const handleFieldChange = (field, value) => setCompany({ field, value });

    const handleUpdate = async (name, value) => {
        await onUpdate(name, value);

        setCurrentEdit(null);
    }

    useEffect(() => {
        document.documentElement.style.setProperty('--blocked-sector-position-text', `'${t('blockedSectorPositionText')}'`);
    }, [t])

    return (
        <div
            className={
                classNames(
                    'fk-form-section',
                    {
                        'edit-mode': canEdit
                    }
                )
            }
        >
            <h3 className="title">
                {t('title')}
            </h3>
            <Grid container spacing={2}>
                {infoFieldsArray.map(({name, modelName, required, items, multiple}, index) => (
                    <React.Fragment key={index}>
                        <Grid item md={3} className="fk-label">
                            <label htmlFor={name}>{`${t(name)}${(required ? '*' : '')}`}</label>
                        </Grid>
                        <Grid item md={8} className={
                            classNames(
                                'fk-input-container',
                                {
                                    'edit-mode': canEdit,
                                    'has-error': !!getErrorMessage(name),
                                }
                            )
                        }>
                            <TextField
                                fullWidth
                                select={!!items}
                                disabled={checkIfDisabled(name)}
                                className="fk-input"
                                placeholder={t(`${name}Placeholder`)}
                                helperText={getErrorMessage(name)?.error}
                                value={model ? model[name] ?? '' : !!items ? '' : ''}
                                onChange={(event) => handleFieldChange(name, event.target.value)}
                                SelectProps={{
                                    multiple: multiple,
                                }}
                            >
                                {items && items.map(({value, text}, index) => {
                                    const sectorData = (name === 'allSectors') ? _.find(model.sectorsData, (data) => data.sectorId === value) : null;

                                    return (
                                        <MenuItem key={index} value={value} disabled={!!(sectorData?.reportsDraftsIncludedAmount)} className={sectorData?.reportsDraftsIncludedAmount ? '--have-complains' : ''}>
                                            {text}
                                        </MenuItem>
                                    )
                                })}
                            </TextField>
                        </Grid>
                        {canEdit ?
                            <Grid item md={1}>
                                {name !== currentEdit
                                    ? <button
                                        className="fk-edit-button"
                                        onClick={() => setCurrentEdit(name)}
                                    >
                                        <EditIcon />
                                    </button>
                                    : <button
                                        className="fk-edit-button"
                                        onClick={() => handleUpdate(modelName, model[name])}
                                    >
                                        <SaveIcon />
                                    </button>
                                }
                            </Grid>
                            : <Grid item md={1} />
                        }
                    </React.Fragment>
                ))}
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    model: state.companies.company,
    errors: state.companies.errors,
});

const actions = {
    setCompany,
    showModal,
};

export default connect(mapStateToProps, actions)(CompanyInfoForm);