import {
    Checkbox,
    Collapse,
    TableCell,
    TableRow,
  } from "@mui/material";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkAllItems, checkItem, fetchComplains, nextPage, previousPage, setFilter, resetFilter, uncheckAllItems, uncheckItem } from "../../../app/slices/complainsSlice";
import CompanyComplainsList from "../../forms/company/companyComplainsList";


function CompaniesTableRow({ open, company, onRowClick, filter, selectedItems, complains, checked, onCheck}) {
    const { t: t_button } = useTranslation('Buttons');
    const { t: t_common } = useTranslation('Common');
    const { t: t_link} = useTranslation('Pages');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const refreshComplains = async () => {
        await dispatch(fetchComplains({ companyId: company.id }));
    }

    const handlePreviousPage = () => {
        dispatch(previousPage());
        refreshComplains();
    }

    const handleNextPage = () => {
        dispatch(nextPage());
        refreshComplains();
    }

    const handleRequestSort = (event, property) => {
        const direction =
            filter.orderBy === property &&
            filter.orderDirection === "asc"
                ? "desc"
                : "asc";
        dispatch(setFilter({ field: "orderBy", value: property }));
        dispatch(setFilter({ field: "orderDirection", value: direction }));

        refreshComplains();
    }

    const handleRowCheck = (item, isChecked) => {
        if (!isChecked) {
            dispatch(checkItem(item));
        } else {
            dispatch(uncheckItem(item));
        }
    }

    const handleSelectAll = () => {
        if (selectedItems.length === complains.length) {
            dispatch(uncheckAllItems());
        } else {
            dispatch(checkAllItems());
        }
    }

    const onCellClick = async () => {
        onRowClick(company.id);
        dispatch(resetFilter());
        await refreshComplains();
    }
    
    const renderUndeliveredComplains = () => {
        if(company.undeliveredComplains > 0) {
            return (
                `${t_common('yes')} (${company.undeliveredComplains})`
            )
        }
        else if(company.undeliveredComplains < 1 || company.undeliveredComplains) {
            return (`${t_common('no')} (0)`)
        }

        // {company.undeliveredComplains ? t_common('yes') : t_common('no')} ({company.undeliveredComplains})
    } 

    return (
        <>
            <TableRow
                className={`row ${open ? " active" : ""}`}
            >
                <TableCell padding="checkbox">
                    <Checkbox color="primary" checked={checked} onClick={onCheck} />
                </TableCell>
                <TableCell
                    padding="none"
                    className="fk-table-cell --first"
                    onClick={onCellClick}
                >
                    <span className="value">{company.id}</span>
                </TableCell>
                <TableCell
                    padding="none"
                    className="fk-table-cell"
                    onClick={onCellClick}
                >
                    <span className="value">
                        {company.name}
                    </span>
                </TableCell>
                <TableCell
                    padding="none"
                    className="fk-table-cell"
                    onClick={onCellClick}
                >
                    <span className="value">{company.sectors}</span>
                </TableCell>
                <TableCell
                    padding="none"
                    className="fk-table-cell"
                    onClick={onCellClick}
                >
                    <span className="value">{company.openReportsAmount}</span>
                </TableCell>
                <TableCell
                    padding="none"
                    className="fk-table-cell"
                    onClick={onCellClick}
                >
                    <span className="value">{company.closedReportsAmount}</span>
                </TableCell>
                <TableCell
                    padding="none"
                    className="fk-table-cell"
                    onClick={onCellClick}
                >
                    <span className="value">{company.allReportsAmount > 0 ? (company.answerRating / company.allReportsAmount).toFixed(2) * 100 : 0}%</span>
                </TableCell>
                <TableCell
                    padding="none"
                    className="fk-table-cell"
                    onClick={onCellClick}
                >
                    <span className="value">{company.points} ({t_common((company.scoreVisible) ? 'yes' : 'no')})</span>
                </TableCell>
                <TableCell
                    padding="none"
                    className="fk-table-cell"
                    onClick={onCellClick}
                >
                    <span className={`value${company.undeliveredComplains > 0 ? ' error' : ''}`}>
                        {renderUndeliveredComplains()}
                    </span>
                </TableCell>
                <TableCell padding="none" className="fk-table-cell crud --last">
                    <span className="value">
                        <button
                            className="show-details-button"
                            onClick={() => navigate(t_link('companyLink') + company.id)}
                        >
                            {t_button('show')}
                        </button>
                    </span>
                </TableCell>
            </TableRow>
            {open &&
                <TableRow className="row collapsed-data">
                    <TableCell padding="checkbox" />
                    <TableCell
                        className="fk-table-cell --first"
                        colSpan={8}
                    >
                        <Collapse in={open} timeout="auto" className="collapse">
                            <CompanyComplainsList
                                companyId={company.id}
                                selectedItems={selectedItems}
                                onRequestSort={handleRequestSort}
                                onRowCheck={handleRowCheck}
                                onSelectAll={handleSelectAll}
                                onPreviousPage={handlePreviousPage}
                                onNextPage={handleNextPage}
                            />
                        </Collapse>
                    </TableCell>
                </TableRow>
            }

      </>
    );
}

const mapStateToProps = state => ({
    filter: state.complains.filter,
    selectedItems: state.complains.selectedItems,
    complains: state.complains.data,
});

export default connect(mapStateToProps, {})(CompaniesTableRow);