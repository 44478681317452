import ComplainAdminsComment from './complainAdminsComment';
import ComplainAttachments from './complainAttachments';
import ComplainDataForm from './complainDataForm';
import ComplainDuplicateList from './complainDuplicateList';
import ComplainHistory from './complainHistory';
import ComplainMessages from './complainMessages';
import ComplainStatusForm from './complainStatusForm';
import ComplainLogsList from './complainLogsList';

export {
    ComplainAdminsComment,
    ComplainAttachments,
    ComplainDataForm,
    ComplainDuplicateList,
    ComplainHistory,
    ComplainMessages,
    ComplainStatusForm,
    ComplainLogsList,
}