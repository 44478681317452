//#region imports

import React, { useMemo } from "react";
import { Button, Menu, MenuItem, TextField } from "@mui/material";

import './filterMenu.styles.scss';
import plLocale from 'date-fns/locale/pl';
import enLocale from 'date-fns/locale/en-GB';
import esCLLocale from 'date-fns/locale/es';
import { CalendarPicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTranslation } from "react-i18next";

//#endregion


function FilterMenu(props) {
    const {
        id,
        label,
        buttonText,
        actionButton,
        datepicker,
        link,
        withValue,
        tFunc,
        tPrefix,
        selected,
        options,
        width,
        onClick,
        onChange,
    } = props;
    const { t: t_buttons, i18n } = useTranslation('Buttons');
    const { t: t_common } = useTranslation('Common');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const dateLocale = useMemo(() => {
        switch (i18n.language) {
            case 'pl': return plLocale;
            case 'en': return enLocale;
            case 'es-CL': return esCLLocale;
            default: return enLocale;
        }
    }, [i18n.language])

    const handleClick = (event) => {
        if (actionButton) {
            onClick();
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (option) => {
        onChange(option);

        if (!withValue)
            handleClose();
    }

    return (
        <>
            <div className="fk-filter-menu">
                <span className="fk-filter-menu-label">{ label }</span>
                {!link
                    ? <Button
                        aria-controls={open ? id : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        className={`fk-filter-menu-button${(!actionButton) ? ' --with-arrow' : ' --action'}${options ? ' --selectable' : ''}`}
                        style={width ? { maxWidth: width } : null}
                    >
                        { buttonText ? buttonText : selected ? (tFunc ? tFunc((tPrefix ?? '') + selected.text) : selected.text) : t_buttons('choose') }
                    </Button>
                    : <a href={link} download className="fk-filter-menu-button">{buttonText}</a>
                }
            </div>
            {!actionButton &&
                <Menu
                    id={id}
                    className="fk-filter-menu-list"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={async () => await handleChange(null)}>
                        {t_common('clear')}
                    </MenuItem>
                    {options.map((option, index) => (
                        <MenuItem
                            key={index}
                            onClick={() => handleChange(option)}
                        >
                            {tFunc ? tFunc((tPrefix ?? '') + option.text) : option.text}
                        </MenuItem>
                    ))}
                    {datepicker &&
                        <MenuItem>
                            <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                adapterLocale={dateLocale}
                            >
                                <CalendarPicker
                                    displayStaticWrapperAs="desktop"
                                    value={selected}
                                    onChange={handleChange}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </MenuItem>
                    }
                    {withValue &&
                        <MenuItem>
                            <TextField value={selected} onChange={(event) => handleChange(event.target.value)} type="number"/>
                        </MenuItem>
                    }
                </Menu>
            }
        </>
    )
}

export default FilterMenu;