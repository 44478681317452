import {
    Checkbox,
    Collapse,
    TableCell,
    TableRow,
  } from "@mui/material";
import { format } from "date-fns";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { APP_URLS } from "../../../app/config";
import { visibilityStates } from "../../../app/config/visibilityStates";
import ComplainLogsList from "../../forms/complain/complainLogsList";


function LogsTableRow({ log }) {
    const { t: t_type } = useTranslation('LogsScreen', { keyPrefix: 'LogTypes' });

    return (
        <>
            <TableRow
                className="row"
            >
                <TableCell
                    padding="none"
                    className="fk-table-cell --first"
                    style={{minWidth: '200px'}}
                >
                    <span className="value">
                        {format(
                            new Date(log.createdAt),
                            "yyyy-MM-dd 'godz:' HH:mm"
                        )}
                    </span>
                </TableCell>
                <TableCell
                    padding="none"
                    className="fk-table-cell"
                >
                    <span className="value">{t_type(log.type)}</span>
                </TableCell>
                <TableCell
                    padding="none"
                    className="fk-table-cell"
                >
                    <span className="value">
                        {log.description}
                        {log.link &&
                            <>
                                {log.type === 'UndeliveredEmail' &&
                                    <a href={`${APP_URLS.BASE}/${log.link}`} download target="_blank" rel="noreferrer">&nbsp;[PLIK]</a>
                                }
                            </>
                        }
                    </span>
                </TableCell>

            </TableRow>
      </>
    );
}

export default connect(null, {})(LogsTableRow);