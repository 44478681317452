export const complainDuplicatesTableHead = [
    {
      id: 'createdAt',
    },
    {
      id: 'companyName',
    },
    {
      id: 'title',
    },
    {
      id: 'fullName',
    },
    {
      id: 'isPublic',
    },
    {
      id: 'number',
    },
    {
      id: 'duplicatedNumber',
    },
    {
      id: 'parentNumber',
    },
    {
      id: 'status',
    },
  ];