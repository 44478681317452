import './reports.scss'
import {useMemo} from "react";

const Stat = ({value, description, onClick = null, valueColor, status, period, filterNumber, activeFilter}) => {

    const isActive = useMemo(() => {
        return activeFilter === filterNumber;
    }, [activeFilter]);
    
    const handleClick = () => {
        onClick && onClick(period, status, filterNumber);
    }
    
    return (
        <div className={onClick ? `statButton ${isActive ? '--active' : ''}` : 'stat'} onClick={handleClick}>
            <span className="valueText" style={{color: valueColor}}>{value}</span>
            {description && <span className="descriptionText">{description}</span>}
        </div>
    )
}

export default Stat;