import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import React from "react";
import { Button, Grid } from "@mui/material";
import { createAccount } from "../../../app/slices/consumersSlice";
import { useNavigate } from "react-router-dom";

function CompanySubmitForm() {
    const { t } = useTranslation('AccountDetailsScreen', { keyPrefix: 'Submit' });
    const { t: link} = useTranslation('Pages');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = () => {
        dispatch(createAccount()).then(({ payload }) => {
            if (payload && payload.data) {
                navigate(link('userLink').replace(':id', payload.data.id));
            } else {
                alert(t('apiError'));
            }
        });
    }

    return (
        <Grid container spacing={2} style={{marginTop: '15px', padding: '25px'}}>
            <Grid item md={3} />
            <Grid item md={8}>
                <Button
                    className="fk-button fk-button--primary"
                    fullWidth
                    onClick={handleSubmit}
                >
                    {t('button')}
                </Button>
            </Grid>
            <Grid item md={1} />
        </Grid>
    );
}

const mapStateToProps = (state) => ({
});

const actions = {
    createAccount,
};

export default connect(mapStateToProps, actions)(CompanySubmitForm);