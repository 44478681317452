export const consumersTableHead = [
    {
      id: 'id',
    },
    {
      id: 'fullName',
    },
    {
      id: 'createdAt',
    },
    {
      id: 'complainsAmount',
    },
    {
      id: 'points',
    },
    {
      id: 'status',
    },
  ];