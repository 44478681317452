import { format } from "date-fns";
import { connect, useDispatch } from "react-redux";
import { visibilityStates, complainStatuses } from "../../../app/config";
import { fetchComplains, setFilter, uncheckAllItems } from "../../../app/slices/complainsSlice";
import { FilterMenu } from "../../filterMenu";
import { ScreenHeader } from "../../screenHeader";
import { useTranslation } from "react-i18next";
import MergeComplainsModal from "../../modal/mergeComplainsModal";
import {useEffect, useState} from "react";
import { showModal } from "../../../app/slices/modalSlice";
import _ from "lodash";

function ComplainsTableFilter({ filter, hideSearch, hideTitle, selectedItems, csvLink }) {
  const dispatch = useDispatch();
  const { t } = useTranslation('ComplainsScreen', { keyPrefix: 'TableFilter' });
  const { t: t_common } = useTranslation('Common');
  let timer = null;

  const [showMergeModal, setShowMergeModal] = useState(false);

  const handleFilterChange = (field, value) => {
    clearTimeout(timer);
    
    dispatch(setFilter({ field, value, }));

    timer = setTimeout(() => {
      dispatch(fetchComplains());
    }, 500);
  };

  const handleMergeSelectedComplains = () => {
    if (!selectedItems || selectedItems.length <= 1) {
      dispatch(showModal({
        title: 'Nie zaznaczono skarg do scalenia',
        message: 'Aby scalić skargi, należy wybrać z listy min. dwie pozycje.',
        mode: 'danger',
      }));

      return;
    }

    if (!_.every(selectedItems, {mergeInfo: selectedItems[0].mergeInfo})) {
      dispatch(showModal({
        title: 'Wykryto niezgodność w danych skarg',
        message: 'Aby scalić skargi, powinny dotyczyć tego samego zgłaszającego, firmy i jej rodzaju działalności oraz rodzaju zgłoszenia.',
        mode: 'danger',
      }));

      return;
    }

    setShowMergeModal(true);
  }

  const onMergeModalComplete = async (isSuccess) => {
    setShowMergeModal(false);

    if (isSuccess) {
      dispatch(showModal({
        title: 'Wybrane skargi zostały połączone.',
        mode: 'normal',
      }));

      dispatch(uncheckAllItems());
      await dispatch(fetchComplains());
    } else {
      dispatch(showModal({
        title: 'Wystąpił błąd podczas scalania skarg.',
        mode: 'danger',
      }));
    }
  }

    useEffect(() => {
        console.log('[complainsTableFilter CSV] 🚀 ', JSON.stringify(csvLink, null, 2));
    }, [csvLink]);

  return (
    <>
        {!hideTitle &&
          <ScreenHeader withSearch={!hideSearch} search={filter.search} onChange={(event) => handleFilterChange('search', event.target.value)} />
        }
        <div className="fk-filter-container --only-filter">
          <div className="fk-filter">
              <FilterMenu
                  label={t('status.text')}
                  options={complainStatuses}
                  selected={filter.status}
                  onChange={(option) => handleFilterChange('status', option)}
                  tFunc={t}
                  tPrefix="status."
              />
              <FilterMenu
                  label={t('visibility.text')}
                  options={visibilityStates}
                  selected={filter.isPublic}
                  onChange={(option) => handleFilterChange('isPublic', option)}
                  tFunc={t}
                  tPrefix="visibility."
              />
              <FilterMenu
                  datepicker
                  label={t('createdAt.text')}
                  options={[]}
                  selected={filter.dateFrom}
                  buttonText={`${t('createdAt.from')} ${filter.dateFrom ? format(new Date(filter.dateFrom), "yyyy-MM-dd") : ''}`}
                  onChange={(option) => handleFilterChange('dateFrom', option)}
              />
              <FilterMenu
                  datepicker
                  options={[]}
                  selected={filter.dateTo}
                  buttonText={`${t('createdAt.to')} ${filter.dateTo ? format(new Date(filter.dateTo), "yyyy-MM-dd") : ''}`}
                  onChange={(option) => handleFilterChange('dateTo', option)}
              />
              <FilterMenu
                  actionButton
                  buttonText={t('mergeSelectedComplains')}
                  onClick={handleMergeSelectedComplains}
              />
              <FilterMenu
                  actionButton
                  link={csvLink}
                  buttonText={t_common('downloadCsv')}
              />
          </div>
        </div>
        <MergeComplainsModal
          show={showMergeModal}
          complains={selectedItems}
          fallback={onMergeModalComplete}
          onCancel={() => setShowMergeModal(false)}
        />
    </>
  );
}

const mapStateToProps = state => ({
  filter: state.complains.filter,
  selectedItems: state.complains.selectedItems,
  csvLink: state.complains.csvLink,
});

export default connect(mapStateToProps, {})(ComplainsTableFilter);