import React, { Component } from 'react';
import { companiesTableHead } from '../app/config';
import { CompaniesTableFilter, CompaniesTableRow, TableHeader, TablePagination } from "../components/table";
import {
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";
import { connect } from 'react-redux';
import {
  fetchCompanies,
  previousPage,
  nextPage,
  resetFilter,
  setFilter,
  checkItem,
  checkAllItems,
  uncheckItem,
  uncheckAllItems,
} from "../app/slices/companiesSlice";
import _ from 'lodash';

class CompaniesScreen extends Component {
    constructor(props) {
      super(props);

      this.state = {
        open: null,
      };

      this.props.resetFilter();
      this.refresh();

      this.handleRowClick = this.handleRowClick.bind(this);
      this.handleRequestSort = this.handleRequestSort.bind(this);
      this.handlePreviousPage = this.handlePreviousPage.bind(this);
      this.handleNextPage = this.handleNextPage.bind(this);
      this.handleRowClick = this.handleRowClick.bind(this);
      this.handleSelectAll = this.handleSelectAll.bind(this);
    }

    refresh() {
      this.props.fetchCompanies();
    }

    handleRequestSort(event, property) {
      const direction =
        this.props.filter.orderBy === property &&
        this.props.filter.orderDirection === "asc"
          ? "desc"
          : "asc";
      this.props.setFilter({ field: "orderBy", value: property });
      this.props.setFilter({ field: "orderDirection", value: direction });

      this.refresh();
    }

    handleRowCheck(item, isChecked) {
      if (!isChecked) {
          this.props.checkItem(item);
      } else {
          this.props.uncheckItem(item);
      }

    }
    handleSelectAll() {
      if (this.props.selectedItems.length === this.props.companies.length) {
          this.props.uncheckAllItems();
      } else {
          this.props.checkAllItems();
      }
    }

    handleRowClick(id) {
      this.setState({
        open: this.state.open === id ? null : id,
      });
    }

    handlePreviousPage() {
      this.props.previousPage();
      this.refresh();
    }

    handleNextPage() {
      this.props.nextPage();
      this.refresh();
    }

    render() {
        return (
          <div className="fk-screen">
            <CompaniesTableFilter />
            <TableContainer>
              <Table size="medium" className="fk-table">
                <TableHeader
                  crud
                  withCheckbox
                  items={companiesTableHead}
                  orderBy={this.props.filter.orderBy}
                  order={this.props.filter.orderDirection}
                  numSelected={this.props.selectedItems.length}
                  rowCount={this.props.companies.length}
                  onRequestSort={this.handleRequestSort}
                  onSelectAllClick={this.handleSelectAll}
                  screen="CompaniesScreen"
                />

                <TableBody>
                    {this.props.companies.map((company) => {
                      const isChecked = _.some(this.props.selectedItems, (item) => item.id === company.id);

                      return (
                        <CompaniesTableRow
                            key={company.id}
                            checked={isChecked}
                            company={company}
                            open={this.state.open === company.id}
                            onRowClick={this.handleRowClick}
                            onCheck={() => this.handleRowCheck(company, isChecked)}
                        />
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
                filter={this.props.filter}
                handlePreviousPage={this.handlePreviousPage}
                handleNextPage={this.handleNextPage}
            />
          </div>
        );
    }
}

const mapStateToProps = (state) => ({
  companies: state.companies.data,
  filter: state.companies.filter,
  selectedItems: state.companies.selectedItems,
});

const actions = {
  fetchCompanies,
  previousPage,
  nextPage,
  resetFilter,
  setFilter,
  checkItem,
  checkAllItems,
  uncheckItem,
  uncheckAllItems,
};

export default connect(mapStateToProps, actions)(CompaniesScreen);