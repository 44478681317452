import { Grid } from '@mui/material';
import React, { Component } from 'react';
import { CompanyAdditionalInfoForm, CompanyInfoForm, CompanyInfoSection, CompanySubmitForm } from '../components/forms/company';
import { ScreenHeader } from '../components/screenHeader';
import { clearCompany } from '../app/slices/companiesSlice';
import { connect } from 'react-redux';

class CompanyCreateScreen extends Component {
    constructor(props) {
        super(props);

        if (props.model?.id) {
            props.clearCompany();
        }
    }

    render() {
        return (
            <div className="fk-screen">
                <ScreenHeader withBackButton withSeparator />
                <Grid container spacing={4}>
                    <Grid item md={6}>
                        <CompanyInfoForm isCreate />
                        <CompanyAdditionalInfoForm isCreate />
                        <CompanySubmitForm />
                    </Grid>
                    <Grid item md={6}>
                        <CompanyInfoSection />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    model: state.companies.company,
});

const actions = {
    clearCompany,
};

export default connect(mapStateToProps, actions)(CompanyCreateScreen);