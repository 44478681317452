import BackArrow from './back_arrow.png';
import ListAdd from './list_add.png';
import PrivateIcon from './private_icon.svg';
import PrivateIconWithBg from './private_icon_with_bg.svg';
import PublicIcon from './public_icon.svg';
import PublicIconWithBg from './public_icon_with_bg.svg';
import TrashIcon from './trash_icon.svg';

export {
    BackArrow,
    ListAdd,
    PrivateIcon,
    PrivateIconWithBg,
    PublicIcon,
    PublicIconWithBg,
    TrashIcon,
};