export const companiesTableHead = [
    {
      id: 'id',
      label: 'ID firmy',
    },
    {
      id: 'name',
      label: 'Nazwa',
    },
    {
      id: 'sectorId',
      label: 'Rodzaj działalności',
    },
    {
      id: 'openComplainsAmount',
      label: 'Liczba otwartych skarg',
    },
    {
      id: 'closeComplainsAmount',
      label: 'Liczba zamkniętych skarg',
    },
    {
        id: 'answerRating',
        label: 'Ratio',
    },
    {
      id: 'points',
      label: 'Ocena punktowa (wyświetlana)',
    },
    {
      id: 'undeliveredComplains',
      label: 'Niedostarczone skargi',
    },
  ];

export const companiesSummaryTableHead = [
    {
        id: 'id',
        label: 'ID firmy',
    },
    {
        id: 'name',
        label: 'Nazwa',
    },
    {
        id: 'sectorId',
        label: 'Rodzaj działalności',
    },
    {
        id: 'openComplainsAmount',
        label: 'Liczba otwartych skarg',
    },
    {
        id: 'closeComplainsAmount',
        label: 'Liczba zamkniętych skarg',
    },
    {
        id: 'answerRating',
        label: 'Ratio',
    },
    {
        id: 'points',
        label: 'Ocena punktowa (wyświetlana)',
    },
    {
        id: 'undeliveredComplains',
        label: 'Niedostarczone skargi',
    },
];