import { format } from "date-fns";
import { visibilityStates } from "../../../app/config";

export const dataFieldsArray = [
    {
        name: 'id',
    },
    {
        name: 'firstCreatedAt',
        convert: (value) => format(
                new Date(value ? value : new Date()),
                "yyyy-MM-dd 'godz:' HH:mm"
            )
    },
    {
        name: 'date',
        convert: (value) => format(
                new Date(value ? value : new Date()),
                "yyyy-MM-dd 'godz:' HH:mm"
            )
    },
    {
        name: 'typeId',
        modelName: 'TypeId',
        required: true,
    },
    {
        name: 'isPublic',
        modelName: 'IsPublic',
        items: visibilityStates,
        required: true,
    },
    {
        name: 'title',
        modelName: 'Title',
        required: true,
    },
    {
        name: 'description',
        modelName: 'Description',
        textarea: true,
        rows: 5,
    }
];