import {
    combineReducers,
    configureStore,
} from '@reduxjs/toolkit';

import authReducer from './slices/authSlice';
import consumersReducer from './slices/consumersSlice';
import complainsReducer from './slices/complainsSlice';
import complainTypesReducer from './slices/complainTypesSlice';
import companiesReducer from './slices/companiesSlice';
import modalReducer from './slices/modalSlice';
import logsReducer from './slices/logsSlice';

const reducer = combineReducers({
    auth: authReducer,
    consumers: consumersReducer,
    complains: complainsReducer,
    complainTypes: complainTypesReducer,
    companies: companiesReducer,
    modal: modalReducer,
    logs: logsReducer,
});

const store = configureStore({
    reducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export default store;