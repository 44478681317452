export const complainLogsTableHead = [
    {
        id: 'createdAt',
    },
    {
        id: 'author',
    },
    {
        id: 'eventType',
    }
]