import { connect } from "react-redux";

function ComplainHistory() {
    return (
        <></>
    );
}

const mapStateToProps = (state) => ({
});

const actions = {
};

export default connect(mapStateToProps, actions)(ComplainHistory);