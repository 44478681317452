import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import React from "react";
import { APP_URLS } from "../../../app/config";
import { AccountLevel1, NoCompanyLogo } from "../../../assets/images";
import { Button, Grid } from "@mui/material";
import { sendLogo } from '../../../app/slices/companiesSlice';
import ConsumerRankIcon from "../../common/consumerRankIcon";

function ConsumerInfoSection({ model, sendLogo }) {
    const { t } = useTranslation('ConsumerDetailsScreen');

    return (
        <div className="fk-form-section">
            <Grid container spacing={2}>
                <Grid item md={3} className="fk-consumer-points-section">
                    <label>
                        {t('collectedPoints')}
                    </label>
                    <span>
                        {model.points}
                    </span>
                </Grid>
                <Grid item md={9} className="fk-consumer-points-section">
                    <label>
                        {t('consumerRank')}
                    </label>
                    <span className="rank">
                        {model.rank}
                        <ConsumerRankIcon rank={model.localizedRank} />
                    </span>
                </Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    model: state.consumers.consumer
});

const actions = {
};

export default connect(mapStateToProps, actions)(ConsumerInfoSection);