import { Table, TableBody, TableContainer } from "@mui/material";
import React, { Component } from "react";
import {
  fetchLogs,
  previousPage,
  nextPage,
  resetFilter,
  setFilter,
} from "../app/slices/logsSlice";
import {
  LogsTableFilter,
  ComplainsTableRow,
  TableHeader,
  TablePagination,
} from "../components/table";
import { complainsTableHead } from "../app/config";
import "./screens.styles.scss";
import "../components/table/table.styles.scss";
import { connect } from "react-redux";
import _ from "lodash";
import { logsTableHead } from "../app/config/tables/logsTableHead";
import LogsTableRow from "../components/table/rows/logsTableRow";

class LogsScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: null,
        };

        this.props.resetFilter();
        this.refresh();

        this.handlePreviousPage = this.handlePreviousPage.bind(this);
        this.handleNextPage = this.handleNextPage.bind(this);
        this.handleRequestSort = this.handleRequestSort.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handleSelectAll = this.handleSelectAll.bind(this);
    }

    componentWillUnmount() {
        this.props.resetFilter();
    }

    refresh() {
        this.props.fetchLogs();
    }

    handlePreviousPage() {
        this.props.previousPage();
        this.refresh();
    }

    handleNextPage() {
        this.props.nextPage();
        this.refresh();
    }

    handleRequestSort(event, property) {
        const direction =
            this.props.filter.orderBy === property &&
            this.props.filter.orderDirection === "asc"
                ? "desc"
                : "asc";
        this.props.setFilter({ field: "orderBy", value: property });
        this.props.setFilter({ field: "orderDirection", value: direction });

        this.refresh();
    }

    handleRowCheck(item, isChecked) {
        if (!isChecked) {
            this.props.checkItem(item);
        } else {
            this.props.uncheckItem(item);
        }
    }

    handleSelectAll() {
        if (this.props.selectedItems.length === this.props.complains.length) {
            this.props.uncheckAllItems();
        } else {
            this.props.checkAllItems();
        }
    }

    handleRowClick(id) {
        this.setState({
            open: this.state.open === id ? null : id,
        });
    }

  render() {
    return (
      <div className="fk-screen">
            <LogsTableFilter />
            <TableContainer>
                <Table size="medium" className="fk-table">
                    <TableHeader
                        items={logsTableHead}
                        orderBy={this.props.filter.orderBy}
                        order={this.props.filter.orderDirection}
                        onRequestSort={this.handleRequestSort}
                        screen="LogsScreen"
                    />
                    <TableBody>
                    {this.props.logs.map((log) => {
                        return (
                            <LogsTableRow
                                key={log.id}
                                log={log}
                                columns={logsTableHead}
                            />
                        )
                    })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                filter={this.props.filter}
                handlePreviousPage={this.handlePreviousPage}
                handleNextPage={this.handleNextPage}
            />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
    logs: state.logs.data,
    filter: state.logs.filter,
});

const actions = {
    fetchLogs,
    previousPage,
    nextPage,
    resetFilter,
    setFilter,
};

export default connect(mapStateToProps, actions)(LogsScreen);
