import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import React from "react";
import { APP_URLS } from "../../../app/config";
import { NoCompanyLogo } from "../../../assets/images";
import { Button } from "@mui/material";
import { sendLogo } from '../../../app/slices/companiesSlice';

function CompanyInfoSection({ model, sendLogo }) {
    const { t } = useTranslation('CompanyCreateScreen', { keyPrefix: 'InfoSection' });

    const handleUploadAcceptanceEmail = async (event) => {
        await sendLogo({
            id: model.id,
            acceptanceEmail: event.target.files[0],
        });
    }

    const handleUploadLogo = async (event) => {
        await sendLogo({
            id: model.id,
            logo: event.target.files[0],
        });
    }

    return (
        <div className="fk-form-section">
            <h3 className="title">
                {model?.name ? model.name : t('title')}
            </h3>
            <img
                className="logo"
                src={model?.logo ? `${APP_URLS.UPLOADS}${model.logo}` : NoCompanyLogo}
                alt=""
            />
            <div className="fk-company-logo-uploads">
                <div className="fk-company-logo-acceptance">
                    <Button component="label">
                        {t('uploadAcceptanceEmail')}
                        <input hidden type="file" onChange={handleUploadAcceptanceEmail} />
                    </Button>
                </div>
                <div className="fk-company-logo">
                    <Button component="label">
                        {t('uploadLogo')}
                        <input hidden type="file" onChange={handleUploadLogo} />
                    </Button>
                </div>
                {model.logoAcceptanceEmail &&
                    <div className="fk-company-acceptance-download">
                        <Button component="a" href={`${APP_URLS.UPLOADS}${model.logoAcceptanceEmail}`}>
                            {t('downloadAcceptanceEmail')}
                        </Button>
                    </div>
                }
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    model: state.companies.company
});

const actions = {
    sendLogo,
};

export default connect(mapStateToProps, actions)(CompanyInfoSection);