import { Box, Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { visuallyHidden } from '@mui/utils';
import { useTranslation } from "react-i18next";

function TableHeader({ items, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, crud, withCheckbox, screen }) {
    const { t } = useTranslation(screen, { keyPrefix: 'TableHeader' });
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead className="fk-table-head">
        <TableRow>
          {withCheckbox &&
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            </TableCell>
          }
          {items.map((headCell) => (
            <TableCell
              key={headCell.id}
              padding="none"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {t(headCell.id)}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          {crud &&
            <TableCell>
                {t('actions')}
            </TableCell>
          }
        </TableRow>
      </TableHead>
    );
}

export default TableHeader;