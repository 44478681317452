import React from 'react';
import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { mergeComplains } from '../../app/slices/complainsSlice';

function MergeComplainsModal({ show, complains, fallback, onCancel }) {
    const [parent, setParent] = React.useState(null);
    const { t } = useTranslation('ComplainsScreen', { keyPrefix: 'MergeComplains' });
    const dispatch = useDispatch();

    if (!show) {
        return null;
    }

    const onCloseButtonClick = async () => {
        let children = complains.filter((complain) => complain.id !== +parent).map((complain) => complain.id);

        let result = await dispatch(mergeComplains({
            parentId: parent,
            childrenIds: children
        }));

        fallback(!result.hasOwnProperty('error'));
    }

    const onCancelButtonClick = () => {
        onCancel();
    }

    return (
        <div className="fk-modal-overlay">
            <div className="fk-modal" style={{maxWidth: '600px'}}>
                <span className="fk-modal-close" onClick={onCancelButtonClick}>
                    &#10005;
                </span>
                <div className="fk-modal-title">
                    {t('dialogTitle')}
                </div>
                <div className={`fk-modal-body`}>
                    <Grid container spacing={5}>
                        <Grid item md={12}>
                            <RadioGroup
                                value={parent}
                                onChange={(event) => setParent(event.target.value)}
                            >
                                {complains.map((complain) => (
                                    <FormControlLabel key={complain.id} value={complain.id} control={<Radio />} label={complain.number} />
                                ))}
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </div>
                <div className="fk-modal-buttons">
                    <Button
                        fullWidth
                        className={`fk-modal-button-normal`}
                        onClick={onCloseButtonClick}
                        disabled={!parent}
                    >
                        {t('merge')}
                    </Button>
                    <Button
                        fullWidth
                        className={`fk-modal-button-danger`}
                        onClick={onCancelButtonClick}
                    >
                        {t('cancel')}
                    </Button>
                    {/* <Button
                        primary
                        fullWidth
                        disabled={isSuccess === null}
                        // label={t('common:send')}
                        onClick={onCloseButtonClick}
                    />
                    <Button
                        fullWidth
                        // label={t('common:cancel')}
                        onClick={onCancelButtonClick}
                    /> */}
                </div>
            </div>
        </div>
    );
}


export default MergeComplainsModal;