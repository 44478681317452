const apiUrl = process.env.REACT_APP_SERVER_URL;

export const APP_URLS = {
    BASE: `${apiUrl}`,

    UPLOADS: `${apiUrl}/uploads/`,

    GET_TOKEN: `${apiUrl}/connect/token`,
    ADMIN_LOGIN: `${apiUrl}/admin/token`,
    USER_DATA: `${apiUrl}/api/Account`,
    USER_CREATE: `${apiUrl}/api/Account/createWithoutPassword`,
    USER_REMEMBER_PASSWORD: `${apiUrl}/api/ForgotPasswordAdmin`,
    USER_SET_NEW_PASSWORD: `${apiUrl}/api/Account/setNewPassword`,
    USER_SEND_SET_NEW_PASSWORD: `${apiUrl}/api/Account/sendSetNewPassword/{id}`,

    EDIT_MESSAGE: `${apiUrl}/api/Message/edit`,
    EDIT_DELETE: `${apiUrl}/api/Message/delete`,
    
    
    COMPANIES_LIST: `${apiUrl}/api/Company/list`,
    COMPANY_CREATE: `${apiUrl}/api/Company`,
    COMPANY_UPDATE: `${apiUrl}/api/Company/{id}`,
    COMPANY_GET: `${apiUrl}/api/Company/{id}`,
    COMPANY_LOGO_UPLOAD: `${apiUrl}/api/Company/sendLogoAdmin/{id}`,
    COMPANY_CHANGE_EMAIL: `${apiUrl}/api/Company/changeCompanyEmail`,
    COMPANY_MERGE: `${apiUrl}/api/Company/mergeCompanies`,
    
    COMPANY_CONFIRM: `${apiUrl}/api/Company/confirmCompany`,

    COMPLAINS_LIST: `${apiUrl}/api/Report/list`,
    COMPLAINS_CSV: `${apiUrl}/api/Report/csv`,
    COMPLAIN_TYPES: `${apiUrl}/api/Dictionary/ComplainTypes/{id}`,
    COMPLAIN_GET: `${apiUrl}/api/Report/my/forWeb/{id}`,
    COMPLAIN_UPDATE: `${apiUrl}/api/Report/{id}`,
    COMPLAIN_REMOVE: `${apiUrl}/api/Report/{id}`,
    COMPLAIN_REMOVE_PHOTO: `${apiUrl}/api/Report/{id}/removePhoto`,
    COMPLAIN_SEND_AGAIN: `${apiUrl}/api/Report/{id}/sendToCompany`,
    COMPLAIN_BULK_SEND_AGAIN: `${apiUrl}/api/Report/sendBulkToCompany`,
    COMPLAIN_SEND_MESSAGE: `${apiUrl}/api/Report/{id}/sendMessage`,
    COMPLAIN_CLOSE: `${apiUrl}/api/Report/{id}/close`,
    COMPLAIN_CHANGE_VISIBILITY_IS_BLOCKED: `${apiUrl}/api/Report/{id}/changeVisibilityIsBlocked`,
    COMPLAIN_MERGE: `${apiUrl}/api/Report/mergeComplaints`,
    COMPLAIN_UNMERGE: `${apiUrl}/api/Report/unmergeComplaints`,
    COMPLAIN_STATS: `${apiUrl}/api/Reports/stats`,
    SECTOR_STATS: `${apiUrl}/api/Reports/stats/sectors`,
    COMPANY_STATS: `${apiUrl}/api/Reports/stats/companies`,
    COMPANY_SEARCH: `${apiUrl}/api/Company/search`,
    
    CONSUMERS_LIST: `${apiUrl}/api/Account/list`,
    CONSUMER_CHANGE_STATUS: `${apiUrl}/api/Account/changeStatus?id={id}`,
    CONSUMER_REMOVE: `${apiUrl}/api/Account?id={id}`,
    CONSUMER_GET: `${apiUrl}/api/Account/{id}`,
    CONSUMER_UPDATE: `${apiUrl}/api/Account/{id}`,
    CONSUMER_REMOVE_PHOTO: `${apiUrl}/api/Account/removePhoto/{id}`,
    CONSUMER_REMOVE_ADMIN: `${apiUrl}/api/Account/{id}/removeAdmin`,

    LOGS_LIST: `${apiUrl}/api/Report/loglist`,
}