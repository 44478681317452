const SectorItem = ({label, value, transparent}) => {
    return (
        <div className="itemRow">
            <div className="item left" style={{backgroundColor: transparent && 'transparent'}}>
                {label}
            </div>
            <div className="item" style={{backgroundColor: transparent && 'transparent'}}>
                {value}
            </div>
        </div>
    )
}

export default SectorItem;