import ReportCard from "./ReportCard";
import Stat from "./Stat";
import {ComplainsTableFilter, ComplainsTableRow, TableHeader, TablePagination} from "../../components/table";
import {InputAdornment, Table, TableBody, TableContainer, TextField} from "@mui/material";
import {APP_URLS, complainsTableHead, complainStatuses, STORAGE} from "../../app/config";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import {
    checkAllItems,
    checkItem,
    fetchComplains,
    nextPage,
    previousPage,
    resetFilter,
    setFilter, uncheckAllItems, uncheckItem
} from "../../app/slices/complainsSlice";
import {connect, useDispatch} from "react-redux";
import {Search} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {TailSpin} from "react-loader-spinner";

const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY));
const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token?.token,
};

const defaultStats = {
    totalComplaints: 0,
    complaintsClosedCount: 0,
    complaintsClosedAutomatically: 0,
    complaintsManuallyClosedTotal: 0,
    complaintsManuallyClosedSuccessfully: 0,
    complaintsManuallyClosedUnsuccessfully: 0,
    publicComplaintsRatio: 0,
    closedSuccessfully: 0,
    complaintsWithCompanyAnswer: 0,
    averageResponseTime: 0,
    createdCases: {
        thisMonth: 0,
        thisYear: 0,
        fromBeginning: 0
    },
    closedSuccessfullyCases: {
        thisMonth: 0,
        thisYear: 0,
        fromBeginning: 0
    },
    closedUnsuccessfullyCases: {
        thisMonth: 0,
        thisYear: 0,
        fromBeginning: 0
    }
}


const CasesTab = (props) => {
    const [open, setOpen] = useState(null);
    const dispatch = useDispatch();
    const [activeFilter, setActiveFilter] = useState(0);
    const {t: tPages} = useTranslation('Pages');
    const [stats, setStats] = useState(defaultStats);
    const [isLoading, setIsLoading] = useState(true);

    let timer = null;

    const refresh = () => {
        props.fetchComplains();
    }

    useEffect(() => {
        setIsLoading(true);
        
        axios.get(APP_URLS.COMPLAIN_STATS).then(response => {
            setStats(response.data);
            setIsLoading(false);
        })
    }, []);
    
    const handlePreviousPage = () => {
        props.previousPage();
        refresh();
    }

    const clearFilters = () => {
        clearTimeout(timer);
        dispatch(resetFilter());

        timer = setTimeout(() => {
            dispatch(fetchComplains());
        }, 500);
    }

    const handleNextPage = () => {
        props.nextPage();
        refresh();
    }


    const filterCaseCreated = (currentPeriod, success, filterNumber) => {
        clearTimeout(timer);
        setActiveFilter(filterNumber);
        let firstDay;

        const dateTo = new Date();

        switch (currentPeriod) {
            case 'month': {
                firstDay = new Date(dateTo.getFullYear(), dateTo.getMonth(), 1);
                break;
            }
            case 'year': {
                firstDay = new Date(dateTo.getFullYear(), 0, 1);
                break;
            }
            case 'start': {
                firstDay = null;
                break;
            }
        }

        dispatch(setFilter({field: 'status', value: success ? success : null}));
        dispatch(setFilter({field: 'dateFrom', value: firstDay}));
        dispatch(setFilter({field: 'dateTo', value: dateTo}));

        timer = setTimeout(() => {
            dispatch(fetchComplains());
        }, 500);

    }

    const handleRequestSort = (event, property) => {
        const direction =
            props.filter.orderBy === property &&
            props.filter.orderDirection === "asc"
                ? "desc"
                : "asc";
        props.setFilter({field: "orderBy", value: property});
        props.setFilter({field: "orderDirection", value: direction});

        refresh();
    }

    const handleRowCheck = (item, isChecked) => {
        if (!isChecked) {
            props.checkItem(item);
        } else {
            props.uncheckItem(item);
        }
    }

    const handleSelectAll = () => {
        if (props.selectedItems.length === props.complains.length) {
            props.uncheckAllItems();
        } else {
            props.checkAllItems();
        }
    }

    const handleRowClick = (id) => {
        setOpen(prev => prev === id ? null : id);
    }

    useEffect(() => {
        props.fetchComplains();

        return () => {
            props.resetFilter();
            props.uncheckAllItems();
        }
    }, []);

    const searchIcon = {
        endAdornment:
            <InputAdornment position="end">
                <Search/>
            </InputAdornment>
    };

    const handleFilterChange = (field, value) => {
        clearTimeout(timer);

        dispatch(setFilter({field, value,}));

        timer = setTimeout(() => {
            dispatch(fetchComplains());
        }, 500);
    };
    
    if(isLoading) {
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '80vh'}}>
                <TailSpin
                    visible={true}
                    height="80"
                    width="80"
                    color="#4fa94d"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
            </div>
        )
    }
    

    return (
        <div className="fk-screen">
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', padding: 24}}>
                <TextField
                    className="fk-input"
                    variant="outlined"
                    placeholder={tPages('searchFieldLabel')}
                    InputProps={searchIcon}
                    value={props.filter.search}
                    onChange={(event) => handleFilterChange('search', event.target.value)}
                />
            </div>
            

            <div style={{display: 'flex', flexDirection: 'row'}}>
                <span className="rowHeaderText">Quick filters </span>
                <span style={{color: '#B2B2B2', marginLeft: 12, cursor: 'pointer'}} onClick={clearFilters}>Clear filters</span>
            </div>


            <div className="rowContainer">
                <ReportCard label={'Cases created'} isLoading={isLoading}>
                    <Stat value={stats?.createdCases?.thisMonth}
                          description={"this month"}
                          onClick={filterCaseCreated}
                          period={'month'}
                          filterNumber={1}
                          activeFilter={activeFilter}
                    />

                    <Stat value={stats?.createdCases?.thisYear}
                          description={"this year"}
                          onClick={filterCaseCreated}
                          period={'year'}
                          filterNumber={2}
                          activeFilter={activeFilter}
                    />
                    <Stat value={stats?.createdCases?.fromBeginning}
                          description={"since beginning"}
                          onClick={filterCaseCreated}
                          period={'start'}
                          filterNumber={3}
                          activeFilter={activeFilter}
                    />
                </ReportCard>

                <ReportCard label={'Cases completed successfully'} isLoading={isLoading}>
                    <Stat value={stats?.closedSuccessfullyCases?.thisMonth}
                          description={"this month"}
                          onClick={filterCaseCreated}
                          period={'month'}
                          filterNumber={4}
                          activeFilter={activeFilter}
                          status={complainStatuses[2]}
                    />
                    <Stat value={stats?.closedSuccessfullyCases?.thisYear}
                          description={"this year"}
                          onClick={filterCaseCreated}
                          period={'year'}
                          filterNumber={5}
                          activeFilter={activeFilter}
                          status={complainStatuses[2]}
                    />

                    <Stat value={stats?.closedSuccessfullyCases?.fromBeginning}
                          description={"since beginning"}
                          onClick={filterCaseCreated}
                          period={'start'}
                          filterNumber={6}
                          activeFilter={activeFilter}
                          status={complainStatuses[2]}
                    />
                </ReportCard>

                <ReportCard label={'Cases ended unsuccessfully'} isLoading={isLoading}>
                    <Stat value={stats?.closedUnsuccessfullyCases?.thisMonth}
                          description={"this month"}
                          onClick={filterCaseCreated}
                          period={'month'}
                          filterNumber={7}
                          activeFilter={activeFilter}
                          status={complainStatuses[3]}
                    />
                    <Stat value={stats?.closedUnsuccessfullyCases?.thisYear}
                          description={"this year"}
                          onClick={filterCaseCreated}
                          period={'year'}
                          filterNumber={8}
                          activeFilter={activeFilter}
                          status={complainStatuses[3]}
                    />
                    <Stat value={stats?.closedUnsuccessfullyCases?.fromBeginning}
                          description={"since beginning"}
                          onClick={filterCaseCreated}
                          period={'start'}
                          filterNumber={9}
                          activeFilter={activeFilter}
                          status={complainStatuses[3]}
                    />
                </ReportCard>
            </div>

            <ComplainsTableFilter hideSearch/>
            <TableContainer>
                <Table size="medium" className="fk-table">
                    <TableHeader
                        crud
                        withCheckbox
                        items={complainsTableHead}
                        orderBy={props.filter.orderBy}
                        order={props.filter.orderDirection}
                        numSelected={props.selectedItems.length}
                        rowCount={props.complains.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAll}
                        screen="ComplainsScreen"
                    />
                    <TableBody>
                        {props.complains.map((complain) => {
                            const isChecked = _.some(props.selectedItems, (item) => item.id === complain.id);

                            return (
                                <ComplainsTableRow
                                    key={complain.id}
                                    checked={isChecked}
                                    open={open === complain.id}
                                    complain={complain}
                                    columns={complainsTableHead}
                                    onCheck={() => handleRowCheck(complain, isChecked)}
                                    onRowClick={handleRowClick}
                                />
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                filter={props.filter}
                handlePreviousPage={handlePreviousPage}
                handleNextPage={handleNextPage}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    complains: state.complains.data,
    filter: state.complains.filter,
    selectedItems: state.complains.selectedItems,
});

const actions = {
    fetchComplains,
    previousPage,
    nextPage,
    resetFilter,
    setFilter,
    checkItem,
    checkAllItems,
    uncheckItem,
    uncheckAllItems,
};

export default connect(mapStateToProps, actions)(CasesTab);