import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import classNames from 'classnames';
import { Grid, MenuItem, TextField } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import {
    setConsumer,
} from '../../../app/slices/consumersSlice';
import _ from "lodash";
import { infoFieldsArray } from "./config";
import { NoProfilePhoto } from "../../../assets/images";

function ConsumerInfoForm({ canEdit, onUpdate, model, errors, setConsumer }) {
    const { t } = useTranslation('ConsumerDetailsScreen', { keyPrefix: 'InfoForm' });

    const [currentEdit, setCurrentEdit] = React.useState('');

    const checkIfDisabled = (field) => !canEdit || field !== currentEdit;
    const getErrorMessage = (field) => _.find(errors, (error) => error.field === field);
    const handleFieldChange = (field, value) => setConsumer({ field, value });
    
    const isAvatarSet = model?.avatar?.split('/')?.filter((s) => !!s)?.length > 1 ?? false;
    const isLocalAvatar = isAvatarSet && (model?.avatar?.startsWith('https') ?? false);
    const handleUpdate = async (name, value) => {
        await onUpdate(name, value);

        setCurrentEdit(null);
    }
    
    const handleRemovePhoto = async () => await onUpdate('avatar', '');

    return (
        <div className="fk-form-section">
            <h3 className="title">
                {t('title')}
            </h3>
            <Grid container spacing={2}>
                {infoFieldsArray.map(({name, modelName, required, items, convert}, index) => (
                    <React.Fragment key={index}>
                        <Grid item md={3} className="fk-label">
                            <label htmlFor={name}>{`${t(name)}${(required ? '*' : '')}`}</label>
                        </Grid>
                        <Grid item md={8} className={
                            classNames(
                                'fk-input-container',
                                {
                                    'has-error': !!getErrorMessage(name),
                                }
                            )
                        }>
                            <TextField
                                fullWidth
                                select={!!items}
                                disabled={checkIfDisabled(name)}
                                className="fk-input"
                                placeholder={t(`${name}Placeholder`)}
                                helperText={getErrorMessage(name)?.error}
                                value={
                                    model
                                    ? convert ? convert(model[name]) : model[name]
                                    : !!items ? '' : ''
                                }
                                onChange={(event) => handleFieldChange(name, event.target.value)}
                            >
                                {items && items.map(({value, text}) => (
                                    <MenuItem key={value} value={value}>
                                        {t(text)}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        {modelName && canEdit ?
                            <Grid item md={1}>
                                {name !== currentEdit
                                    ? <button
                                        className="fk-edit-button"
                                        onClick={() => setCurrentEdit(name)}
                                    >
                                        <EditIcon />
                                    </button>
                                    : <button
                                        className="fk-edit-button"
                                        onClick={() => handleUpdate(modelName, model[name])}
                                    >
                                        <SaveIcon />
                                    </button>
                                }
                            </Grid>
                            : <Grid item md={1} />
                        }
                    </React.Fragment>
                ))}
                <Grid item md={3} className="fk-label">
                    <label>{t('avatar')}</label>
                </Grid>
                <Grid item md={8} className="fk-account-photo">
                    <div className="photo-container">
                        <img src={isAvatarSet ? `${!isLocalAvatar ? process.env.REACT_APP_SERVER_URL : ''}${model.avatar}` : NoProfilePhoto} alt="Zdjęcie użytkownika" />
                        {isAvatarSet && <span onClick={handleRemovePhoto}>Usuń</span>}
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    model: state.consumers.consumer,
    errors: state.consumers.errors,
});

const actions = {
    setConsumer,
};

export default connect(mapStateToProps, actions)(ConsumerInfoForm);