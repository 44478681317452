// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.attachmentContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  row-gap: 1rem;
  padding-top: 1rem;
}

.attachmentItem {
  display: inline-flex;
  margin-right: 16px;
  flex-direction: row;
  flex-shrink: 1;
  column-gap: 4px;
  align-items: center;
  padding-right: 4px;
  border-right: solid 2px #41A046;
  line-height: 5px;
}
.attachmentItem p {
  font-size: 0.8rem;
}
.attachmentItem button {
  color: red;
  padding: 0;
  background-color: transparent;
  align-items: center;
  justify-content: center;
}

.leftColumn {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.rightColumn {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 0 0 0 24px;
}
.rightColumn button {
  padding-top: 7px;
}
.rightColumn .cancel {
  background-color: red;
}

.editForm {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.editForm textarea {
  display: flex;
  flex-grow: 1;
  resize: none;
}

.attachmentContainer > .attachmentItem:last-child {
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/forms/complain/ComplaintMessageEditForm/complaintMessageEditForm.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,eAAA;EACA,mBAAA;EACA,aAAA;EACA,iBAAA;AADF;;AAIA;EACE,oBAAA;EACA,kBAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;EACA,mBAAA;EACA,kBAAA;EACA,+BAAA;EACA,gBAAA;AADF;AAGE;EACE,iBAAA;AADJ;AAIE;EACE,UAAA;EACA,UAAA;EACA,6BAAA;EACA,mBAAA;EACA,uBAAA;AAFJ;;AAMA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;AAHF;;AAMA;EACE,aAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;AAHF;AAKE;EACE,gBAAA;AAHJ;AAME;EACE,qBAAA;AAJJ;;AAUA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;AAPF;AASE;EACE,aAAA;EACA,YAAA;EACA,YAAA;AAPJ;;AAWA;EAAoD,YAAA;AAPpD","sourcesContent":["\n\n.attachmentContainer {\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: row;\n  row-gap: 1rem;\n  padding-top: 1rem;\n}\n\n.attachmentItem {\n  display: inline-flex;\n  margin-right: 16px;\n  flex-direction: row;\n  flex-shrink: 1;\n  column-gap: 4px;\n  align-items: center;\n  padding-right: 4px;\n  border-right: solid 2px #41A046;\n  line-height: 5px;\n  \n  p {\n    font-size: 0.8rem;\n  }\n  \n  button {\n    color: red;\n    padding: 0;\n    background-color: transparent;\n    align-items: center;\n    justify-content: center;\n  }\n}\n\n.leftColumn {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.rightColumn {\n  display: flex;\n  flex-direction: column;\n  row-gap: 12px;\n  padding: 0 0 0 24px;\n  \n  button {\n    padding-top: 7px;\n  }\n  \n  .cancel {\n    background-color: red;\n  }\n}\n\n\n\n.editForm {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  \n  textarea {\n    display: flex;\n    flex-grow: 1;\n    resize: none;\n  }\n}\n\n.attachmentContainer > .attachmentItem:last-child { border: none; }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
