import ReportCard from "../ReportCard";
import Stat from "../Stat";
import React, {useEffect, useState} from "react";
import {APP_URLS, STORAGE} from "../../../app/config";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {fetchComplains, setFilter} from "../../../app/slices/complainsSlice";

const defaultStats = {
    totalComplaints: 0,
    complaintsClosedCount: 0,
    complaintsClosedAutomatically: 0,
    complaintsManuallyClosedTotal: 0,
    complaintsManuallyClosedSuccessfully: 0,
    complaintsManuallyClosedUnsuccessfully: 0,
    publicComplaintsRatio: 0,
    closedSuccessfully: 0,
    complaintsWithCompanyAnswer: 0,
    averageResponseTime: 0,
    createdCases: {
        thisMonth: 0,
        thisYear: 0,
        fromBeginning: 0
    },
    closedSuccessfullyCases: {
        thisMonth: 0,
        thisYear: 0,
        fromBeginning: 0
    },
    closedUnsuccessfullyCases: {
        thisMonth: 0,
        thisYear: 0,
        fromBeginning: 0
    }
}

const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY));
const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token?.token,
};

const SummaryTab = () => {
    const dispatch = useDispatch();
    const [activeFilter, setActiveFilter] = useState(0);
    const {t: tPages} = useTranslation('Pages');
    const [stats, setStats] = useState(defaultStats);
    const [isLoading, setIsLoading] = useState(true);
    const [filters, setFilters] = useState({dateTo: new Date()});

    const setFilter = (field, value) => {
        setFilters(prev => {
            return {
                ...prev,
                [field]: value
            }
        })
    }

    let timer = null;

    useEffect(() => {
        setIsLoading(true);
        
        axios.get(APP_URLS.COMPLAIN_STATS, {params: {
            dateFrom: filters.dateFrom,
            dateTo: filters.dateTo
            }}).then(response => {
            setStats(response.data);
            setIsLoading(false);
        })
    }, [filters]);

    const filterCaseCreated = (currentPeriod, filterNumber) => {
        clearTimeout(timer);
        setActiveFilter(filterNumber);
        let firstDay;

        const dateTo = new Date();

        switch (currentPeriod) {
            case 'month': {
                firstDay = new Date(dateTo.getFullYear(), dateTo.getMonth(), 1);
                break;
            }
            case 'year': {
                firstDay = new Date(dateTo.getFullYear(), 0, 1);
                break;
            }
            case 'start': {
                firstDay = null;
                break;
            }
        }

        setFilter('dateFrom', firstDay);
    }

    return (
        <div className="fk-screen">
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <span className="rowHeaderText">Summary</span>
            </div>
            <div className="rowContainer">
                <div
                    className={`tabButton --small ${activeFilter === 1 && '--active'}`}
                    onClick={() => filterCaseCreated('start', 1)}>
                    since begining
                </div>
                <div
                    className={`tabButton --small ${activeFilter === 2 && '--active'}`}
                    onClick={() => filterCaseCreated('year', 2)}>
                    this year
                </div>
                <div
                    className={`tabButton --small ${activeFilter === 3 && '--active'}`}
                    onClick={() => filterCaseCreated('month', 3)}>
                    this month
                </div>
            </div>
            <div className="rowContainer">
                <ReportCard label={'Complaints closed'} isLoading={isLoading}>
                    <Stat value={stats?.complaintsClosedCount}/>
                </ReportCard>

                <ReportCard label={'Ratio of automatically closed cases'} isLoading={isLoading}>
                    <Stat value={`${(stats?.complaintsClosedAutomatically / stats?.complaintsClosedCount * 100).toFixed(2)}%`}
                          description={`${stats?.complaintsClosedAutomatically} / ${stats?.complaintsClosedCount} cases`}/>
                </ReportCard>

                <ReportCard label={'Ratio of manually closed cases'} isLoading={isLoading}>
                    <Stat
                        value={`${(stats?.complaintsManuallyClosedSuccessfully / stats?.complaintsManuallyClosedTotal * 100).toFixed(2)}%`}
                        description={`${stats?.complaintsManuallyClosedSuccessfully} successfully closed`}/>
                    <Stat
                        value={`${(stats?.complaintsManuallyClosedUnsuccessfully / stats?.complaintsManuallyClosedTotal * 100).toFixed(2)}%`}
                        description={`${stats?.complaintsManuallyClosedUnsuccessfully} unsuccessfully closed`}
                        valueColor="red"/>
                </ReportCard>

                <ReportCard label={'Platform success rate'} isLoading={isLoading}>
                    <Stat value={`${(stats?.closedSuccessfully / stats?.complaintsClosedCount * 100).toFixed(2)}%`}
                          description={`${stats?.closedSuccessfully} / ${stats?.complaintsClosedCount} cases`}/>
                </ReportCard>

                <ReportCard label={'Answering rate'} isLoading={isLoading}>
                    <Stat value={`${(stats?.complaintsWithCompanyAnswer / stats?.totalComplaints * 100).toFixed(2)}%`}
                          description={`${stats?.complaintsWithCompanyAnswer} / ${stats?.totalComplaints} cases`}/>
                </ReportCard>

                <ReportCard label={'Public complaint rate'} isLoading={isLoading}>
                    <Stat value={`${stats?.publicComplaintsRatio.toFixed(2) * 100}%`}
                          description={`${stats?.closedSuccessfully} / ${stats?.totalComplaints} cases`}/>
                </ReportCard>

                <ReportCard label={'Average response time in days'} isLoading={isLoading}>
                    <Stat value={`${Math.ceil(stats?.averageResponseTime)} ${Math.ceil(stats?.averageResponseTime) > 1 ? 'days' : 'day' }`}/>
                </ReportCard>
            </div>
        </div>
    )
}

export default SummaryTab;