import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import _ from 'lodash';
import { STORAGE, APP_URLS } from '../config';

const defaultState = {
    filter: {
        search: '',
        dateFrom: null,
        dateTo: null,
        status: null,
        isPublic: null,
        orderBy: 'createdAt',
        orderDirection: 'desc',
        pageNumber: 1,
        pageSize: 6,
        hasPrevious: false,
        hasNext: false,
    },
    data: [],
    selectedItems: [],
    complain: null,
    isPending: false,
    requestId: null,
    error: null,
    errors: [],
}

const validate = (state) => {
    return [];
}

const fetchComplains = createAsyncThunk(
    'complains/fetch',
    async (data, thunkApi) => {
        try {
            const { complains } = thunkApi.getState();

            if (complains.isPending && complains.requestId !== thunkApi.requestId) {
                return;
            }

            let params = {
                pageSize: complains.filter.pageSize ? complains.filter.pageSize : 6,
                pageNumber: complains.filter.pageNumber ? complains.filter.pageNumber : 1,
            };

            params = (data?.companyId) ? {...params, companyId: data.companyId} : {...params};
            params = (data?.reporterId) ? {...params, reporterId: data.reporterId} : {...params};
            params = (data?.parentId) ? {...params, parentId: data.parentId} : {...params};
            params = (complains.filter.search) ? {...params, search: complains.filter.search} : {...params};
            params = (complains.filter.dateFrom) ? {...params, dateFrom: complains.filter.dateFrom} : {...params};
            params = (complains.filter.dateTo) ? {...params, dateTo: complains.filter.dateTo} : {...params};
            params = (complains.filter.status) ? {...params, status: complains.filter.status.value} : {...params};
            params = (complains.filter.isPublic) ? {...params, isPublic: complains.filter.isPublic.value} : {...params};
            params = (complains.filter.sector) ? {...params, sectorId: complains.filter.sector.value} : {...params};
            params = (complains.filter.type) ? {...params, typeId: complains.filter.type.value} : {...params};
            params = (complains.filter.orderBy) ? {...params, orderBy: complains.filter.orderBy} : {...params};
            params = (complains.filter.orderDirection) ? {...params, orderDirection: complains.filter.orderDirection} : {...params};

            const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY));
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token?.token,
            };

            const response = await axios.get(
                APP_URLS.COMPLAINS_LIST,
                { params, headers});

            return response;
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

const getComplain = createAsyncThunk(
    "complains/get",
    async (id, thunkApi) => {
        try {
            const { complains } = thunkApi.getState();

            if (complains.isPending && complains.requestId !== thunkApi.requestId) {
                return;
            }
            const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY))?.token;
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }

            const response = await axios.get(
                APP_URLS.COMPLAIN_GET.replace('{id}', id),
                { headers});

            return response;
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

const updateComplain = createAsyncThunk(
    'complains/update',
    async (data, thunkApi) => {
        try {
            const { complains } = thunkApi.getState();

            if ((complains.isPending && complains.requestId !== thunkApi.requestId) || validate(complains).length)
                return thunkApi.rejectWithValue(complains.errors);

            const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY))?.token;

            const response = await axios.put(
                APP_URLS.COMPLAIN_UPDATE.replace('{id}', complains.complain.id),
                data,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    }
            });

            return response;
        } catch (error) {
            return thunkApi.rejectWithValue(error.response.data);
        }
    }
);

const deleteComplain = createAsyncThunk(
    'complain/delete',
    async (id, thunkApi) => {
        try {
            const { complains } = thunkApi.getState();

            if (complains.isPending && complains.requestId !== thunkApi.requestId)
                return;

            const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY))?.token;
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }

            const response = await axios.delete(
                APP_URLS.COMPLAIN_REMOVE.replace('{id}', id),
                { headers }
            );

            if (!response.data.succeeded)
                return thunkApi.rejectWithValue('Błąd api');

            return response;
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

const removePhoto = createAsyncThunk(
    'complain/removePhoto',
    async ({id, file}, thunkApi) => {
        try {
            const { complains } = thunkApi.getState();
            console.log('id :>> ', id);
            if (complains.isPending && complains.requestId !== thunkApi.requestId)
                return;

            const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY))?.token;
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }

            const response = await axios.post(
                APP_URLS.COMPLAIN_REMOVE_PHOTO.replace('{id}', id),
                file,
                { headers }
            );

            if (!response.data.succeeded)
                return thunkApi.rejectWithValue('Błąd api');

            return response;
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

const sendAgainToCompany = createAsyncThunk(
    "complains/sendAgain",
    async (id, thunkApi) => {
        try {
            const { complains } = thunkApi.getState();

            if (complains.isPending && complains.requestId !== thunkApi.requestId) {
                return;
            }

            const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY))?.token;
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }

            const response = await axios.get(
                APP_URLS.COMPLAIN_SEND_AGAIN.replace('{id}', id),
                { headers});

            return response;
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);


const sendBulkAgainToCompany = createAsyncThunk(
    "complains/sendAgainBulk",
    async (ids, thunkApi) => {
        try {
            const { complains } = thunkApi.getState();

            if (complains.isPending && complains.requestId !== thunkApi.requestId) {
                return;
            }

            const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY))?.token;
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
            
            const body = ids;
            console.log(body);
            const response = await axios.put(
                APP_URLS.COMPLAIN_BULK_SEND_AGAIN, body,
                { headers });

            return response;
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

const changeVisibilityIsBlocked = createAsyncThunk(
    "complains/changeVisibilityIsBlocked",
    async (id, thunkApi) => {
        try {
            const { complains } = thunkApi.getState();

            if (complains.isPending && complains.requestId !== thunkApi.requestId) {
                return;
            }

            const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY))?.token;
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }

            const response = await axios.get(
                APP_URLS.COMPLAIN_CHANGE_VISIBILITY_IS_BLOCKED.replace('{id}', id),
                { headers});

            return response;
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

const prepareMessageForm = (data) => {
    let formData = new FormData();

    data.attachments.forEach(file => {
        formData.append('Attachments', file);
    });

    formData.append('message', data.message);
    formData.append('Reporter', data.reporter);
    formData.append('Company', data.company);
    formData.append('Admin', data.admin);

    return formData;
}

const sendMessage = createAsyncThunk(
    "complains/sendMessage",
    async (data, thunkApi) => {
        try {
            const { complains } = thunkApi.getState();

            if (complains.isPending && complains.requestId !== thunkApi.requestId) {
                return;
            }

            const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY))?.token;
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': 'Bearer ' + token,
            }

            const response = await axios.post(
                APP_URLS.COMPLAIN_SEND_MESSAGE.replace('{id}', data.id),
                prepareMessageForm(data),
                {
                    headers
                }
            );

            return response;
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

const removeComplain = createAsyncThunk(
    "complains/remove",
    async (id, thunkApi) => {
        try {
            const { complains } = thunkApi.getState();

            if (complains.isPending && complains.requestId !== thunkApi.requestId) {
                return;
            }

            const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY))?.token;
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }

            const response = await axios.delete(
                APP_URLS.COMPLAIN_REMOVE.replace('{id}', id),
                {
                    headers
                }
            );

            return response;
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

const closeComplain = createAsyncThunk(
    "complains/closeComplain",
    async (data, thunkApi) => {
        console.log("🚀 ~ file: complainsSlice.jsx:306 ~ data:", data)
        try {
            const { complains } = thunkApi.getState();

            if (complains.isPending && complains.requestId !== thunkApi.requestId) {
                return;
            }
            const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY))?.token;
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }

            const response = await axios.post(
                APP_URLS.COMPLAIN_CLOSE.replace('{id}', data.id),
                data,
                {
                    headers
                }
            );
            console.log("🚀 ~ file: complainsSlice.jsx:326 ~ response:", response)

            return response;
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

const mergeComplains = createAsyncThunk(
    "complains/mergeComplains",
    async (data, thunkApi) => {
        try {
            const { complains } = thunkApi.getState();

            if (complains.isPending && complains.requestId !== thunkApi.requestId) {
                return;
            }
            const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY))?.token;
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }

            const response = await axios.post(
                APP_URLS.COMPLAIN_MERGE,
                data,
                {
                    headers
                }
            );

            return response;
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

const unmergeComplains = createAsyncThunk(
    "complains/unmergeComplains",
    async (data, thunkApi) => {
        try {
            const { complains } = thunkApi.getState();

            if (complains.isPending && complains.requestId !== thunkApi.requestId) {
                return;
            }
            const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY))?.token;
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }

            const response = await axios.post(
                APP_URLS.COMPLAIN_UNMERGE,
                data,
                {
                    headers
                }
            );

            return response;
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

const slice = createSlice({
    name: 'complains',
    initialState: {...defaultState},
    reducers: {
        setFilter: (state, { payload }) => {
            const { field, value } = payload;

            state.filter = {
                ...state.filter,
                [field]: value
            };
        },
        setAllFilters: (state, { payload }) => {
            state.filter = {
                ...payload
            };
        },
        resetFilter: (state) => {
            state.filter = {
                ...defaultState.filter,
            };

            state.selectedItems = [];
        },
        previousPage: (state) => {
            state.filter.pageNumber--;
        },
        nextPage: (state) => {
            state.filter.pageNumber++;
        },
        checkItem: (state, { payload }) => {
            state.selectedItems.push(payload);
        },
        checkAllItems: (state) => {
            state.selectedItems = [...state.data];
        },
        uncheckItem: (state, { payload }) => {
            state.selectedItems = _.filter(state.selectedItems, (item) => item.id !== payload.id)
        },
        uncheckAllItems: (state) => {
            state.selectedItems = [];
        },
        setComplain: (state, { payload }) => {
            const { field, value } = payload;

            state.complain = {
                ...state.complain,
                [field]: value
            };

            if (state.errors.length)
                state.errors = [...validate(state)];
        },
    },
    extraReducers: builder  => {
        builder
            .addCase(fetchComplains.pending, (state, action) => {
                if (state.isPending)
                    return;

                state.data = [];
                state.error = null;
                state.isPending = true;
                state.requestId = action.meta.requestId;
            })
            .addCase(fetchComplains.fulfilled, (state, { payload }) => {
                state.isPending = false;
                state.requestId = null;

                if (!payload)
                    return;

                state.filter = {
                    ...state.filter,
                    hasPrevious: payload.data.hasPrevious,
                    hasNext: payload.data.hasNext,
                }
                state.data = payload.data.items;
                state.csvLink = payload.request?.responseURL?.replace("list", "csv");
            })
            .addCase(fetchComplains.rejected, (state, { error }) => {
                state.error = error.message;
                state.isPending = false;
                state.requestId = null;
            })
            .addCase(getComplain.pending, (state, action) => {
                if (state.isPending)
                    return;

                state.error = null;
                state.isPending = true;
                state.requestId = action.meta.requestId;
            })
            .addCase(getComplain.fulfilled, (state, { payload }) => {
                state.isPending = false;
                state.requestId = null;

                if (!payload)
                    return;

                state.complain = payload.data;
            })
            .addCase(getComplain.rejected, (state, { error }) => {
                state.error = error.message;
                state.isPending = false;
                state.requestId = null;
            })
            .addCase(updateComplain.pending, (state, action) => {
                if (state.isPending)
                    return;

                state.errors = [...validate(state)];

                state.isPending = true;
                state.requestId = action.meta.requestId;
            })
            .addCase(updateComplain.fulfilled, (state, { payload }) => {
                state.isPending = false;
                state.requestId = null;

                if (!payload)
                    return;
            })
            .addCase(updateComplain.rejected, (state, error) => {
                state.error = error.payload;
                state.isPending = false;
                state.requestId = null;
            })
            .addCase(deleteComplain.pending, (state, action) => {
                if (state.isPending)
                    return;

                state.error = null;
                state.isPending = true;
                state.requestId = action.meta.requestId;
            })
            .addCase(deleteComplain.fulfilled, (state, { payload }) => {
                state.isPending = false;
                state.requestId = null;

                if (!payload)
                    return;
            })
            .addCase(deleteComplain.rejected, (state, { error }) => {
                state.error = error.message;
                state.isPending = false;
                state.requestId = null;
            })
            .addCase(closeComplain.pending, (state, action) => {
                if (state.isPending)
                    return;

                state.error = null;
                state.isPending = true;
                state.requestId = action.meta.requestId;
            })
            .addCase(closeComplain.fulfilled, (state, { payload }) => {
                state.isPending = false;
                state.requestId = null;

                if (!payload)
                    return;
            })
            .addCase(closeComplain.rejected, (state, { error }) => {
                state.error = error.message;
                state.isPending = false;
                state.requestId = null;
            })
            .addCase(mergeComplains.pending, (state, action) => {
                if (state.isPending)
                    return;

                state.error = null;
                state.isPending = true;
                state.requestId = action.meta.requestId;
            })
            .addCase(mergeComplains.fulfilled, (state, { payload }) => {
                state.isPending = false;
                state.requestId = null;

                if (!payload)
                    return;
            })
            .addCase(mergeComplains.rejected, (state, { error }) => {
                state.error = error.message;
                state.isPending = false;
                state.requestId = null;
            })
            .addCase(unmergeComplains.pending, (state, action) => {
                if (state.isPending)
                    return;

                state.error = null;
                state.isPending = true;
                state.requestId = action.meta.requestId;
            })
            .addCase(unmergeComplains.fulfilled, (state, { payload }) => {
                state.isPending = false;
                state.requestId = null;

                if (!payload)
                    return;
            })
            .addCase(unmergeComplains.rejected, (state, { error }) => {
                state.error = error.message;
                state.isPending = false;
                state.requestId = null;
            });
    }
});

export const {
    setFilter,
    setAllFilters,
    resetFilter,
    previousPage,
    nextPage,
    checkItem,
    checkAllItems,
    uncheckItem,
    uncheckAllItems,
    setComplain,
} = slice.actions;

export {
    fetchComplains,
    getComplain,
    updateComplain,
    deleteComplain,
    removePhoto,
    sendAgainToCompany,
    sendMessage,
    removeComplain,
    closeComplain,
    changeVisibilityIsBlocked,
    mergeComplains,
    unmergeComplains,
    sendBulkAgainToCompany
};

export default slice.reducer;