export const usersTableHead = [
    {
      id: 'id',
    },
    {
      id: 'fullName',
    },
    {
      id: 'email',
    },
    {
      id: 'role'
    },
    {
      id: 'createdAt',
    },
    {
      id: 'status',
    },
  ];