import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData, login, logout } from '../app/slices/authSlice';

export function useAuth() {
    const { auth } = useSelector(state => ({
        auth: state.auth
    }));
    const [authenticated, setAuthenticated] = React.useState(auth.authenticated);
    const [userData, setUserData] = React.useState(auth.userData);
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(getUserData()).then((result) => {
            if (result.payload) {
                setAuthenticated(true);
                setUserData(result.payload);
            }
        });
    }, [dispatch]);

    return {
        authenticated,
        userData,
        login(credentials) {
            return new Promise(async (res) => {
                let loginResult = await dispatch(login(credentials));

                if (loginResult.payload.hasOwnProperty('refresh_token')) {
                    let userData = await dispatch(getUserData());

                    setAuthenticated(true);
                    setUserData(userData.payload);
                }
                res();
            });
        },
        logout() {
            return new Promise((res) => {
                dispatch(logout()).then(() => {
                    setAuthenticated(false);
                });
                res();
            });
        },
    };
}