import { Block, DeleteOutline } from "@mui/icons-material";
import {
    Collapse,
    TableCell,
    TableRow,
  } from "@mui/material";
import { format } from "date-fns";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userStatuses } from "../../../app/config/userStatuses";
import { checkAllItems, checkItem, fetchComplains, nextPage, previousPage, setFilter, resetFilter, uncheckAllItems, uncheckItem } from "../../../app/slices/complainsSlice";
import ConsumerComplainsList from "../../forms/consumer/consumerComplainsList";


function ConsumersTableRow({ open, consumer, onRowClick, onStatusChange, onDelete, filter, selectedItems, complains}) {
    const { t: t_button } = useTranslation('Buttons');
    const { t: t_link} = useTranslation('Pages');
    const { t: t_status } = useTranslation('ConsumersScreen', { keyPrefix: 'TableFilter.status' });

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const statusText = _.find(
        userStatuses,
        (e) => e.value === consumer.isBlocked
      )?.text;

    const refreshComplains = async () => {

        await dispatch(fetchComplains({ reporterId: consumer.id }));
    }

    const handlePreviousPage = () => {
        dispatch(previousPage());
        refreshComplains();
    }

    const handleNextPage = () => {
        dispatch(nextPage());
        refreshComplains();
    }

    const handleRequestSort = (event, property) => {
        const direction =
            filter.orderBy === property &&
            filter.orderDirection === "asc"
                ? "desc"
                : "asc";
        dispatch(setFilter({ field: "orderBy", value: property }));
        dispatch(setFilter({ field: "orderDirection", value: direction }));

        refreshComplains();
    }

    const handleRowCheck = (item, isChecked) => {
        if (!isChecked) {
            dispatch(checkItem(item));
        } else {
            dispatch(uncheckItem(item));
        }
    }

    const handleSelectAll = () => {
        if (selectedItems.length === complains.length) {
            dispatch(uncheckAllItems());
        } else {
            dispatch(checkAllItems());
        }
    }

    const onCellClick = async () => {
        onRowClick(consumer.id);
        dispatch(resetFilter());
        console.log("🚀 ~ file: consumersTableRow.jsx:79 ~ onCellClick ~ consumer.id:", consumer.id)
        await refreshComplains();
    }

    return (
        <>
            <TableRow
                className={`row ${open ? " active" : ""}`}
            >
                <TableCell
                    padding="none"
                    className="fk-table-cell --first"
                    onClick={onCellClick}
                >
                    <span className="value">{consumer.id}</span>
                </TableCell>
                <TableCell
                    padding="none"
                    className="fk-table-cell"
                    onClick={onCellClick}
                >
                    <span className="value">{consumer.fullName}</span>
                </TableCell>
                <TableCell
                    padding="none"
                    className="fk-table-cell"
                    onClick={onCellClick}
                >
                    <span className="value">
                        {format(
                        new Date(consumer.createdAt),
                        "yyyy-MM-dd 'godz:' HH:mm"
                        )}
                    </span>
                </TableCell>
                <TableCell
                    padding="none"
                    className="fk-table-cell"
                    onClick={onCellClick}
                >
                    <span className="value">{consumer.reportsAmount}</span>
                </TableCell>
                <TableCell
                    padding="none"
                    className="fk-table-cell"
                    onClick={onCellClick}
                >
                    <span className="value">{consumer.points}</span>
                </TableCell>
                <TableCell
                    padding="none"
                    className="fk-table-cell"
                    onClick={onCellClick}
                >
                    <span className="value">{t_status(statusText)}</span>
                </TableCell>
                <TableCell padding="none" className="fk-table-cell crud --last">
                    <span className="value">
                        <Block onClick={() => onStatusChange(consumer)} />
                        <DeleteOutline
                            onClick={() => onDelete(consumer)}
                        />
                        <button
                            className="show-details-button"
                            onClick={() => navigate(t_link('consumerLink') + consumer.id)}
                        >
                            {t_button('show')}
                        </button>
                    </span>
                </TableCell>
            </TableRow>
            {open &&
                <TableRow className="row collapsed-data">
                    <TableCell
                        className="fk-table-cell --first"
                        colSpan={7}
                    >
                        <Collapse in={open} timeout="auto" className="collapse">
                            <ConsumerComplainsList
                                consumerId={consumer.id}
                                selectedItems={selectedItems}
                                onRequestSort={handleRequestSort}
                                onRowCheck={handleRowCheck}
                                onSelectAll={handleSelectAll}
                                onPreviousPage={handlePreviousPage}
                                onNextPage={handleNextPage}
                            />
                        </Collapse>
                    </TableCell>
                </TableRow>
            }

      </>
    );
}

const mapStateToProps = state => ({
    filter: state.complains.filter,
    selectedItems: state.complains.selectedItems,
    complains: state.complains.data,
});

export default connect(mapStateToProps, {})(ConsumersTableRow);