export const companySectors = [
    {
        value: 1,
        text: 'Dobra konsumpcyjne'
    },
    {
        value: 2,
        text: 'Usługi telekomunikacyjne'
    },
    {
        value: 3,
        text: 'Usługi kurierskie i pocztowe'
    },
    {
        value: 4,
        text: 'Rekreacja'
    },
    {
        value: 5,
        text: 'Energia, gaz, woda'
    },
    {
        value: 6,
        text: 'Transport'
    },
    {
        value: 7,
        text: 'Zdrowie'
    },
    {
        value: 8,
        text: 'Edukacja'
    },
    {
        value: 9,
        text: 'Usługi finansowe'
    },
    {
        value: 10,
        text: 'Usługi inne'
    },
];