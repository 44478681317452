import React, { Component } from 'react';
import { Login } from '../components/auth';
import { SetPassword } from '../components/auth';

class SetPasswordScreen extends Component {

    render() {
        return (
            <SetPassword />
        );
    }
}

export default SetPasswordScreen;