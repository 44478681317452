import Logo from './logo.png';
import NoCompanyLogo from './no_company_logo.png';
import NoProfilePhoto from './no_profile_photo.png';
import AccountLevel1 from './account_level_1.png';
import PublicIcon from './public_icon.png';
import PrivateIcon from './private_icon.png';
import ConsumerRank_Basic from './consumerRank_basic.png';
import ConsumerRank_Junior from './consumerRank_junior.png';
import ConsumerRank_Senior from './consumerRank_senior.png';
import ConsumerRank_Master from './consumerRank_master.png';
import ConsumerRank_SuperMaster from './consumerRank_superMaster.png';

export {
    Logo,
    NoCompanyLogo,
    NoProfilePhoto,
    AccountLevel1,
    PublicIcon,
    PrivateIcon,
    ConsumerRank_Basic,
    ConsumerRank_Junior,
    ConsumerRank_Senior,
    ConsumerRank_Master,
    ConsumerRank_SuperMaster,
}