import React, { Component } from 'react';
import { Login } from '../components/auth';

class LoginScreen extends Component {

    render() {
        return (
            <Login />
        );
    }
}

export default LoginScreen;