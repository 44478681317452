import {
    ConsumerRank_Basic,
    ConsumerRank_Junior,
    ConsumerRank_Senior,
    ConsumerRank_Master,
    ConsumerRank_SuperMaster,
} from '../../assets/images';

function ConsumerRankIcon({ rank, width, height, className }) {
    let rankIcon;

    switch (rank) {
        case 'consumerRank_basic':
            rankIcon = ConsumerRank_Basic;
            break;
        case 'consumerRank_junior':
            rankIcon = ConsumerRank_Junior;
            break;
        case 'consumerRank_senior':
            rankIcon = ConsumerRank_Senior;
            break;
        case 'consumerRank_master':
            rankIcon = ConsumerRank_Master;
            break;
        case 'consumerRank_superMaster':
            rankIcon = ConsumerRank_SuperMaster;
            break;
        default:
            break;
    }

    return <img src={rankIcon} alt="" width={width ?? 40} height={height ?? 40} className={className} />;
}

export default ConsumerRankIcon;