import React, { Component } from 'react';
import { RememberPassword } from '../components/auth';

class RememberPasswordScreen extends Component {

    render() {
        return (
            <RememberPassword />
        );
    }
}

export default RememberPasswordScreen;