import { companySectors } from "../../../app/config";

export const infoFieldsArray = [
    {
        name: 'name',
        modelName: 'Name',
        required: true,
    },
    {
        name: 'nip',
        modelName: 'NIP',
    },
    {
        name: 'allSectors',
        modelName: 'AllSectors',
        required: true,
        items: companySectors,
        multiple: true,
    },
    {
        name: 'email',
        modelName: 'Email',
        required: true,
    },
    {
        name: 'status',
        modelName: 'Status',
    },
    {
        name: 'ownName',
        modelName: 'OwnName',
    }
];


export const additionalInfoFieldsArray = [
    {
        name: 'contactPerson',
        modelName: 'ContactPerson',
    },
    {
        name: 'phoneNumber',
        modelName: 'PhoneNumber',
    },
    {
        name: 'additionalEmail',
        modelName: 'AdditionalEmail',
    },
    {
        name: 'comment',
        modelName: 'Comment',
        textarea: true,
    },
];