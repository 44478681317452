import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import MessageEditAttachmentItem from "./MessageEditAttachmentItem";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
const ComplaintMessageEditForm = ({message, onConfirm, onCancel}) => {
    const [newMessage, setNewMessage] = useState(message.message);
    const [attachments, setAttachments] = useState(message.attachments);
    const {t} = useTranslation('ComplainDetailsScreen', {keyPrefix: 'Messages'});

    const handleConfirm = () => {
        onConfirm(newMessage, attachments);
        onCancel();
    }
    
    const handleRemoveAttachments = (id) => {
        const filtered = attachments.filter(a => {
            return a.id !== id
        });
        setAttachments(filtered);
    }

    return (
        <div style={styles.container}>
            <div className="fk-form editForm">
                <div className="fk-input--with-counter editForm">
                        <textarea className="fk-input fk-input--full-width"
                                  placeholder={t('answerPlaceholder')}
                                  value={newMessage}
                                  onChange={(event) => setNewMessage(event.target.value)}
                                  rows={5}
                                  maxLength={8000}/>
                    <div className="fk-word-counter">
                        {t('charsLimit')} {newMessage.length} / 8000
                    </div>
                </div>
                <div className={"attachmentContainer"}>
                    {attachments.map(i => {
                        return <MessageEditAttachmentItem attachment={i} onRemove={handleRemoveAttachments}/>
                    })}
                </div>
            </div>
            <div className={'rightColumn'}>
                <button className={'fk-edit-button'} onClick={handleConfirm}><CheckIcon /></button>
                <button className={'fk-edit-button cancel'} onClick={onCancel}><CloseIcon /></button>
            </div>
        </div>
    )
}

const styles = {
    container: {
        display: 'flex',
        width: '100%',
        flexGrow: 1,
        flexDirection: 'row',
        alignItems: 'flex-start',
        paddingBottom: 24,
        paddingTop: 24
    }
}

export default ComplaintMessageEditForm;