import './complaintMessageEditForm.scss'
import {Button} from "@mui/material";
import {DeleteOutline} from "@mui/icons-material";
import React from "react";

const MessageEditAttachmentItem = ({attachment, onRemove}) => {

    const handleRemove = () => {
        onRemove(attachment.id);
    }
    
    return (
        <div className="attachmentItem">
            <p>{attachment.fileName}</p>
            <Button sx={{minWidth: 32, minHeight: 32, aspectRatio: 1}} onClick={handleRemove}><DeleteOutline /></Button>
        </div>
    )
}

export default MessageEditAttachmentItem;