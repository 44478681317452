import {
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ConsumersTableFilter } from "../components";
import {
  fetchConsumers,
  previousPage,
  nextPage,
  resetFilter,
  setFilter,
  deleteConsumer,
  changeStatusConsumer,
} from "../app/slices/consumersSlice";
import { ConsumersTableRow, TableHeader, TablePagination } from "../components/table";
import { consumersTableHead } from "../app/config";
import "./screens.styles.scss";
import "../components/table/table.styles.scss";
import { showModal } from "../app/slices/modalSlice";
import {useLocation, useNavigate} from "react-router-dom";

function ConsumersScreen() {
  const dispatch = useDispatch();
  const consumers = useSelector(state => state.consumers.data);
  const filter = useSelector(state => state.consumers.filter);
  const [open, setOpen] = useState(null);
  
  useEffect(() => {
    dispatch(resetFilter());
    refresh();
  }, [dispatch]);

  const refresh = useCallback(() => {
    dispatch(fetchConsumers(true));
  }, [dispatch]);

  const handlePreviousPage = useCallback(() => {
    dispatch(previousPage());
    refresh();
  }, [dispatch, refresh]);

  const handleNextPage = useCallback(() => {
    dispatch(nextPage());
    refresh();
  }, [dispatch, refresh]);

  const handleRequestSort = useCallback((event, property) => {
    const direction =
        filter.orderBy === property && filter.orderDirection === "asc"
            ? "desc"
            : "asc";
    dispatch(setFilter({ field: "orderBy", value: property }));
    dispatch(setFilter({ field: "orderDirection", value: direction }));
    refresh();
  }, [dispatch, filter.orderBy, filter.orderDirection, refresh]);

  const handleRowClick = useCallback((id) => {
    setOpen(prevOpen => prevOpen === id ? null : id);
  }, []);

  const handleStatusChange = useCallback((consumer) => {
    const { id, isBlocked, fullName } = consumer;
    const content = {
      title: isBlocked ? 'Odblokowanie konta' : 'Zablokowanie konta',
      message: isBlocked ? 'Czy na pewno chcesz odblokować konto konsumenta' : 'Czy na pewno chcesz zablokować konto konsumenta',
      button: isBlocked ? 'Odblokuj' : 'Blokuj'
    };
    dispatch(showModal({
      title: content.title,
      message: `${content.message} ${fullName}?`,
      button: content.button,
      mode: 'normal',
      cancelButton: true,
      fallback: () => {
        dispatch(changeStatusConsumer(id)).then(() => refresh());
      }
    }));
  }, [dispatch, refresh]);

  const handleDelete = useCallback((consumer) => {
    dispatch(showModal({
      title: 'Usunięcie konsumenta',
      message: `Czy na pewno chcesz usunąć konsumenta ${consumer.fullName}?`,
      button: 'Usuń',
      mode: 'normal',
      cancelButton: true,
      fallback: () => {
        dispatch(deleteConsumer(consumer.id)).then(() => refresh());
      }
    }));
  }, [dispatch, refresh]);

  return (
      <div className="fk-screen">
        <ConsumersTableFilter />
        <TableContainer>
          <Table size="medium" className="fk-table">
            <TableHeader
                crud
                items={consumersTableHead}
                orderBy={filter.orderBy}
                order={filter.orderDirection}
                onRequestSort={handleRequestSort}
                screen="ConsumersScreen"
            />
            <TableBody>
              {consumers.map((consumer) => (
                  <ConsumersTableRow
                      key={consumer.id}
                      consumer={consumer}
                      open={open === consumer.id}
                      onRowClick={() => handleRowClick(consumer.id)}
                      onStatusChange={() => handleStatusChange(consumer)}
                      onDelete={() => handleDelete(consumer)}
                  />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
            filter={filter}
            handlePreviousPage={handlePreviousPage}
            handleNextPage={handleNextPage}
        />
      </div>
  );
}

export default ConsumersScreen;
