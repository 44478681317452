import {
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";
import React, { Component } from "react";
import {
  fetchConsumers,
  previousPage,
  nextPage,
  resetFilter,
  setFilter,
  deleteConsumer,
  changeStatusConsumer, 
  removeAdminRoles
} from "../app/slices/consumersSlice";
import { TableHeader, TablePagination, UsersTableFilter, UsersTableRow } from "../components/table";
import { usersTableHead } from "../app/config";
import "./screens.styles.scss";
import "../components/table/table.styles.scss";
import { connect } from "react-redux";
import {withRouter} from "../wrappers/withRouter";


class UsersScreen extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      open: null,
    };

    this.props.resetFilter();
    this.refresh();

    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleRequestSort = this.handleRequestSort.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handlePreviousPage = this.handlePreviousPage.bind(this);
    this.handleNextPage = this.handleNextPage.bind(this);
    this.handleRemoveRole = this.handleRemoveRole.bind(this);
    
  }

  refresh() {
    this.props.fetchConsumers(false);
  }

  handlePreviousPage() {
    this.props.previousPage();
    this.refresh();
  }

  handleNextPage() {
    this.props.nextPage();
    this.refresh();
  }

  handleRequestSort(event, property) {
    const direction =
      this.props.filter.orderBy === property &&
      this.props.filter.orderDirection === "asc"
        ? "desc"
        : "asc";
    this.props.setFilter({ field: "orderBy", value: property });
    this.props.setFilter({ field: "orderDirection", value: direction });

    this.refresh();
  }

  handleRowClick(id) {
    this.setState({
      open: this.state.open === id ? null : id,
    });
  }

  handleStatusChange(id) {
    this.props.changeStatusConsumer(id).then(() => this.refresh());
  }

  handleDelete(id) {
    this.props.deleteConsumer(id).then(() => this.refresh());
  }

  handleRemoveRole(id) {
    this.props.removeAdminRoles(id).then(() => this.refresh());
  }
  
  render() {
    return (
      <div className="fk-screen">
        <UsersTableFilter isCurrentUserAdmin={this.props.isAdmin} />
        <TableContainer>
          <Table size="medium" className="fk-table">
            <TableHeader
              crud
              items={usersTableHead}
              orderBy={this.props.filter.orderBy}
              order={this.props.filter.orderDirection}
              onRequestSort={this.handleRequestSort}
              screen="UsersScreen"
            />

            <TableBody>
                {this.props.consumers.map((user) => (
                    <UsersTableRow
                        key={user.id}
                        user={user}
                        open={this.state.open === user.id}
                        onRowClick={this.handleRowClick}
                        onStatusChange={this.handleStatusChange}
                        onDelete={this.handleRemoveRole}
                        isCurrentUserAdmin={this.props.isAdmin}
                    />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          filter={this.props.filter}
          handlePreviousPage={this.handlePreviousPage}
          handleNextPage={this.handleNextPage}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  consumers: state.consumers.data,
  filter: state.consumers.filter,
});

const actions = {
  fetchConsumers,
  previousPage,
  nextPage,
  resetFilter,
  setFilter,
  deleteConsumer,
  changeStatusConsumer,
  removeAdminRoles
};

export default withRouter(connect(mapStateToProps, actions)(UsersScreen));
