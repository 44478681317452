import { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

function RequireAuth({ authenticated, children }) {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!authenticated) {
            navigate('/login', {state: {nextPath: location?.pathname}});
            return;
        }
    })

    if (!authenticated)
        return;

    return children;
}

const mapStateToProps = (state) => ({
    authenticated: state.auth.authenticated,
});

export default connect(mapStateToProps, {})(RequireAuth);