import FaIconsProvider from '../../providers/faIconsProvider';
import Menu from './menu';
import './layout.styles.scss';
import { connect } from 'react-redux';

function Layout({ authenticated, children }) {
    return (
        <FaIconsProvider>
            <div className="fk-layout">
                {authenticated &&
                    <Menu />
                }
                {children}
            </div>
        </FaIconsProvider>
    );
}

const mapStateToProps = (state) => ({
    authenticated: state.auth.authenticated,
});

export default connect(mapStateToProps, {})(Layout);